import React, { useState, useEffect, useRef } from "react";
import { Dialog } from "primereact/dialog";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import PackagingMaterials from "../PackagingMaterials";
import MetrologyPanel from "../Metrology";
import MeasurementPanel from "../Measurements";
import ReceptionMeasuresPanel from "../ReceptionMeasurements";
import RawMaterialChamberPanel from "../RawMaterialChamber";
import PackingMeasurementPanel from "../PackingMeasurement";
import SealingRoomMeasurementPanel from "../SealingRoomMeasurements";
import PalletizedMeasurementsPanel from "../PalletizedMeasurements";
import OtherMeasurementsPanel from "../OtherMeasurements";
import { sendEmailWithRecipients, setSendedAt } from "../../api/email";
import PdfBlob from "../../utils/pdfBlob";
import PdfDownload from "../../utils/pdfDownload";

const VisitDialog = (props) => {
  const {
    visible,
    hideDialog,
    formData,
    saveVisit,
    handleInputChange,
    users,
    brands,
    species,
    places,
    customers,
    subComponentData,
    setSubComponentData,
    contacts,
  } = props;

  const [pdfData, setPdfData] = useState({
    visit: formData,
    subComponentData: subComponentData,
  });
  const [pdfBlob, setPdfBlob] = useState(null);
  const [filteredEmails, setFilteredEmails] = useState([]);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [sendingEmail, setSendingEmail] = useState(false);
  const toast = useRef(null);

  const showToastMessage = (severity, summary, detail) => {
    toast.current.show({ severity, summary, detail });
  };

  useEffect(() => {
    setPdfData({
      visit: formData,
      subComponentData: subComponentData,
    });
  }, [formData, subComponentData]);

  const filterContacts = () => {
    const filteredContacts = contacts.filter((contact) => {
      if (
        contact.corporate === 1 &&
        contact.customer_id === formData.customer_id
      ) {
        return contact.email;
      } else {
        return contact.place_id === formData.place_id && contact.email;
      }
    });

    setFilteredEmails(filteredContacts);
    setShowEmailModal(true);
  };

  const sendEmail = async () => {
    try {
      setSendingEmail(true);

      await sendEmailWithRecipients(pdfBlob, filteredEmails, formData);

      await setSendedAt(formData.id);

      showToastMessage(
        "success",
        "Correo Enviado",
        "El correo se ha enviado exitosamente."
      );

      hideDialog();
      setPdfBlob(null);
      setFilteredEmails([]);
      setShowEmailModal(false);
    } catch (error) {
      console.error("Error al enviar el correo:", error);
      showToastMessage("error", "Error", "Error al enviar el correo.");
    } finally {
      setSendingEmail(false);
    }
  };

  const cancelSendEmail = () => {
    setShowEmailModal(false);
  };

  const onBlobGenerated = (blob) => {
    setPdfBlob(blob);
  };

  const filterOtherMeasurements = (type) => {
    if (Array.isArray(subComponentData.OtherMeasurement)) {
      if (subComponentData.OtherMeasurement.length === 0) return [];
      return subComponentData.OtherMeasurement.filter(
        (measure) => measure.measurement_type === type
      );
    } else {
      return [];
    }
  };

  return (
    <Dialog
      visible={visible}
      onHide={hideDialog}
      header={"Editar Visita"}
      style={{ width: "90vw", maxWidth: "1000px" }}
      resizable={false}
    >
      <Accordion style={{ width: "100%", height: "100%" }}>
        <AccordionTab header="Información general de la visita">
          <div className="p-mt-2">
            <div className="field-title">Usuario</div>
            <Dropdown
              optionLabel="name"
              optionValue="id"
              value={formData.user_id}
              options={users}
              onChange={(e) => handleInputChange("user_id", e.value)}
              placeholder="Usuario"
            />
            <div className="field-title">Cliente</div>
            <Dropdown
              optionLabel="name"
              optionValue="id"
              value={formData.customer_id}
              options={customers}
              onChange={(e) => handleInputChange("customer_id", e.value)}
              placeholder="Cliente"
            />
            <div className="field-title">Lugar</div>
            <Dropdown
              optionLabel="name"
              optionValue="id"
              value={formData.place_id}
              options={places}
              onChange={(e) => handleInputChange("place_id", e.target.value)}
              placeholder="Lugar"
            />
            <div className="field-title">Marca</div>
            <Dropdown
              optionLabel="name"
              optionValue="id"
              value={formData.brand_id}
              options={brands}
              onChange={(e) => handleInputChange("brand_id", e.value)}
              placeholder="Marca"
            />
            <div className="field-title">Especie</div>
            <Dropdown
              optionLabel="name"
              optionValue="id"
              value={formData.specie_id}
              options={species}
              onChange={(e) => handleInputChange("specie_id", e.value)}
              placeholder="Especie"
            />
            <div className="field-title">Fecha de Visita</div>
            <Calendar
              id="date_of_visit"
              value={
                formData.date_of_visit ? new Date(formData.date_of_visit) : null
              }
              onChange={(e) => handleInputChange("date_of_visit", e.value)}
              placeholder="Fecha de Visita"
              dateFormat="dd/mm/yy"
            />
            <div className="field-title">Persona a Cargo</div>
            <InputText
              value={formData.person_in_charge}
              onChange={(e) =>
                handleInputChange("person_in_charge", e.target.value)
              }
              placeholder="Persona a Cargo"
            />
            <div className="field-title">Hora de inicio</div>
            <Calendar
              value={formData.start_time ? new Date(formData.start_time) : null}
              onChange={(e) => handleInputChange("start_time", e.value)}
              timeOnly
              placeholder="Fecha de Inicio"
              dateFormat="dd/mm/yy"
            />
            <div className="field-title">Hora de Término</div>
            <Calendar
              value={formData.end_time ? new Date(formData.end_time) : null}
              onChange={(e) => handleInputChange("end_time", e.value)}
              timeOnly
              placeholder="Fecha de Término"
              dateFormat="dd/mm/yy"
            />
            <div className="field-title">Conclusiones</div>
            <InputTextarea
              autoResize={true}
              value={formData.conclusions}
              onChange={(e) => handleInputChange("conclusions", e.target.value)}
              placeholder="Conclusiones"
            />
            <div className="field-title">Recomendaciones</div>
            <InputTextarea
              autoResize={true}
              value={formData.recommendations}
              onChange={(e) =>
                handleInputChange("recommendations", e.target.value)
              }
              placeholder="Recomendaciones"
            />
          </div>
          <div className="field-title">Otros comentarios</div>
          <InputTextarea
            autoResize={true}
            value={formData.other_comments}
            onChange={(e) =>
              handleInputChange("other_comments", e.target.value)
            }
            placeholder="Otros comentarios"
          />
          <div
            className="
            p-mt-2"
            style={{
              display: "flex",
              justifyContent: "left",
            }}
          >
            <Button label="Guardar" icon="pi pi-check" onClick={saveVisit} />
          </div>
        </AccordionTab>
      </Accordion>
      <div className="p-mt-2">
        <PackagingMaterials
          packaging_materials_comment={formData.packaging_materials_comment}
          visitId={formData.id}
          showToastMessage={showToastMessage}
          parentInputChange={handleInputChange}
        />
      </div>
      <div className="p-mt-2">
        <MetrologyPanel
          metrologies={subComponentData.Metrology}
          visitId={formData.id}
          onChange={(updatedMetrology) => {
            setSubComponentData((prev) => ({
              ...prev,
              Metrology: updatedMetrology,
            }));
          }}
        />
      </div>
      <div className="p-mt-2">
        <MeasurementPanel
          measurement={subComponentData.Measurements}
          onChange={(updatedMeasurement) =>
            setSubComponentData((prev) => ({
              ...prev,
              Measurements: updatedMeasurement,
            }))
          }
        />
      </div>
      <div className="p-mt-2">
        <ReceptionMeasuresPanel
          measures={subComponentData.ReceptionMeasurements}
          visitId={formData.id}
          showToastMessage={showToastMessage}
          receptionComment={formData.reception_measurements_comment}
          parentInputChange={handleInputChange}
          onChange={(updatedMeasures) =>
            setSubComponentData((prev) => ({
              ...prev,
              ReceptionMeasurements: updatedMeasures,
            }))
          }
        />
      </div>
      <div className="p-mt-2">
        <RawMaterialChamberPanel
          rawMaterialChamber={subComponentData.RawMaterialChamber}
          onChange={(updatedChamber) =>
            setSubComponentData((prev) => ({
              ...prev,
              RawMaterialChamber: updatedChamber,
            }))
          }
        />
      </div>
      <div className="p-mt-2">
        <PackingMeasurementPanel
          packingMeasurement={subComponentData.PackingMeasurement}
          onChange={(updatedPackingMeasurement) =>
            setSubComponentData((prev) => ({
              ...prev,
              PackingMeasurement: updatedPackingMeasurement,
            }))
          }
        />
      </div>
      <div className="p-mt-2">
        <SealingRoomMeasurementPanel
          sealingRoomMeasurement={subComponentData.SealingRoomMeasurement}
          onChange={(updatedSealingRoomMeasurement) =>
            setSubComponentData((prev) => ({
              ...prev,
              SealingRoomMeasurement: updatedSealingRoomMeasurement,
            }))
          }
        />
      </div>
      <div className="p-mt-2">
        <PalletizedMeasurementsPanel
          parentInputChange={handleInputChange}
          palletizedMeasurements={subComponentData.PalletizedMeasurement}
          onChange={(updatedPalletizedMeasurements) =>
            setSubComponentData((prev) => ({
              ...prev,
              PalletizedMeasurement: updatedPalletizedMeasurements,
            }))
          }
        />
      </div>

      <div className="p-mt-2">
        <OtherMeasurementsPanel
          otherMeasurements={filterOtherMeasurements("finished_product")}
          title="Poducto Terminado"
          onChange={(updatedOtherMeasurements) =>
            setSubComponentData((prev) => ({
              ...prev,
              OtherMeasurement: updatedOtherMeasurements,
            }))
          }
        />
      </div>
      <div className="p-mt-2">
        <OtherMeasurementsPanel
          otherMeasurements={filterOtherMeasurements("loading_dock")}
          title="Andén de carga"
          onChange={(updatedOtherMeasurements) =>
            setSubComponentData((prev) => ({
              ...prev,
              OtherMeasurement: updatedOtherMeasurements,
            }))
          }
        />
      </div>

      <div
        className="p-mt-2"
        style={{
          display: "flex",
          justifyContent: "right",
        }}
      >
        <PdfDownload
          visit={pdfData.visit}
          subComponentData={pdfData.subComponentData}
        />

        <PdfBlob
          visit={pdfData.visit}
          subComponentData={pdfData.subComponentData}
          onBlobGenerated={onBlobGenerated}
          filterContacts={filterContacts}
        />

        <Button
          label="Cerrar"
          icon="pi pi-times"
          className="p-button-secondary p-ml-2"
          onClick={hideDialog}
        />
      </div>
      <Dialog
        visible={showEmailModal}
        onHide={cancelSendEmail}
        header="Confirmar emails"
      >
        <div>
          <p>¿Está seguro que desea enviar el archivo a estos contactos?</p>
          <ul>
            {filteredEmails.map((contact) => (
              <li key={contact.id}>{contact.email}</li>
            ))}
          </ul>

          <Button
            label={sendingEmail ? "Enviando..." : "Aceptar"}
            icon={sendingEmail ? "pi pi-spin pi-spinner" : "pi pi-check"}
            onClick={sendEmail}
            disabled={sendingEmail}
          />

          <Button
            label="Cancelar"
            icon="pi pi-times"
            onClick={cancelSendEmail}
          />
        </div>
      </Dialog>
      <Toast ref={toast} />
    </Dialog>
  );
};

export default VisitDialog;

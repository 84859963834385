import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import ConfirmationModal from "./ConfirmationModal";
import {
  getSpecies,
  createSpecies,
  deleteSpecies,
  updateSpecies,
} from "../api/species";
import "./TablesStyle.css";

const SpeciesTab = () => {
  const [species, setSpecies] = useState([]);
  const [selectedSpecie, setSelectedSpecie] = useState(null);
  const [visible, setVisible] = useState(false);
  const [speciesForm, setSpeciesForm] = useState({
    name: "",
    min_o2: "",
    max_o2: "",
    min_co2: "",
    max_co2: "",
    min_room_temperature: "",
    max_room_temperature: "",
    min_pulp_temperature: "",
    max_pulp_temperature: "",
    min_wind_speed: "",
    max_wind_speed: "",
    min_relative_humidity: "",
    max_relative_humidity: "",
  });
  const [confirmationModalVisible, setConfirmationModalVisible] =
    useState(false);
  const [searchText, setSearchText] = useState("");
  const [nameError, setNameError] = useState("");
  const [minO2Error, setMinO2Error] = useState("");
  const [maxO2Error, setMaxO2Error] = useState("");
  const [minCO2Error, setMinCO2Error] = useState("");
  const [maxCO2Error, setMaxCO2Error] = useState("");
  const [minRoomTemperatureError, setMinRoomTemperatureError] = useState("");
  const [maxRoomTemperatureError, setMaxRoomTemperatureError] = useState("");
  const [minPulpTemperatureError, setMinPulpTemperatureError] = useState("");
  const [maxPulpTemperatureError, setMaxPulpTemperatureError] = useState("");
  const [minWindSpeedError, setMinWindSpeedError] = useState("");
  const [maxWindSpeedError, setMaxWindSpeedError] = useState("");
  const [minRelativeHumidityError, setMinRelativeHumidityError] = useState("");
  const [maxRelativeHumidityError, setMaxRelativeHumidityError] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [disableSave, setDisableSave] = useState(false);

  useEffect(() => {
    loadSpecies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadSpecies = async () => {
    try {
      const response = await getSpecies();
      setSpecies(response.data);
    } catch (error) {
      showToast("error", "Error", "Error al obtener las especies");
      console.error("Error al obtener las especies:", error);
    }
  };

  const openDialog = (specie) => {
    setSelectedSpecie(specie);
    setSpeciesForm({
      name: specie?.name || "",
      min_o2: specie?.min_o2 || "",
      max_o2: specie?.max_o2 || "",
      min_co2: specie?.min_co2 || "",
      max_co2: specie?.max_co2 || "",
      min_room_temperature: specie?.min_room_temperature || "",
      max_room_temperature: specie?.max_room_temperature || "",
      min_pulp_temperature: specie?.min_pulp_temperature || "",
      max_pulp_temperature: specie?.max_pulp_temperature || "",
      min_wind_speed: specie?.min_wind_speed || "",
      max_wind_speed: specie?.max_wind_speed || "",
      min_relative_humidity: specie?.min_relative_humidity || "",
      max_relative_humidity: specie?.max_relative_humidity || "",
    });
    setVisible(true);
    setIsEditing(!!specie);
  };

  const hideDialog = () => {
    setVisible(false);
    setSelectedSpecie(null);
    setSpeciesForm({
      name: "",
      min_o2: "",
      max_o2: "",
      min_co2: "",
      max_co2: "",
      min_room_temperature: "",
      max_room_temperature: "",
      min_pulp_temperature: "",
      max_pulp_temperature: "",
      min_wind_speed: "",
      max_wind_speed: "",
      min_relative_humidity: "",
      max_relative_humidity: "",
    });
    setNameError("");
    setIsEditing(false);
  };

  const saveUser = async () => {
    try {
      if (!speciesForm.name) {
        setNameError("El nombre de la especie es obligatorio");
        return;
      }
      if (!speciesForm.min_o2) {
        setMinO2Error("El o2 mínimo es obligatorio");
        return;
      }
      if (!speciesForm.max_o2) {
        setMaxO2Error("El o2 máximo es obligatorio");
        return;
      }
      if (!speciesForm.min_co2) {
        setMinCO2Error("El co2 mínimo es obligatorio");
        return;
      }
      if (!speciesForm.max_co2) {
        setMaxCO2Error("El co2 máximo es obligatorio");
        return;
      }
      if (!speciesForm.min_room_temperature) {
        setMinRoomTemperatureError(
          "La temperatura ambiente mínima es obligatoria"
        );
        return;
      }
      if (!speciesForm.max_room_temperature) {
        setMaxRoomTemperatureError(
          "La temperatura ambiente máxima es obligatoria"
        );
        return;
      }
      if (!speciesForm.min_pulp_temperature) {
        setMinPulpTemperatureError(
          "La temperatura pulpa mínima es obligatoria"
        );
        return;
      }
      if (!speciesForm.max_pulp_temperature) {
        setMaxPulpTemperatureError(
          "La temperatura pulpa máxima es obligatoria"
        );
        return;
      }
      if (!speciesForm.min_wind_speed) {
        setMinWindSpeedError("La velocidad viento mínima es obligatoria");
        return;
      }
      if (!speciesForm.max_wind_speed) {
        setMaxWindSpeedError("La velocidad viento máxima es obligatoria");
        return;
      }
      if (!speciesForm.min_relative_humidity) {
        setMinRelativeHumidityError(
          "La humedad relativa mínima es obligatoria"
        );
        return;
      }
      if (!speciesForm.max_relative_humidity) {
        setMaxRelativeHumidityError(
          "La humedad relativa máxima es obligatoria"
        );
        return;
      }

      await updateOrcreateSpecies();
    } catch (error) {
      console.error("Error al guardar la especie:", error);
      showToast("error", "Error", "Error al guardar la especie");
    }
  };

  const updateOrcreateSpecies = async () => {
    setDisableSave(true);
    try {
      if (selectedSpecie) {
        await updateSpecies(
          selectedSpecie.id,
          speciesForm.name,
          speciesForm.min_o2,
          speciesForm.max_o2,
          speciesForm.min_co2,
          speciesForm.max_co2,
          speciesForm.min_room_temperature,
          speciesForm.max_room_temperature,
          speciesForm.min_pulp_temperature,
          speciesForm.max_pulp_temperature,
          speciesForm.min_wind_speed,
          speciesForm.max_wind_speed,
          speciesForm.min_relative_humidity,
          speciesForm.max_relative_humidity
        );
      } else {
        await createSpecies(
          speciesForm.name,
          speciesForm.min_o2,
          speciesForm.max_o2,
          speciesForm.min_co2,
          speciesForm.max_co2,
          speciesForm.min_room_temperature,
          speciesForm.max_room_temperature,
          speciesForm.min_pulp_temperature,
          speciesForm.max_pulp_temperature,
          speciesForm.min_wind_speed,
          speciesForm.max_wind_speed,
          speciesForm.min_relative_humidity,
          speciesForm.max_relative_humidity
        );
      }

      loadSpecies();
      hideDialog();
      showToast("success", "Operación Exitosa", "Especie guardada con éxito");
      setDisableSave(false);
    } catch (error) {
      console.error("Error al guardar la especie:", error);
      showToast("error", "Error", "Error al guardar la especie");
      setDisableSave(false);
    }
  };

  const showConfirmationModal = () => {
    setConfirmationModalVisible(true);
  };

  const hideConfirmationModal = () => {
    setConfirmationModalVisible(false);
  };

  const confirmDelete = async () => {
    try {
      await deleteSpecies(selectedSpecie.id);
      loadSpecies();
      hideDialog();
      showToast("success", "Operación Exitosa", "Especie eliminada con éxito");
    } catch (error) {
      console.error("Error al eliminar la especie:", error);
      showToast("error", "Error", "Error al eliminar la especie");
    }
  };

  const showToast = (severity, summary, detail) => {
    toast.current.show({ severity, summary, detail });
  };

  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  const filteredSpecies = species.filter((specie) =>
    specie.name.toLowerCase().includes(searchText.toLowerCase())
  );

  const toast = React.useRef(null);

  return (
    <div className="Table">
      <div className="TableData">
        <h2>Especies</h2>
        <div className="TableHeader">
          <div>
            <Button
              label="Agregar Especie"
              icon="pi pi-plus"
              onClick={() => openDialog(null)}
              className="p-mb-2"
            />
          </div>
          <div>
            <span className="p-input-icon-left">
              <i className="pi pi-search" />
              <InputText
                placeholder="Buscar por nombre"
                value={searchText}
                onChange={handleSearch}
              />
            </span>
          </div>
        </div>
        <DataTable
          value={filteredSpecies}
          selectionMode="single"
          onSelectionChange={(e) => openDialog(e.value)}
        >
          <Column field="name" header="Nombre de especie" sortable></Column>
          <Column field={"min_o2"} header="O2 Mínimo (%)"></Column>
          <Column field="max_o2" header="O2 Máximo (%)"></Column>
          <Column field="min_co2" header="Co2 Mínimo (%)"></Column>
          <Column field="max_co2" header="Co2 Máximo (%)"></Column>
          <Column
            field="min_room_temperature"
            header="Temperatura Ambiente Mínima (°C)"
          ></Column>
          <Column
            field="max_room_temperature"
            header="Temperatura Ambiente Máxima (°C)"
          ></Column>
          <Column
            field="min_pulp_temperature"
            header="Temperatura Pulpa Mínima (°C)"
          ></Column>
          <Column
            field="max_pulp_temperature"
            header="Temperatura Pulpa Máxima (°C)"
          ></Column>
          <Column
            field="min_wind_speed"
            header="Velocidad Viento Mínima (m/s)"
          ></Column>
          <Column
            field="max_wind_speed"
            header="Velocidad Viento Máxima (m/s)"
          ></Column>
          <Column
            field="min_relative_humidity"
            header="Humedad Relativa Mínima (%)"
          ></Column>
          <Column
            field="max_relative_humidity"
            header="Humedad Relativa Máxima (%)"
          ></Column>
        </DataTable>
      </div>

      <Dialog
        visible={visible}
        onHide={hideDialog}
        header={isEditing ? "Editar Especie" : "Nueva Especie"}
      >
        <div className="p-fluid">
          <div className="p-field">
            <label htmlFor="name">Nombre</label>
            <InputText
              id="name"
              value={speciesForm.name}
              onChange={(e) => {
                setSpeciesForm({ ...speciesForm, name: e.target.value });
                setNameError("");
              }}
            />
            {nameError && <small className="p-error">{nameError}</small>}
          </div>
          <div className="p-field">
            <label htmlFor="min_o2">O2 Mínimo (%)</label>
            <InputText
              id="min_o2"
              value={speciesForm.min_o2}
              onChange={(e) => {
                setSpeciesForm({ ...speciesForm, min_o2: e.target.value });
                setMinO2Error("");
              }}
            />
            {minO2Error && <small className="p-error">{minO2Error}</small>}
          </div>
          <div className="p-field">
            <label htmlFor="max_o2">O2 Máximo (%)</label>
            <InputText
              id="max_o2"
              value={speciesForm.max_o2}
              onChange={(e) => {
                setSpeciesForm({ ...speciesForm, max_o2: e.target.value });
                setMaxO2Error("");
              }}
            />
            {maxO2Error && <small className="p-error">{maxO2Error}</small>}
          </div>
          <div className="p-field">
            <label htmlFor="min_co2">Co2 Mínimo (%)</label>
            <InputText
              id="min_co2"
              value={speciesForm.min_co2}
              onChange={(e) => {
                setSpeciesForm({ ...speciesForm, min_co2: e.target.value });
                setMinCO2Error("");
              }}
            />
            {minCO2Error && <small className="p-error">{minCO2Error}</small>}
          </div>
          <div className="p-field">
            <label htmlFor="max_co2">Co2 Máximo (%)</label>
            <InputText
              id="max_co2"
              value={speciesForm.max_co2}
              onChange={(e) => {
                setSpeciesForm({ ...speciesForm, max_co2: e.target.value });
                setMaxCO2Error("");
              }}
            />
            {maxCO2Error && <small className="p-error">{maxCO2Error}</small>}
          </div>
          <div className="p-field">
            <label htmlFor="min_room_temperature">
              Temperatura Ambiente Mínima (°C)
            </label>
            <InputText
              id="min_room_temperature"
              value={speciesForm.min_room_temperature}
              onChange={(e) => {
                setSpeciesForm({
                  ...speciesForm,
                  min_room_temperature: e.target.value,
                });
                setMinRoomTemperatureError("");
              }}
            />
            {minRoomTemperatureError && (
              <small className="p-error">{minRoomTemperatureError}</small>
            )}
          </div>
          <div className="p-field">
            <label htmlFor="max_room_temperature">
              Temperatura Ambiente Máxima (°C)
            </label>
            <InputText
              id="max_room_temperature"
              value={speciesForm.max_room_temperature}
              onChange={(e) => {
                setSpeciesForm({
                  ...speciesForm,
                  max_room_temperature: e.target.value,
                });
                setMaxRoomTemperatureError("");
              }}
            />
            {maxRoomTemperatureError && (
              <small className="p-error">{maxRoomTemperatureError}</small>
            )}
          </div>
          <div className="p-field">
            <label htmlFor="min_pulp_temperature">
              Temperatura Pulpa Mínima (°C)
            </label>
            <InputText
              id="min_pulp_temperature"
              value={speciesForm.min_pulp_temperature}
              onChange={(e) => {
                setSpeciesForm({
                  ...speciesForm,
                  min_pulp_temperature: e.target.value,
                });
                setMinPulpTemperatureError("");
              }}
            />
            {minPulpTemperatureError && (
              <small className="p-error">{minPulpTemperatureError}</small>
            )}
          </div>
          <div className="p-field">
            <label htmlFor="max_pulp_temperature">
              Temperatura Pulpa Máxima (°C)
            </label>
            <InputText
              id="max_pulp_temperature"
              value={speciesForm.max_pulp_temperature}
              onChange={(e) => {
                setSpeciesForm({
                  ...speciesForm,
                  max_pulp_temperature: e.target.value,
                });
                setMaxPulpTemperatureError("");
              }}
            />
            {maxPulpTemperatureError && (
              <small className="p-error">{maxPulpTemperatureError}</small>
            )}
          </div>
          <div className="p-field">
            <label htmlFor="min_wind_speed">
              Velocidad Viento Mínima (m/s)
            </label>
            <InputText
              id="min_wind_speed"
              value={speciesForm.min_wind_speed}
              onChange={(e) => {
                setSpeciesForm({
                  ...speciesForm,
                  min_wind_speed: e.target.value,
                });
                setMinWindSpeedError("");
              }}
            />
            {minWindSpeedError && (
              <small className="p-error">{minWindSpeedError}</small>
            )}
          </div>
          <div className="p-field">
            <label htmlFor="max_wind_speed">
              Velocidad Viento Máxima (m/s)
            </label>
            <InputText
              id="max_wind_speed"
              value={speciesForm.max_wind_speed}
              onChange={(e) => {
                setSpeciesForm({
                  ...speciesForm,
                  max_wind_speed: e.target.value,
                });
                setMaxWindSpeedError("");
              }}
            />
            {maxWindSpeedError && (
              <small className="p-error">{maxWindSpeedError}</small>
            )}
          </div>
          <div className="p-field">
            <label htmlFor="min_relative_humidity">
              Humedad Relativa Mínima (%)
            </label>
            <InputText
              id="min_relative_humidity"
              value={speciesForm.min_relative_humidity}
              onChange={(e) => {
                setSpeciesForm({
                  ...speciesForm,
                  min_relative_humidity: e.target.value,
                });
                setMinRelativeHumidityError("");
              }}
            />
            {minRelativeHumidityError && (
              <small className="p-error">{minRelativeHumidityError}</small>
            )}
          </div>
          <div className="p-field">
            <label htmlFor="max_relative_humidity">
              Humedad Relativa Máxima (%)
            </label>
            <InputText
              id="max_relative_humidity"
              value={speciesForm.max_relative_humidity}
              onChange={(e) => {
                setSpeciesForm({
                  ...speciesForm,
                  max_relative_humidity: e.target.value,
                });
                setMaxRelativeHumidityError("");
              }}
            />
            {maxRelativeHumidityError && (
              <small className="p-error">{maxRelativeHumidityError}</small>
            )}
          </div>
        </div>
        <div className="p-mt-2">
          <Button
            label="Guardar"
            icon="pi pi-check"
            onClick={saveUser}
            disabled={disableSave}
          />
          <Button
            label="Cancelar"
            icon="pi pi-times"
            className="p-button-secondary p-ml-2"
            onClick={hideDialog}
          />
          {selectedSpecie && (
            <Button
              label="Eliminar"
              icon="pi pi-trash"
              className="p-button-danger p-ml-2"
              onClick={showConfirmationModal}
            />
          )}
        </div>
      </Dialog>

      <ConfirmationModal
        visible={confirmationModalVisible}
        onHide={hideConfirmationModal}
        onConfirm={confirmDelete}
        onCancel={hideConfirmationModal}
        message={"esta especie"}
      />

      <Toast ref={toast} />
    </div>
  );
};

export default SpeciesTab;

export const isAdmin = () => {
  const userData = localStorage.getItem("user");
  const user = JSON.parse(userData);
  return user.profiles.map((profile) => profile.id).includes(1);
};

export const isReviewer = () => {
  const userData = localStorage.getItem("user");
  const user = JSON.parse(userData);
  return user.profiles.map((profile) => profile.id).includes(4);
};

export const isCommercial = () => {
  const userData = localStorage.getItem("user");
  const user = JSON.parse(userData);
  return user.profiles.map((profile) => profile.id).includes(3);
};

export const getUserData = () => {
  const userData = localStorage.getItem("user");
  return JSON.parse(userData);
};

export const logOut = () => {
  localStorage.removeItem("id");
  localStorage.removeItem("token");
  localStorage.removeItem("tokenExpiration");
  window.location.href = "/";
};

import { useState } from "react";
import { Calendar } from "primereact/calendar";

const EditableCalendar = ({ value, onChange }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [date, setDate] = useState(new Date(value));

  const startEditing = () => {
    setIsEditing(true);
  };
  const finishEditing = () => {
    setIsEditing(false);
    onChange(date);
  };
  return (
    <div className="p-inputgroup">
      <Calendar
        disabled={!isEditing}
        value={date}
        onChange={(e) => setDate(e.value)}
        dateFormat="dd/mm/yy"
      />
      <span className="p-inputgroup-addon" style={{ height: 36 }}>
        {isEditing ? (
          <i
            className="pi pi-check"
            onClick={finishEditing}
            style={{ cursor: "pointer" }}
          />
        ) : (
          <i
            className="pi pi-pencil"
            onClick={startEditing}
            style={{ cursor: "pointer" }}
          />
        )}
      </span>
    </div>
  );
};

export default EditableCalendar;

import { instance } from "./axios";

export const getProducts = () => {
  try {
    return instance.get("/products");
  } catch (error) {
    throw error;
  }
};

export const createProduct = (payload) => {
  try {
    return instance.post("/products", payload);
  } catch (error) {
    throw error;
  }
};

export const updateProduct = (id, payload) => {
  try {
    return instance.put(`/products/${id}`, payload);
  } catch (error) {
    throw error;
  }
};

export const deleteProduct = (id) => {
  try {
    return instance.delete(`/products/${id}`);
  } catch (error) {
    throw error;
  }
};

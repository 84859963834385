import xlsx from "json-as-xlsx";

export const generateXls = (jsonData) => {
  let data = [
    {
      sheet: "Traslados",
      columns: [
        { label: "Fecha de Visita", value: "date_of_visit" },
        { label: "Hora de Partida", value: "start_at" },
        { label: "Hora de LLegada", value: "end_at" },
        { label: "Duración Viaje", value: "duration" },
        { label: "Distancia Recorrida (km)", value: "distance" },
        { label: "Inspector", value: "user_name" },
        { label: "Tema", value: "visit_type_name" },
        { label: "Cliente", value: "customer_name" },
        { label: "Lugar", value: "place_name" },
        { label: "N° Reporte", value: "id" },
      ],
      content: jsonData,
    },
  ];

  let settings = {
    fileName: "BD Traslados", // Name of the resulting spreadsheet
    extraLength: 3, // A bigger number means that columns will be wider
    writeMode: "writeFile", // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
    writeOptions: {}, // Style options from https://docs.sheetjs.com/docs/api/write-options
    RTL: false, // Display the columns from right-to-left (the default value is false)
  };

  xlsx(data, settings);
};

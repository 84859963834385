import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import ConfirmationModal from "./ConfirmationModal";
import {
  getInstruments,
  createInstrument,
  updateInstrument,
  deleteInstrument,
  getInstrumentTypes,
} from "../api/instruments";
import "./TablesStyle.css";

const InstrumentsTab = () => {
  const [instruments, setInstruments] = useState([]);
  const [selectedInstrument, setSelectedInstrument] = useState(null);
  const [visible, setVisible] = useState(false);
  const [instrumentForm, setInstrumentForm] = useState({
    name: "",
    verified_at: null,
    next_verification: null,
    instrument_type: null,
  });
  const [confirmationModalVisible, setConfirmationModalVisible] =
    useState(false);
  const [searchText, setSearchText] = useState("");
  const [nameError, setNameError] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [instrumentTypes, setInstrumentTypes] = useState([]);
  const [disableSave, setDisableSave] = useState(false);
  const toast = React.useRef(null);

  useEffect(() => {
    loadInstruments();
    loadInstrumentTypes();
  }, []);

  const loadInstruments = async () => {
    try {
      const response = await getInstruments();
      setInstruments(response.data);
    } catch (error) {
      console.error("Error al obtener los instrumentos:", error);
    }
  };

  const loadInstrumentTypes = async () => {
    try {
      const response = await getInstrumentTypes();
      setInstrumentTypes(response.data);
    } catch (error) {
      console.error("Error al obtener los tipos de instrumentos:", error);
    }
  };

  const openDialog = (selectedRow) => {
    setSelectedInstrument(selectedRow);

    const defaultVerifiedAt = selectedRow?.verified_at
      ? new Date(selectedRow.verified_at)
      : null;

    const defaultNextVerification = selectedRow?.next_verification
      ? new Date(selectedRow.next_verification)
      : null;

    setInstrumentForm({
      name: selectedRow?.name || "",
      verified_at: defaultVerifiedAt || null,
      next_verification: defaultNextVerification || null,
      instrument_type: selectedRow?.instrument_type || null,
    });
    setVisible(true);
    setIsEditing(!!selectedRow);
  };

  const hideDialog = () => {
    setVisible(false);
    setSelectedInstrument(null);
    setInstrumentForm({
      name: "",
      verified_at: null,
      next_verification: null,
      instrument_type: null,
    });
    setNameError("");
    setIsEditing(false);
  };

  const saveInstrument = async () => {
    try {
      console.log("Instrument Form:", instrumentForm);
      if (!instrumentForm.name || !instrumentForm.instrument_type) {
        setNameError("El nombre y el tipo de instrumento son obligatorios");
        return;
      }

      await updateOrCreateInstrument();
    } catch (error) {
      console.error("Error al guardar el instrumento:", error);
      showToast("error", "Error", "Error al guardar el instrumento");
    }
  };
  const updateOrCreateInstrument = async () => {
    setDisableSave(true);
    try {
      if (selectedInstrument) {
        await updateInstrument(
          selectedInstrument.id,
          instrumentForm.name,
          instrumentForm.verified_at,
          instrumentForm.next_verification,
          instrumentForm.instrument_type
        );
      } else {
        await createInstrument(
          instrumentForm.name,
          instrumentForm.verified_at,
          instrumentForm.next_verification,
          instrumentForm.instrument_type
        );
      }
      loadInstruments();
      hideDialog();
      showToast(
        "success",
        "Operación Exitosa",
        "Instrumento guardado con éxito"
      );
      setDisableSave(false);
    } catch (error) {
      console.error("Error al guardar el instrumento:", error);
      showToast("error", "Error", "Error al guardar el instrumento");
      setDisableSave(false);
    }
  };

  const showConfirmationModal = () => {
    setConfirmationModalVisible(true);
  };

  const hideConfirmationModal = () => {
    setConfirmationModalVisible(false);
  };

  const confirmDelete = async () => {
    try {
      const instrumentId = selectedInstrument.rowId;
      await deleteInstrument(instrumentId);
      loadInstruments();
      hideDialog();
      showToast(
        "success",
        "Operación Exitosa",
        "Instrumento eliminado con éxito"
      );
      console.log("Instrumento eliminado", instrumentId);
    } catch (error) {
      console.error("Error al eliminar el instrumento:", error);
      showToast("error", "Error", "Error al eliminar el instrumento");
    }
  };

  const showToast = (severity, summary, detail) => {
    toast.current.show({ severity, summary, detail });
  };

  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  return (
    <div className="Table">
      <div className="TableData">
        <h2>Instrumentos</h2>
        <div className="TableHeader">
          <div>
            <Button
              label="Agregar Instrumento"
              icon="pi pi-plus"
              onClick={() => openDialog(null)}
              className="p-mb-2"
            />
          </div>
          <div>
            <span className="p-input-icon-left">
              <i className="pi pi-search" />
              <InputText
                placeholder="Buscar por nombre"
                value={searchText}
                onChange={handleSearch}
              />
            </span>
          </div>
        </div>
        <DataTable
          value={
            Array.isArray(instruments)
              ? instruments
                  .filter((instrument) =>
                    instrument.name
                      .toLowerCase()
                      .includes(searchText.toLowerCase())
                  )
                  .map((instrument) => ({
                    ...instrument,
                    rowId: instrument.id,
                  }))
              : []
          }
          selectionMode="single"
          onSelectionChange={(e) => openDialog(e.value)}
        >
          <Column
            field="type_name"
            header="Tipo de Instrumento"
            sortable
          ></Column>
          <Column field="name" header="Código" sortable></Column>
          <Column
            field="verified_at"
            header="Fecha última verificación"
            body={(rowData) =>
              rowData.verified_at
                ? new Date(rowData.verified_at).toLocaleDateString()
                : "No verificado"
            }
            sortable
          ></Column>
          <Column
            field="next_verification"
            header="Fecha próxima verificación"
            body={(rowData) => (
              <div className="p-d-flex p-ai-center">
                {rowData.next_verification && (
                  <span style={{ marginRight: "10px" }}>
                    {new Date(rowData.next_verification).toLocaleDateString()}
                  </span>
                )}
                {rowData.next_verification &&
                  new Date(rowData.next_verification) < new Date() && (
                    <i
                      className="pi pi-exclamation-triangle"
                      style={{ color: "red" }}
                    />
                  )}
                {!rowData.next_verification && "No programado"}
              </div>
            )}
            sortable
          />
        </DataTable>
      </div>

      <Dialog
        visible={visible}
        onHide={hideDialog}
        header={isEditing ? "Editar Instrumento" : "Nuevo Instrumento"}
      >
        <div className="p-fluid">
          <div className="p-field">
            <label htmlFor="instrument_type">Tipo de Instrumento</label>
            <Dropdown
              id="instrument_type"
              optionLabel="name"
              optionValue="id"
              value={instrumentForm.instrument_type}
              options={instrumentTypes}
              onChange={(e) =>
                setInstrumentForm({
                  ...instrumentForm,
                  instrument_type: e.value,
                })
              }
              placeholder="Seleccione un tipo"
            />
          </div>
          <div className="p-field">
            <label htmlFor="name">Código</label>
            <InputText
              id="name"
              value={instrumentForm.name}
              onChange={(e) => {
                setInstrumentForm({ ...instrumentForm, name: e.target.value });
                setNameError("");
              }}
            />
            {nameError && <small className="p-error">{nameError}</small>}
          </div>
          <div className="p-field">
            <label htmlFor="verified_at">Fecha última verificación</label>
            <Calendar
              id="verified_at"
              value={instrumentForm.verified_at}
              onChange={(e) =>
                setInstrumentForm({
                  ...instrumentForm,
                  verified_at: e.value,
                })
              }
              maxDate={new Date()}
              dateFormat="dd/mm/yy"
            />
          </div>
          <div className="p-field">
            <label htmlFor="next_verification">
              Fecha próxima verificación
            </label>
            <Calendar
              id="next_verification"
              value={instrumentForm.next_verification}
              onChange={(e) =>
                setInstrumentForm({
                  ...instrumentForm,
                  next_verification: e.value,
                })
              }
              dateFormat="dd/mm/yy"
            />
          </div>
        </div>
        <div className="p-mt-2">
          <Button
            label="Guardar"
            icon="pi pi-check"
            onClick={saveInstrument}
            disabled={disableSave}
          />
          <Button
            label="Cancelar"
            icon="pi pi-times"
            className="p-button-secondary p-ml-2"
            onClick={hideDialog}
          />
          {selectedInstrument && (
            <Button
              label="Eliminar"
              icon="pi pi-trash"
              className="p-button-danger p-ml-2"
              onClick={showConfirmationModal}
            />
          )}
        </div>
      </Dialog>

      <ConfirmationModal
        visible={confirmationModalVisible}
        onHide={hideConfirmationModal}
        onConfirm={confirmDelete}
        onCancel={hideConfirmationModal}
        message={"este instrumento"}
      />

      <Toast ref={toast} />
    </div>
  );
};

export default InstrumentsTab;

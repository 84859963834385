import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { FileUpload } from "primereact/fileupload";
import { Toast } from "primereact/toast";
import ConfirmationModal from "./ConfirmationModal";
import {
  getCustomers,
  createCustomer,
  deleteCustomer,
  updateCustomer,
} from "../api/customers";
import "./TablesStyle.css";
import PlacesTab from "./Places";
import ContactsTab from "./Contacts";
import Cropper from "./Cropper";

const CustomersTab = () => {
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [visible, setVisible] = useState(false);
  const [customerForm, setCustomerForm] = useState({
    name: "",
    logo: "",
    logoPreview: null,
  });
  const [confirmationModalVisible, setConfirmationModalVisible] =
    useState(false);
  const [searchText, setSearchText] = useState("");
  const [nameError, setNameError] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [disableSave, setDisableSave] = useState(false);
  const [displayImageCrop, setDisplayImageCrop] = useState(false);
  const [newImage, setNewImage] = useState(null);

  useEffect(() => {
    loadCustomers();
  }, []);

  const loadCustomers = async () => {
    try {
      const response = await getCustomers();
      setCustomers(response.data);
    } catch (error) {
      console.error("Error al obtener clientes:", error);
    }
  };

  const openDialog = (customer) => {
    setSelectedCustomer(customer);
    setCustomerForm({
      name: customer?.name || "",
      logo: customer?.logo || "",
      logoPreview: customer?.logo || null,
    });
    setVisible(true);
    setIsEditing(!!customer);
  };

  const hideDialog = () => {
    setVisible(false);
    setSelectedCustomer(null);
    setCustomerForm({ name: "", logo: "", logoPreview: null });
    setNameError("");
    setIsEditing(false);
  };

  const saveCustomer = async () => {
    try {
      if (!customerForm.name) {
        setNameError("El nombre del cliente es obligatorio");
        return;
      }

      await updateOrCreateCustomer();
    } catch (error) {
      console.error("Error al guardar el cliente:", error);
      showToast("error", "Error", "Error al guardar el cliente");
    }
  };

  const updateOrCreateCustomer = async () => {
    setDisableSave(true);
    try {
      if (selectedCustomer) {
        await updateCustomer(
          selectedCustomer.id,
          customerForm.name,
          customerForm.logo
        );
      } else {
        await createCustomer(customerForm.name, customerForm.logo);
      }
      loadCustomers();
      hideDialog();
      showToast("success", "Operación Exitosa", "Cliente guardado con éxito");
      setDisableSave(false);
    } catch (error) {
      console.error("Error al guardar el cliente:", error);
      showToast("error", "Error", "Error al guardar el cliente");
      setDisableSave(false);
    }
  };

  const showConfirmationModal = () => {
    setConfirmationModalVisible(true);
  };

  const hideConfirmationModal = () => {
    setConfirmationModalVisible(false);
  };

  const confirmDelete = async () => {
    try {
      await deleteCustomer(selectedCustomer.id);
      loadCustomers();
      hideDialog();
      showToast("success", "Operación Exitosa", "Cliente eliminado con éxito");
    } catch (error) {
      console.error("Error al eliminar el cliente:", error);
      showToast("error", "Error", "Error al eliminar el cliente");
    }
  };

  const showToast = (severity, summary, detail) => {
    toast.current.show({ severity, summary, detail });
  };

  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  const onImageCrop = (image) => {
    setCustomerForm((prevForm) => ({
      ...prevForm,
      logo: image,
      logoPreview: image,
    }));
    setDisplayImageCrop(false);
  };

  const onFileSelect = async (event) => {
    const file = event.files[0];
    if (!file) return;
    setNewImage(URL.createObjectURL(file));
    setDisplayImageCrop(true);
  };

  const filteredCustomers = customers.filter((customer) =>
    customer.name.toLowerCase().includes(searchText.toLowerCase())
  );

  const toast = React.useRef(null);

  return (
    <div className="Table">
      <div className="TableData">
        <h2>Clientes</h2>
        <div className="TableHeader">
          <div>
            <Button
              label="Agregar Cliente"
              icon="pi pi-plus"
              onClick={() => openDialog(null)}
              className="p-mb-2"
            />
          </div>
          <div>
            <span className="p-input-icon-left">
              <i className="pi pi-search" />
              <InputText
                placeholder="Buscar por nombre"
                value={searchText}
                onChange={handleSearch}
              />
            </span>
          </div>
        </div>
        <DataTable
          value={filteredCustomers}
          selectionMode="single"
          onSelectionChange={(e) => openDialog(e.value)}
        >
          <Column field="name" header="Nombre" sortable></Column>
          <Column field="logo" header="Logo" body={imageBody}></Column>
        </DataTable>
      </div>

      <Dialog
        visible={visible}
        onHide={hideDialog}
        header={isEditing ? "Editar Cliente" : "Nuevo Cliente"}
        style={{
          width: isEditing ? "90vw" : "40%",
          height: isEditing ? "100vh" : "25vw",
          overflow: "auto",
        }}
      >
        <div style={{ textAlign: "left", paddingLeft: "7rem" }}>
          <label htmlFor="name">Nombre</label>
          <div
            className="p-fluid"
            style={{ display: "flex", justifyContent: "flex-start" }}
          >
            <div className="p-field">
              <InputText
                id="name"
                value={customerForm.name}
                onChange={(e) => {
                  setCustomerForm({ ...customerForm, name: e.target.value });
                  setNameError("");
                }}
              />
              {nameError && <small className="p-error">{nameError}</small>}
            </div>
            <div
              className="p-field"
              style={{ marginTop: "-0.5rem", marginLeft: "0.5rem" }}
            >
              <FileUpload
                mode="basic"
                auto
                accept="image/*"
                onSelect={onFileSelect}
                chooseLabel={"Imagen"}
              />
            </div>
          </div>
          {customerForm.logoPreview && (
            <img
              src={customerForm.logoPreview}
              alt="Vista previa del logo"
              style={{
                width: isEditing ? "15%" : "25%",
                objectFit: "cover",
                marginTop: "0.5rem",
              }}
            />
          )}
          <div className="p-mt-2">
            <Button
              label="Guardar"
              icon="pi pi-check"
              onClick={saveCustomer}
              disabled={disableSave}
            />
            <Button
              label="Cancelar"
              icon="pi pi-times"
              className="p-button-secondary p-ml-2"
              onClick={hideDialog}
            />
            {selectedCustomer && (
              <Button
                label="Eliminar"
                icon="pi pi-trash"
                className="p-button-danger p-ml-2"
                onClick={showConfirmationModal}
              />
            )}
          </div>

          {isEditing && (
            <hr
              style={{
                border: "none",
                borderBottom: "1.5px solid #ccc",
                margin: "1.5rem 0 1rem",
                width: "90%",
              }}
            />
          )}

          <div className="places-table">
            {isEditing && (
              <PlacesTab
                customer={selectedCustomer}
                showToast={showToast}
                loadCustomers={loadCustomers}
              />
            )}
          </div>
          <div className="contacts-table">
            {isEditing && (
              <ContactsTab
                customer={selectedCustomer}
                showToast={showToast}
                loadCustomers={loadCustomers}
              />
            )}
          </div>
        </div>
        <Cropper
          src={newImage}
          visible={displayImageCrop}
          onHide={() => {
            setDisplayImageCrop(false);
            setNewImage(null);
          }}
          onFinish={onImageCrop}
        />
      </Dialog>

      <ConfirmationModal
        visible={confirmationModalVisible}
        onHide={hideConfirmationModal}
        onConfirm={confirmDelete}
        onCancel={hideConfirmationModal}
      />

      <Toast ref={toast} />
    </div>
  );
};

export default CustomersTab;

function imageBody(rowData) {
  return rowData.logo ? (
    <img
      src={rowData.logo}
      alt={rowData.name}
      style={{ width: "50px", height: "50px", objectFit: "cover" }}
    />
  ) : null;
}

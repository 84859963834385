import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { FileUpload } from "primereact/fileupload";
import { Toast } from "primereact/toast";
import ConfirmationModal from "./ConfirmationModal";
import {
  getAllBrands,
  createBrand,
  updateBrand,
  deleteBrand,
} from "../api/brands";
import "./TablesStyle.css";
import Cropper from "./Cropper";

const BrandsTab = () => {
  const [brands, setBrands] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [visible, setVisible] = useState(false);
  const [displayImageCrop, setDisplayImageCrop] = useState(false);
  const [newImage, setNewImage] = useState(null);
  const [brandForm, setBrandForm] = useState({
    name: "",
    logo: "",
    logoPreview: null,
  });
  const [confirmationModalVisible, setConfirmationModalVisible] =
    useState(false);
  const [searchText, setSearchText] = useState("");
  const [nameError, setNameError] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [disableSave, setDisableSave] = useState(false);
  useEffect(() => {
    loadBrands();
  }, []);

  const loadBrands = async () => {
    try {
      const response = await getAllBrands();
      setBrands(response.data);
    } catch (error) {
      console.error("Error al obtener marcas:", error);
    }
  };

  const openDialog = (brand) => {
    setSelectedBrand(brand);
    setBrandForm({
      name: brand?.name || "",
      logo: brand?.logo || "",
      logoPreview: brand?.logo || null,
    });
    setVisible(true);
    setIsEditing(!!brand);
  };

  const hideDialog = () => {
    setVisible(false);
    setSelectedBrand(null);
    setBrandForm({ name: "", logo: "", logoPreview: null });
    setNameError("");
    setIsEditing(false);
  };

  const saveBrand = async () => {
    try {
      if (!brandForm.name) {
        setNameError("El nombre es obligatorio");
        return;
      }
      await updateOrCreateBrand();
    } catch (error) {
      console.error("Error al guardar la marca:", error);
      showToast("error", "Error", "Error al guardar la marca");
    }
  };

  const updateOrCreateBrand = async () => {
    setDisableSave(true);
    try {
      if (selectedBrand) {
        await updateBrand(selectedBrand.id, brandForm.name, brandForm.logo);
      } else {
        await createBrand(brandForm.name, brandForm.logo);
      }
      loadBrands();
      hideDialog();
      showToast("success", "Operación Exitosa", "Marca guardada con éxito");
      setDisableSave(false);
    } catch (error) {
      console.error("Error al guardar la marca:", error);
      showToast("error", "Error", "Error al guardar la marca");
      setDisableSave(false);
    }
  };

  const showConfirmationModal = () => {
    setConfirmationModalVisible(true);
  };

  const hideConfirmationModal = () => {
    setConfirmationModalVisible(false);
  };

  const confirmDelete = async () => {
    try {
      await deleteBrand(selectedBrand.id);
      loadBrands();
      hideDialog();
      showToast("success", "Operación Exitosa", "Marca eliminada con éxito");
    } catch (error) {
      console.error("Error al eliminar la marca:", error);
      showToast("error", "Error", "Error al eliminar la marca");
    }
  };

  const showToast = (severity, summary, detail) => {
    toast.current.show({ severity, summary, detail });
  };

  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  const onImageCrop = (image) => {
    setBrandForm((prevForm) => ({
      ...prevForm,
      logo: image,
      logoPreview: image,
    }));
    setDisplayImageCrop(false);
  };

  const onFileSelect = async (event) => {
    const file = event.files[0];
    if (!file) return;
    setNewImage(URL.createObjectURL(file));
    setDisplayImageCrop(true);
  };

  const filteredBrands = brands.filter((brand) =>
    brand.name.toLowerCase().includes(searchText.toLowerCase())
  );

  const toast = React.useRef(null);

  return (
    <div className="Table">
      <div className="TableData">
        <h2>Marcas</h2>
        <div className="TableHeader">
          <div>
            <Button
              label="Agregar Marca"
              icon="pi pi-plus"
              onClick={() => openDialog(null)}
              className="p-mb-2"
            />
          </div>
          <div>
            <span className="p-input-icon-left">
              <i className="pi pi-search" />
              <InputText
                placeholder="Buscar por nombre"
                value={searchText}
                onChange={handleSearch}
              />
            </span>
          </div>
        </div>
        <DataTable
          value={filteredBrands}
          selectionMode="single"
          onSelectionChange={(e) => openDialog(e.value)}
        >
          <Column field="name" header="Nombre" sortable></Column>
          <Column field="logo" header="Logo" body={imageBody}></Column>
        </DataTable>
      </div>

      <Dialog
        visible={visible}
        onHide={hideDialog}
        header={isEditing ? "Editar Marca" : "Nueva Marca"}
        style={{
          width: isEditing ? "90vw" : "40%",
          height: isEditing ? "100vh" : "25vw",
          overflow: "auto",
        }}
      >
        <div style={{ textAlign: "left", paddingLeft: "7rem" }}>
          <label htmlFor="name">Nombre</label>
          <div
            className="p-fluid"
            style={{ display: "flex", justifyContent: "flex-start" }}
          >
            <div className="p-field">
              <InputText
                id="name"
                value={brandForm.name}
                onChange={(e) => {
                  setBrandForm({ ...brandForm, name: e.target.value });
                  setNameError("");
                }}
              />
              {nameError && <small className="p-error">{nameError}</small>}
            </div>
            <div
              className="p-field"
              style={{ marginTop: "-0.5rem", marginLeft: "0.5rem" }}
            >
              <FileUpload
                mode="basic"
                auto
                accept="image/*"
                onSelect={onFileSelect}
                chooseLabel={"Imagen"}
              />
            </div>
          </div>
          {brandForm.logoPreview && (
            <img
              src={brandForm.logoPreview}
              alt="Vista previa del logo"
              style={{
                width: isEditing ? "15%" : "25%",
                objectFit: "cover",
                marginTop: "0.5rem",
              }}
            />
          )}
          <div className="p-mt-2">
            <Button
              label="Guardar"
              icon="pi pi-check"
              onClick={saveBrand}
              disabled={disableSave}
            />
            <Button
              label="Cancelar"
              icon="pi pi-times"
              className="p-button-secondary p-ml-2"
              onClick={hideDialog}
            />
            {selectedBrand && (
              <Button
                label="Eliminar"
                icon="pi pi-trash"
                className="p-button-danger p-ml-2"
                onClick={showConfirmationModal}
              />
            )}
          </div>

          {isEditing && (
            <hr
              style={{
                border: "none",
                borderBottom: "1.5px solid #ccc",
                margin: "1.5rem 0 1rem",
                width: "90%",
              }}
            />
          )}
        </div>
        <Cropper
          src={newImage}
          visible={displayImageCrop}
          onHide={() => {
            setDisplayImageCrop(false);
            setNewImage(null);
          }}
          onFinish={onImageCrop}
        />
      </Dialog>

      <ConfirmationModal
        visible={confirmationModalVisible}
        onHide={hideConfirmationModal}
        onConfirm={confirmDelete}
        onCancel={hideConfirmationModal}
      />

      <Toast ref={toast} />
    </div>
  );
};

export default BrandsTab;

function imageBody(rowData) {
  return rowData.logo ? (
    <img
      src={rowData.logo}
      alt={rowData.name}
      style={{ width: "50px", height: "50px", objectFit: "cover" }}
    />
  ) : null;
}

import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import React, { useRef } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

const CropperComponent = ({ visible, onHide, src, onFinish }) => {
  const cropperRef = useRef(null);
  const handleFinish = () => {
    const cropper = cropperRef.current?.cropper;
    const base64 = cropper.getCroppedCanvas().toDataURL();
    onFinish(base64);
    onHide();
  };
  return (
    <Dialog
      header="Encuadrar la imagen"
      visible={visible}
      style={{ width: "900px", height: "700px", padding: "100" }}
      onHide={onHide}
    >
      <Cropper
        src={src}
        style={{ height: 400, width: "100%" }}
        initialAspectRatio={1}
        aspectRatio={1}
        guides={false}
        ref={cropperRef}
        dragMode="move"
      />
      <Button
        label="Terminar"
        onClick={handleFinish}
        className="p-mb-2"
        style={{ position: "absolute", bottom: 10, right: 10 }}
      />
    </Dialog>
  );
};

export default CropperComponent;

import React, { useEffect, useState } from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { createOneMetrology, deleteMetrology } from "../api/metrology";
import { getInstruments } from "../api/instruments";

const MetrologyPanel = ({ metrologies, visitId, onChange }) => {
  const [instruments, setInstruments] = useState([]);
  const [instrumentToAdd, setInstrumentToAdd] = useState(null);

  useEffect(() => {
    loadInstruments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadInstruments = async () => {
    try {
      const response = await getInstruments();
      setInstruments(response.data);
    } catch (error) {
      console.error("Error al obtener los instrumentos:", error);
    }
  };

  const handleAddInstrument = async () => {
    try {
      if (!instrumentToAdd) {
        showToast(
          "warn",
          "Advertencia",
          "Selecciona un instrumento antes de agregar."
        );
        return;
      }

      const newMetrology = await createOneMetrology({
        visit_id: visitId,
        instrument_id: instrumentToAdd,
      });

      const instrumentName = instruments.find(
        (instrument) => instrument.id === instrumentToAdd
      )?.name;

      if (typeof onChange === "function") {
        metrologies.push({
          visit_id: newMetrology.visit_id,
          instrument_name: instrumentName,
        });
        onChange([...metrologies]);
      }

      showToast("success", "Éxito", "Instrumento agregado correctamente");
      loadInstruments();
    } catch (error) {
      console.error("Error al agregar el instrumento:", error);
      showToast("error", "Error", "Error al agregar el instrumento");
    }
  };

  const handleDeleteInstrument = async (metrologyId) => {
    try {
      await deleteMetrology(metrologyId);

      if (typeof onChange === "function") {
        onChange(
          metrologies.filter((metrology) => metrology.id !== metrologyId)
        );
      }

      showToast("success", "Éxito", "Instrumento eliminado correctamente");
    } catch (error) {
      console.error("Error al eliminar el instrumento:", error);
      showToast("error", "Error", "Error al eliminar el instrumento");
    }
  };

  const showToast = (severity, summary, detail) => {
    toast.current.show({ severity, summary, detail });
  };

  const toast = React.useRef(null);

  return (
    <div>
      <Accordion>
        <AccordionTab header="Metrología">
          <div>
            {instruments && instruments.length > 0 && (
              <div className="p-mb-2" style={{ marginTop: "0.5rem" }}>
                <Dropdown
                  optionLabel="name"
                  optionValue="id"
                  value={instrumentToAdd}
                  options={instruments}
                  onChange={(e) => setInstrumentToAdd(e.value)}
                  placeholder="Seleccionar Instrumento"
                />
                <Button
                  label="Agregar"
                  icon="pi pi-plus"
                  onClick={handleAddInstrument}
                  style={{ marginLeft: "1rem" }}
                />
              </div>
            )}
            {metrologies && metrologies.length > 0 ? (
              <DataTable value={metrologies}>
                <Column
                  field="instrument_name"
                  header="Nombre del Instrumento"
                />
                <Column
                  body={(rowData) => (
                    <Button
                      label="Eliminar"
                      icon="pi pi-trash"
                      onClick={() => handleDeleteInstrument(rowData.id)}
                    />
                  )}
                />
              </DataTable>
            ) : (
              <div className="p-mb-2" style={{ marginTop: "0.5rem" }}>
                <strong>No hay ningún instrumento asociado.</strong>
              </div>
            )}
          </div>
        </AccordionTab>
      </Accordion>
      <Toast ref={toast} />
    </div>
  );
};

export default MetrologyPanel;

import { instance } from "./axios";

export const getCustomers = async () => {
  try {
    return await instance.get("/customers");
  } catch (error) {
    throw error;
  }
};

export const getCustomerById = async (id) => {
  try {
    return await instance.get(`/customers/${id}`);
  } catch (error) {
    throw error;
  }
};

export const createCustomer = async (name, logo) => {
  try {
    return await instance.post("/customers", {
      name,
      logo,
    });
  } catch (error) {
    throw error;
  }
};

export const updateCustomer = async (id, name, logo) => {
  try {
    return await instance.put(`/customers/${id}`, {
      name,
      logo,
    });
  } catch (error) {
    throw error;
  }
};

export const deleteCustomer = async (id) => {
  try {
    return await instance.delete(`/customers/${id}`);
  } catch (error) {
    throw error;
  }
};

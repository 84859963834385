import { instance } from "./axios";

export const getContacts = async () => {
  try {
    return await instance.get("/contacts");
  } catch (error) {
    throw error;
  }
};

export const getContactsById = async (id) => {
  try {
    return await instance.get(`/contacts/${id}`);
  } catch (error) {
    throw error;
  }
};

export const createContact = async (
  name,
  last_name,
  email,
  phone,
  corporate,
  customer_id,
  place_id
) => {
  try {
    return await instance.post("/contacts", {
      name,
      last_name,
      email,
      phone,
      corporate,
      customer_id,
      place_id,
    });
  } catch (error) {
    throw error;
  }
};

export const updateContact = async (
  id,
  name,
  last_name,
  email,
  phone,
  corporate,
  place_id
) => {
  try {
    return await instance.put(`/contacts/${id}`, {
      name,
      last_name,
      email,
      phone,
      corporate,
      place_id
    });
  } catch (error) {
    throw error;
  }
};

export const deleteContact = async (id) => {
  try {
    return await instance.delete(`/contacts/${id}`);
  } catch (error) {
    throw error;
  }
};

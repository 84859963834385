import React, { useState, useEffect } from "react";
import "../TablesStyle.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { getCommercialVisits } from "../../api/visits";
import { formatDate } from "../../utils/formatDate";
import VisitDialog from "./VisitDialog";

const Commercialvisits = () => {
  const [visits, setVisits] = useState([]);
  const [selectedVisit, setSelectedVisit] = useState(null);
  const [displayDialog, setDisplayDialog] = useState(false);

  const loadVisits = async () => {
    try {
      const response = await getCommercialVisits();
      setVisits(response.data);
      if (selectedVisit) {
        const visit = response.data.find((v) => v.id === selectedVisit.id);
        setSelectedVisit(visit);
      }
    } catch (error) {
      console.error("Error fetching visits:", error);
    }
  };

  const openDialog = (visit) => {
    console.log(visit);
    setSelectedVisit(visit);
    setDisplayDialog(true);
  };

  const hideDialog = () => {
    setDisplayDialog(false);
  };

  useEffect(() => {
    loadVisits();
    // eslint-disable-next-line
  }, []);
  return (
    <div className="Table">
      <div className="TableData">
        <h2>Visitas Comerciales</h2>
        <DataTable
          value={visits}
          selectionMode="single"
          onSelectionChange={(e) => openDialog(e.value)}
        >
          <Column field="id" header="#" sortable />
          <Column
            field="date_of_visit"
            header="Fecha de visita"
            body={(rowData) => formatDate(new Date(rowData.date_of_visit))}
            sortable
          />
          <Column field="customer_name" header="Cliente" sortable />
          <Column field="place_name" header="Planta" sortable />
          <Column field="user_name" header="Usuario" sortable />
        </DataTable>
      </div>
      <VisitDialog
        visible={displayDialog}
        hideDialog={hideDialog}
        visit={selectedVisit}
        loadVisits={loadVisits}
      />
    </div>
  );
};
export default Commercialvisits;

import { createContext, useState } from "react";
import { loginUser } from "../api/auth";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  const login = async (email, password) => {
    try {
      const { user, token } = await loginUser(email, password);
      setUser(user);
      localStorage.setItem("id", JSON.stringify(user.id));
      localStorage.setItem("token", token);
      localStorage.setItem(
        "tokenExpiration",
        new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toISOString()
      );
      return { user, token };
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const logout = () => {
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

import { instance } from "./axios";

export const getAllBrands = () => {
  try {
    return instance.get("/brands");
  } catch (error) {
    throw error;
  }
};

export const createBrand = async (name, logo) => {
  try {
    return await instance.post("/brands", {
      name,
      logo,
    });
  } catch (error) {
    throw error;
  }
};

export const updateBrand = async (id, name, logo) => {
  try {
    return await instance.put(`/brands/${id}`, {
      name,
      logo,
    });
  } catch (error) {
    throw error;
  }
};

export const deleteBrand = async (id) => {
  try {
    return await instance.delete(`/brands/${id}`);
  } catch (error) {
    throw error;
  }
};

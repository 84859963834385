import { instance } from "./axios";

export const getAllVisits = async (startDate, endDate) => {
  const formattedStartDate = new Date(startDate).toISOString().slice(0, 10);
  const formattedEndDate = new Date(endDate).toISOString().slice(0, 10);

  try {
    return await instance.get(
      "/visit?startDate=" + formattedStartDate + "&endDate=" + formattedEndDate
    );
  } catch (error) {
    throw error;
  }
};

export const getCommercialVisits = async () => {
  try {
    return await instance.get("visit/commercial");
  } catch (error) {
    throw error;
  }
};

export const updateVisit = async (payload, visitId) => {
  try {
    return await instance.put(`/visit/${visitId}`, payload);
  } catch (error) {
    throw error;
  }
};

export const updateReceptionComment = async (payload, visitId) => {
  try {
    return await instance.put(`/visit/reception-comment/${visitId}`, payload);
  } catch (error) {
    throw error;
  }
};

export const updatePackagingMaterialsComment = async (payload, visitId) => {
  try {
    return await instance.put(
      `/visit/packaging-materials-comment/${visitId}`,
      payload
    );
  } catch (error) {
    throw error;
  }
};

export const updateCommercialVisit = async (payload, visitId) => {
  try {
    return await instance.put(`/visit/commercial/${visitId}`, payload);
  } catch (error) {
    throw error;
  }
};

export const updatePurpose = async (payload, visitId) => {
  try {
    return await instance.put(`/visit/commercial/purpose/${visitId}`, payload);
  } catch (error) {
    throw error;
  }
};

export const updatTopics = async (payload, visitId) => {
  try {
    return await instance.put(`/visit/commercial/topics/${visitId}`, payload);
  } catch (error) {
    throw error;
  }
};

export const getAllVisitTypes = async () => {
  try {
    return await instance.get("/visit-types");
  } catch (error) {
    throw error;
  }
};

export const updateAttendees = async (payload, attendeeId) => {
  try {
    return await instance.put(`/attendees/${attendeeId}`, payload);
  } catch (error) {
    throw error;
  }
};

export const addNewAttendee = async (payload) => {
  try {
    return await instance.post("/attendees", payload);
  } catch (error) {
    throw error;
  }
};

export const removeAttendee = async (attendeeId) => {
  try {
    return await instance.delete(`/attendees/${attendeeId}`);
  } catch (error) {
    throw error;
  }
};

export const updateCommitments = async (payload, commitmentId) => {
  try {
    return await instance.put(`/commitments/${commitmentId}`, payload);
  } catch (error) {
    throw error;
  }
};

export const addNewCommitment = async (payload) => {
  try {
    return await instance.post("/commitments", payload);
  } catch (error) {
    throw error;
  }
};

export const removeCommitment = async (commitmentId) => {
  try {
    return await instance.delete(`/commitments/${commitmentId}`);
  } catch (error) {
    throw error;
  }
};

import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import ConfirmationModal from "./ConfirmationModal";
import { getSpecies } from "../api/species";
import {
  getPackings,
  createPacking,
  updatePacking,
  deletePacking,
} from "../api/packing";
import "./TablesStyle.css";

const Packings = () => {
  const [packings, setPackings] = useState([]);
  const [selectedPacking, setSelectedPacking] = useState(null);
  const [visible, setVisible] = useState(false);
  const [packingForm, setPackingForm] = useState({
    name: "",
    kilograms: 0,
    specie_id: null,
  });
  const [confirmationModalVisible, setConfirmationModalVisible] =
    useState(false);
  const [searchText, setSearchText] = useState("");
  const [nameError, setNameError] = useState("");
  const [kilogramsError, setKilogramsError] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [disableSave, setDisableSave] = useState(false);
  const [species, setSpecies] = useState([]);

  useEffect(() => {
    loadPackings();
  }, []);

  const loadPackings = async () => {
    try {
      const response = await getPackings();
      setPackings(response.data);
      const speciesResponse = await getSpecies();
      setSpecies(speciesResponse.data);
    } catch (error) {
      console.error("Error al obtener formatos:", error);
    }
  };

  const openDialog = (product) => {
    setSelectedPacking(product);
    setPackingForm({
      name: product?.name || "",
      kilograms: product?.kilograms || "",
      specie_id: product?.specie_id || null,
    });
    setVisible(true);
    setIsEditing(!!product);
  };

  const hideDialog = () => {
    setVisible(false);
    setSelectedPacking(null);
    setPackingForm({ name: "", kilograms: 0, specie_id: null });
    setNameError("");
    setIsEditing(false);
  };

  const savePacking = async () => {
    try {
      if (!packingForm.name) {
        setNameError("El nombre es obligatorio");
        return;
      }
      if (!packingForm.kilograms) {
        setKilogramsError("Los kilogramos son obligatorios");
        return;
      }
      await updateOrCreatePacking();
    } catch (error) {
      console.error("Error al guardar formato:", error);
      showToast("error", "Error", "Error al guardar formato");
    }
  };

  const updateOrCreatePacking = async () => {
    setDisableSave(true);
    try {
      const payload = {
        name: packingForm.name,
        kilograms: packingForm.kilograms,
        specie_id: packingForm.specie_id,
      };
      if (selectedPacking) await updatePacking(selectedPacking.id, payload);
      else await createPacking(payload);
      loadPackings();
      hideDialog();
      showToast("success", "Operación Exitosa", "Formato guardado con éxito");
      setDisableSave(false);
    } catch (error) {
      console.error("Error al guardar el formato:", error);
      showToast("error", "Error", "Error al guardar el formato");
      setDisableSave(false);
    }
  };

  const showConfirmationModal = () => {
    setConfirmationModalVisible(true);
  };

  const hideConfirmationModal = () => {
    setConfirmationModalVisible(false);
  };

  const confirmDelete = async () => {
    try {
      await deletePacking(selectedPacking.id);
      loadPackings();
      hideDialog();
      showToast("success", "Operación Exitosa", "Formato eliminado con éxito");
    } catch (error) {
      console.error("Error al eliminar formato:", error);
      showToast("error", "Error", "Error al eliminar formato");
    }
  };

  const showToast = (severity, summary, detail) => {
    toast.current.show({ severity, summary, detail });
  };

  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  const filteredPackings = packings.filter((packing) =>
    packing.name.toLowerCase().includes(searchText.toLowerCase())
  );

  const toast = React.useRef(null);

  return (
    <div className="Table">
      <div className="TableData">
        <h2>Formatos</h2>
        <div className="TableHeader">
          <div>
            <Button
              label="Agregar Formato"
              icon="pi pi-plus"
              onClick={() => openDialog(null)}
              className="p-mb-2"
            />
          </div>
          <div>
            <span className="p-input-icon-left">
              <i className="pi pi-search" />
              <InputText
                placeholder="Buscar por nombre"
                value={searchText}
                onChange={handleSearch}
              />
            </span>
          </div>
        </div>
        <DataTable
          value={filteredPackings}
          selectionMode="single"
          onSelectionChange={(e) => openDialog(e.value)}
        >
          <Column field="name" header="Nombre" sortable></Column>
          <Column field="kilograms" header="Kilogramos"></Column>
          <Column field="specie_name" header="Especie"></Column>
        </DataTable>
      </div>

      <Dialog
        visible={visible}
        onHide={hideDialog}
        header={isEditing ? "Editar Formato" : "Nuevo Formato"}
        style={{
          width: isEditing ? "90vw" : "40%",
          height: isEditing ? "100vh" : "25vw",
          overflow: "auto",
        }}
      >
        <div style={{ textAlign: "left", paddingLeft: "7rem" }}>
          <div className="p-fluid">
            <label htmlFor="name">Nombre</label>
            <div className="p-field">
              <InputText
                id="name"
                value={packingForm.name}
                onChange={(e) => {
                  setPackingForm({ ...packingForm, name: e.target.value });
                  setNameError("");
                }}
              />
              {nameError && <small className="p-error">{nameError}</small>}
            </div>
            <div className="p-field">
              <label htmlFor="name">Kilogramos</label>
              <InputText
                id="kilograms"
                value={packingForm.kilograms}
                onChange={(e) => {
                  setPackingForm({
                    ...packingForm,
                    kilograms: e.target.value,
                  });
                  setKilogramsError("");
                }}
              />
              {kilogramsError && (
                <small className="p-error">{kilogramsError}</small>
              )}
            </div>
            <div className="p-field">
              <label htmlFor="specie_id">Especie</label>
              <Dropdown
                id="specie_id"
                optionLabel="name"
                optionValue="id"
                value={packingForm.specie_id}
                options={species}
                onChange={(e) =>
                  setPackingForm({
                    ...packingForm,
                    specie_id: e.value,
                  })
                }
                placeholder="Seleccione una especie"
              />
            </div>
            <div
              className="p-field"
              style={{ marginTop: "-0.5rem", marginLeft: "0.5rem" }}
            ></div>
          </div>
          {packingForm.logoPreview && (
            <img
              src={packingForm.logoPreview}
              alt="Vista previa del logo"
              style={{
                width: isEditing ? "15%" : "25%",
                objectFit: "cover",
                marginTop: "0.5rem",
              }}
            />
          )}
          <div className="p-mt-2">
            <Button
              label="Guardar"
              icon="pi pi-check"
              onClick={savePacking}
              disabled={disableSave}
            />
            <Button
              label="Cancelar"
              icon="pi pi-times"
              className="p-button-secondary p-ml-2"
              onClick={hideDialog}
            />
            {selectedPacking && (
              <Button
                label="Eliminar"
                icon="pi pi-trash"
                className="p-button-danger p-ml-2"
                onClick={showConfirmationModal}
              />
            )}
          </div>

          {isEditing && (
            <hr
              style={{
                border: "none",
                borderBottom: "1.5px solid #ccc",
                margin: "1.5rem 0 1rem",
                width: "90%",
              }}
            />
          )}
        </div>
      </Dialog>

      <ConfirmationModal
        visible={confirmationModalVisible}
        onHide={hideConfirmationModal}
        onConfirm={confirmDelete}
        onCancel={hideConfirmationModal}
      />

      <Toast ref={toast} />
    </div>
  );
};

export default Packings;

import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import ConfirmationModal from "./ConfirmationModal";
import { getSpecies } from "../api/species";
import {
  getProducts,
  createProduct,
  updateProduct,
  deleteProduct,
} from "../api/products";
import "./TablesStyle.css";

const Products = () => {
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [visible, setVisible] = useState(false);
  const [productForm, setProductForm] = useState({
    name: "",
    description: "",
    specie_id: null,
  });
  const [confirmationModalVisible, setConfirmationModalVisible] =
    useState(false);
  const [searchText, setSearchText] = useState("");
  const [nameError, setNameError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [disableSave, setDisableSave] = useState(false);
  const [species, setSpecies] = useState([]);

  useEffect(() => {
    loadProducts();
  }, []);

  const loadProducts = async () => {
    try {
      const response = await getProducts();
      setProducts(response.data);
      const speciesResponse = await getSpecies();
      setSpecies(speciesResponse.data);
    } catch (error) {
      console.error("Error al obtener productos:", error);
    }
  };

  const openDialog = (product) => {
    setSelectedProduct(product);
    setProductForm({
      name: product?.name || "",
      description: product?.description || "",
      specie_id: product?.specie_id || null,
    });
    setVisible(true);
    setIsEditing(!!product);
  };

  const hideDialog = () => {
    setVisible(false);
    setSelectedProduct(null);
    setProductForm({ name: "", description: "", specie_id: null });
    setNameError("");
    setIsEditing(false);
  };

  const saveProduct = async () => {
    try {
      if (!productForm.name) {
        setNameError("El nombre es obligatorio");
        return;
      }
      if (!productForm.description) {
        setDescriptionError("La descripción es obligatoria");
        return;
      }
      await updateOrCreateProduct();
    } catch (error) {
      console.error("Error al guardar el producto:", error);
      showToast("error", "Error", "Error al guardar el producto");
    }
  };

  const updateOrCreateProduct = async () => {
    setDisableSave(true);
    try {
      const payload = {
        name: productForm.name,
        description: productForm.description,
        specie_id: productForm.specie_id,
      };
      if (selectedProduct) await updateProduct(selectedProduct.id, payload);
      else await createProduct(payload);
      loadProducts();
      hideDialog();
      showToast("success", "Operación Exitosa", "Producto guardado con éxito");
      setDisableSave(false);
    } catch (error) {
      console.error("Error al guardar el producto:", error);
      showToast("error", "Error", "Error al guardar el producto");
      setDisableSave(false);
    }
  };

  const showConfirmationModal = () => {
    setConfirmationModalVisible(true);
  };

  const hideConfirmationModal = () => {
    setConfirmationModalVisible(false);
  };

  const confirmDelete = async () => {
    try {
      await deleteProduct(selectedProduct.id);
      loadProducts();
      hideDialog();
      showToast("success", "Operación Exitosa", "Producto eliminado con éxito");
    } catch (error) {
      console.error("Error al eliminar el producto:", error);
      showToast("error", "Error", "Error al eliminar el producto");
    }
  };

  const showToast = (severity, summary, detail) => {
    toast.current.show({ severity, summary, detail });
  };

  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  const filteredProducts = products.filter(
    (product) =>
      product.name.toLowerCase().includes(searchText.toLowerCase()) ||
      product.specie_name.toLowerCase().includes(searchText.toLowerCase())
  );

  const toast = React.useRef(null);

  return (
    <div className="Table">
      <div className="TableData">
        <h2>Productos</h2>
        <div className="TableHeader">
          <div>
            <Button
              label="Agregar Producto"
              icon="pi pi-plus"
              onClick={() => openDialog(null)}
              className="p-mb-2"
            />
          </div>
          <div>
            <span className="p-input-icon-left">
              <i className="pi pi-search" />
              <InputText
                placeholder="Buscar por nombre"
                value={searchText}
                onChange={handleSearch}
              />
            </span>
          </div>
        </div>
        <DataTable
          value={filteredProducts}
          selectionMode="single"
          onSelectionChange={(e) => openDialog(e.value)}
        >
          <Column field="name" header="Nombre" sortable></Column>
          <Column field="description" header="Descripción"></Column>
          <Column field="specie_name" header="Especie"></Column>
        </DataTable>
      </div>

      <Dialog
        visible={visible}
        onHide={hideDialog}
        header={isEditing ? "Editar Producto" : "Nuevo Producto"}
        style={{
          width: isEditing ? "90vw" : "40%",
          height: isEditing ? "100vh" : "25vw",
          overflow: "auto",
        }}
      >
        <div style={{ textAlign: "left", paddingLeft: "7rem" }}>
          <div className="p-fluid">
            <label htmlFor="name">Nombre</label>
            <div className="p-field">
              <InputText
                id="name"
                value={productForm.name}
                onChange={(e) => {
                  setProductForm({ ...productForm, name: e.target.value });
                  setNameError("");
                }}
              />
              {nameError && <small className="p-error">{nameError}</small>}
            </div>
            <div className="p-field">
              <label htmlFor="name">Descripción</label>
              <InputText
                id="description"
                value={productForm.description}
                onChange={(e) => {
                  setProductForm({
                    ...productForm,
                    description: e.target.value,
                  });
                  setDescriptionError("");
                }}
              />
              {descriptionError && (
                <small className="p-error">{descriptionError}</small>
              )}
            </div>
            <div className="p-field">
              <label htmlFor="specie_id">Especie</label>
              <Dropdown
                id="specie_id"
                optionLabel="name"
                optionValue="id"
                value={productForm.specie_id}
                options={species}
                onChange={(e) =>
                  setProductForm({
                    ...productForm,
                    specie_id: e.value,
                  })
                }
                placeholder="Seleccione una especie"
              />
            </div>
            <div
              className="p-field"
              style={{ marginTop: "-0.5rem", marginLeft: "0.5rem" }}
            ></div>
          </div>
          {productForm.logoPreview && (
            <img
              src={productForm.logoPreview}
              alt="Vista previa del logo"
              style={{
                width: isEditing ? "15%" : "25%",
                objectFit: "cover",
                marginTop: "0.5rem",
              }}
            />
          )}
          <div className="p-mt-2">
            <Button
              label="Guardar"
              icon="pi pi-check"
              onClick={saveProduct}
              disabled={disableSave}
            />
            <Button
              label="Cancelar"
              icon="pi pi-times"
              className="p-button-secondary p-ml-2"
              onClick={hideDialog}
            />
            {selectedProduct && (
              <Button
                label="Eliminar"
                icon="pi pi-trash"
                className="p-button-danger p-ml-2"
                onClick={showConfirmationModal}
              />
            )}
          </div>

          {isEditing && (
            <hr
              style={{
                border: "none",
                borderBottom: "1.5px solid #ccc",
                margin: "1.5rem 0 1rem",
                width: "90%",
              }}
            />
          )}
        </div>
      </Dialog>

      <ConfirmationModal
        visible={confirmationModalVisible}
        onHide={hideConfirmationModal}
        onConfirm={confirmDelete}
        onCancel={hideConfirmationModal}
      />

      <Toast ref={toast} />
    </div>
  );
};

export default Products;

import React, { useState } from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { updateReceptionMeasurement } from "../api/receptionMeasurements";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { updateReceptionComment } from "../api/visits";

const ReceptionMeasuresPanel = ({
  measures,
  onChange,
  visitId,
  showToastMessage,
  receptionComment,
  parentInputChange,
}) => {
  const [selectedMeasure, setSelectedMeasure] = useState(null);
  const [originalMeasure, setOriginalMeasure] = useState(null);
  const [displayModal, setDisplayModal] = useState(false);
  const [sortOrder, setSortOrder] = useState(1);
  const [comment, setComment] = useState(receptionComment);

  const handleInputChange = (field, value) => {
    setSelectedMeasure((prevMeasure) => ({
      ...prevMeasure,
      [field]: value,
    }));
  };

  const showToast = (severity, summary, detail) => {
    toast.current.show({ severity, summary, detail });
  };

  const updateComment = async () => {
    try {
      await updateReceptionComment(
        {
          reception_measurements_comment: comment,
        },
        visitId
      );
      parentInputChange("reception_measurements_comment", comment);
      showToastMessage("success", "Listo!", "Comentario actualizado");
    } catch (error) {
      showToastMessage(
        "error",
        "Error!",
        "No se pudo actualizar el comentario"
      );
      console.error("Error updating reception comment:", error);
    }
  };

  const toast = React.useRef(null);

  const handleUpdateClick = () => {
    if (selectedMeasure && selectedMeasure.id) {
      updateReceptionMeasurement(selectedMeasure, selectedMeasure.id)
        .then(() => {
          const updatedMeasurements = [
            ...measures.filter((m) => m.id !== selectedMeasure.id),
            selectedMeasure,
          ];
          const sortedMeasurements = updatedMeasurements.sort(
            (a, b) => sortOrder * (a.id - b.id)
          );
          onChange(sortedMeasurements);
          setDisplayModal(false);
          showToast("success", "Éxito", "Medición actualizada correctamente");
        })
        .catch((error) => {
          console.error("Error al actualizar Reception Measurement:", error);
          showToast("error", "Error", "Error al actualizar la medición");
        });
    }
  };
  const openModal = (measure) => {
    setSelectedMeasure(measure);
    setOriginalMeasure({ ...measure });
    setDisplayModal(true);
  };

  const closeModal = () => {
    setSelectedMeasure(null);
    if (originalMeasure) {
      setSelectedMeasure({ ...originalMeasure });
      setOriginalMeasure(null);
    }
    setDisplayModal(false);
  };

  return (
    <div>
      <Accordion>
        <AccordionTab header="Recepción">
          <DataTable
            value={measures}
            selectionMode="single"
            selection={selectedMeasure}
            onSelectionChange={(e) => openModal(e.value)}
            onSort={(e) => setSortOrder(e.sortOrder)}
          >
            <Column
              field="room_temperature"
              header="Temperatura de cámara"
            ></Column>
            <Column
              field="pulp_temperature"
              header="Temperatura de Pulpa"
            ></Column>
            <Column
              field="relative_humidity"
              header="Humedad relativa"
            ></Column>
          </DataTable>
          <InputTextarea
            style={{ marginTop: "10px" }}
            autoResize={true}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            placeholder="Comentario de medidas de recepción"
          />
          <Button
            label="Guardar Comentario"
            icon="pi pi-check"
            onClick={updateComment}
          />
          <Dialog
            visible={displayModal}
            header={"Editar Medida de Recepción"}
            style={{ width: "50vw" }}
            onHide={closeModal}
          >
            <div>
              <div className="field-title">Temperatura de cámara</div>
              <InputText
                value={selectedMeasure?.room_temperature || ""}
                onChange={(e) =>
                  handleInputChange("room_temperature", e.target.value)
                }
                placeholder="Temperatura de cámara"
              />

              <div className="field-title">Temperatura de Pulpa</div>
              <InputText
                value={selectedMeasure?.pulp_temperature || ""}
                onChange={(e) =>
                  handleInputChange("pulp_temperature", e.target.value)
                }
                placeholder="Temperatura de Pulpa"
              />

              <div className="field-title">Humedad relativa</div>
              <InputText
                value={selectedMeasure?.relative_humidity || ""}
                onChange={(e) =>
                  handleInputChange("relative_humidity", e.target.value)
                }
                placeholder="Humedad relativa"
              />

              <div className="p-mt-2">
                <Button
                  label="Guardar"
                  icon="pi pi-check"
                  onClick={handleUpdateClick}
                />
              </div>
            </div>
          </Dialog>
        </AccordionTab>
      </Accordion>
      <Toast ref={toast} />
    </div>
  );
};

export default ReceptionMeasuresPanel;

import { instance } from "./axios";

export const getSpecies = async () => {
  try {
    return await instance.get("/species");
  } catch (error) {
    throw error;
  }
};

export const getSpecieById = async (id) => {
  try {
    return await instance.get(`/species/${id}`);
  } catch (error) {
    throw error;
  }
};

export const createSpecies = async (
  name,
  min_o2,
  max_o2,
  min_co2,
  max_co2,
  min_room_temperature,
  max_room_temperature,
  min_pulp_temperature,
  max_pulp_temperature,
  min_wind_speed,
  max_wind_speed,
  min_relative_humidity,
  max_relative_humidity
) => {
  try {
    return await instance.post("/species", {
      name,
      min_o2,
      max_o2,
      min_co2,
      max_co2,
      min_room_temperature,
      max_room_temperature,
      min_pulp_temperature,
      max_pulp_temperature,
      min_wind_speed,
      max_wind_speed,
      min_relative_humidity,
      max_relative_humidity,
    });
  } catch (error) {
    throw error;
  }
};

export const updateSpecies = async (
  id,
  name,
  min_o2,
  max_o2,
  min_co2,
  max_co2,
  min_room_temperature,
  max_room_temperature,
  min_pulp_temperature,
  max_pulp_temperature,
  min_wind_speed,
  max_wind_speed,
  min_relative_humidity,
  max_relative_humidity
) => {
  try {
    return await instance.put(`/species/${id}`, {
      name,
      min_o2,
      max_o2,
      min_co2,
      max_co2,
      min_room_temperature,
      max_room_temperature,
      min_pulp_temperature,
      max_pulp_temperature,
      min_wind_speed,
      max_wind_speed,
      min_relative_humidity,
      max_relative_humidity,
    });
  } catch (error) {
    throw error;
  }
};

export const deleteSpecies = async (id) => {
  try {
    return await instance.delete(`/species/${id}`);
  } catch (error) {
    throw error;
  }
};

import { instance } from "./axios";

export const getPackings = () => {
  try {
    return instance.get("/packing");
  } catch (error) {
    throw error;
  }
};

export const createPacking = (payload) => {
  try {
    return instance.post("/packing", payload);
  } catch (error) {
    throw error;
  }
};

export const updatePacking = (id, payload) => {
  try {
    return instance.put(`/packing/${id}`, payload);
  } catch (error) {
    throw error;
  }
};

export const deletePacking = (id) => {
  try {
    return instance.delete(`/packing/${id}`);
  } catch (error) {
    throw error;
  }
};

import { useState } from "react";
import { Dropdown } from "primereact/dropdown";

const EditableDropdown = (props) => {
  const [isEditing, setIsEditing] = useState(false);
  const [id, setId] = useState(props.id);

  const startEditing = () => {
    setIsEditing(true);
  };
  const finishEditing = () => {
    setIsEditing(false);
    props.onChange(id);
  };
  const handleChange = (e) => {
    setId(e.value);
  };
  return (
    <div className="p-inputgroup" style={{ marginBottom: 10 }}>
      <Dropdown
        style={{ height: "35px", padding: "0" }}
        optionLabel="name"
        optionValue="id"
        value={id}
        options={props.list}
        onChange={handleChange}
        disabled={!isEditing}
      />
      <span className="p-inputgroup-addon" style={{ height: 35 }}>
        {isEditing ? (
          <i
            className="pi pi-check"
            onClick={finishEditing}
            style={{ cursor: "pointer" }}
          />
        ) : (
          <i
            className="pi pi-pencil"
            onClick={startEditing}
            style={{ cursor: "pointer" }}
          />
        )}
      </span>
    </div>
  );
};

export default EditableDropdown;

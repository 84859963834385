import React, { useState } from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { updatePackingMeasurement } from "../api/packingMeasurements";
import { getVisitImageUrl } from "../utils/getImageUrl";
import { InputTextarea } from "primereact/inputtextarea";

const PackingMeasurementPanel = ({ packingMeasurement, onChange }) => {
  const [selectedMeasurement, setSelectedMeasurement] = useState(null);
  const [originalMeasurement, setOriginalMeasurement] = useState(null);
  const [displayModal, setDisplayModal] = useState(false);
  const [sortOrder, setSortOrder] = useState(1);

  const handleInputChange = (field, value) => {
    setSelectedMeasurement((prevMeasurement) => ({
      ...prevMeasurement,
      [field]: value,
    }));
  };

  const showToast = (severity, summary, detail) => {
    toast.current.show({ severity, summary, detail });
  };

  const toast = React.useRef(null);

  const handleUpdateClick = () => {
    if (selectedMeasurement && selectedMeasurement.id) {
      updatePackingMeasurement(selectedMeasurement, selectedMeasurement.id)
        .then(() => {
          const updatedMeasurements = [
            ...packingMeasurement.filter(
              (m) => m.id !== selectedMeasurement.id
            ),
            selectedMeasurement,
          ];
          const sortedMeasurements = updatedMeasurements.sort(
            (a, b) => sortOrder * (a.id - b.id)
          );
          onChange(sortedMeasurements);
          setDisplayModal(false);
          showToast("success", "Éxito", "Medición actualizada correctamente");
        })
        .catch((error) => {
          console.error("Error al actualizar Packing Measurement:", error);
          showToast("error", "Error", "Error al actualizar la medición");
        });
    }
  };

  const openModal = (measurement) => {
    setSelectedMeasurement(measurement);
    setOriginalMeasurement({ ...measurement });
    setDisplayModal(true);
  };

  const closeModal = () => {
    setSelectedMeasurement(null);
    if (originalMeasurement) {
      setSelectedMeasurement({ ...originalMeasurement });
      setOriginalMeasurement(null);
    }
    setDisplayModal(false);
  };

  const handleDeleteImage = (index) => {
    const updatedMeasurement = { ...selectedMeasurement };
    const updatedDocuments = JSON.parse(updatedMeasurement.documents || "[]");
    updatedDocuments.splice(index, 1);
    updatedMeasurement.documents = JSON.stringify(updatedDocuments);
    setSelectedMeasurement(updatedMeasurement);
  };

  return (
    <div>
      <Accordion>
        <AccordionTab header="Packing">
          <DataTable
            value={packingMeasurement}
            selectionMode="single"
            selection={selectedMeasurement}
            onSelectionChange={(e) => openModal(e.value)}
            onSort={(e) => setSortOrder(e.sortOrder)}
          >
            <Column field="line_number" header="Número de Línea"></Column>
            <Column
              field="sealing_pulp_temperature"
              header="Temperatura Pulpa Sellado"
            ></Column>
            <Column
              field="room_temperature"
              header="Temperatura Cámara"
            ></Column>
            <Column
              field="fungicide_temperature"
              header="Temperatura Fungicida"
            ></Column>
            <Column field="comment" header="Comentario" />
          </DataTable>

          <Dialog
            visible={displayModal}
            header={"Editar Medición del Embalaje"}
            style={{ width: "50vw" }}
            onHide={closeModal}
          >
            <div>
              <div className="field-title">Número de Línea</div>
              <InputText
                value={selectedMeasurement?.line_number || ""}
                onChange={(e) =>
                  handleInputChange("line_number", e.target.value)
                }
                placeholder="Número de Línea"
              />
              <div className="field-title">Temperatura de cámara</div>
              <InputText
                value={selectedMeasurement?.room_temperature || ""}
                onChange={(e) =>
                  handleInputChange("room_temperature", e.target.value)
                }
                placeholder="Temperatura de cámara"
              />
              <div className="field-title">Temperatura de Pulpa al Vacío</div>
              <InputText
                value={selectedMeasurement?.vacuum_pulp_temperature || ""}
                onChange={(e) =>
                  handleInputChange("vacuum_pulp_temperature", e.target.value)
                }
                placeholder="Temperatura de Pulpa al Vacío"
              />
              <div className="field-title">Temperatura de Agua al Vacío</div>
              <InputText
                value={selectedMeasurement?.vacuum_water_temperature || ""}
                onChange={(e) =>
                  handleInputChange("vacuum_water_temperature", e.target.value)
                }
                placeholder="Temperatura de Agua al Vacío"
              />
              <div className="field-title">Temperatura de Corte del Agua</div>
              <InputText
                value={selectedMeasurement?.water_cutting_temperature || ""}
                onChange={(e) =>
                  handleInputChange("water_cutting_temperature", e.target.value)
                }
                placeholder="Temperatura de Corte del Agua"
              />
              <div className="field-title">Temperatura de Fungicida</div>
              <InputText
                value={selectedMeasurement?.fungicide_temperature || ""}
                onChange={(e) =>
                  handleInputChange("fungicide_temperature", e.target.value)
                }
                placeholder="Temperatura de Fungicida"
              />
              <div className="field-title">Temperatura de Pulpa de Sellado</div>
              <InputText
                value={selectedMeasurement?.sealing_pulp_temperature || ""}
                onChange={(e) =>
                  handleInputChange("sealing_pulp_temperature", e.target.value)
                }
                placeholder="Temperatura de Pulpa de Sellado"
              />
              <div className="field-title">Comentario</div>
              <InputTextarea
                autoResize={true}
                value={selectedMeasurement?.comment || ""}
                onChange={(e) => handleInputChange("comment", e.target.value)}
                placeholder="Comentario"
              />
              {selectedMeasurement &&
              selectedMeasurement.documents &&
              JSON.parse(selectedMeasurement.documents).length > 0 ? (
                <div>
                  <div className="field-title">Imágenes</div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    {Array.isArray(
                      JSON.parse(selectedMeasurement.documents)
                    ) ? (
                      JSON.parse(selectedMeasurement.documents).map(
                        (document, index) => {
                          try {
                            const documentData = document;
                            const imageUrl = getVisitImageUrl(
                              documentData.name
                            );

                            return (
                              <div
                                key={index}
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  marginRight: "10px",
                                }}
                              >
                                <img
                                  src={imageUrl}
                                  alt={`Documento ${documentData.name}`}
                                  style={{ width: "100px", height: "100px" }}
                                />
                                <i
                                  className="pi pi-trash"
                                  style={{
                                    cursor: "pointer",
                                    color: "red",
                                    marginTop: "2px",
                                  }}
                                  onClick={() => handleDeleteImage(index)}
                                />
                              </div>
                            );
                          } catch (error) {
                            console.error(
                              "Error al parsear el documento:",
                              error
                            );
                            return (
                              <div key={index}>
                                <span style={{ color: "red" }}>
                                  Error al procesar la imagen.
                                </span>
                              </div>
                            );
                          }
                        }
                      )
                    ) : (
                      <div>No hay imágenes válidas.</div>
                    )}
                  </div>
                </div>
              ) : (
                <div>No hay imágenes asociadas.</div>
              )}
              <div className="p-mt-2">
                <Button
                  label="Guardar"
                  icon="pi pi-check"
                  onClick={handleUpdateClick}
                />
              </div>
            </div>
          </Dialog>
        </AccordionTab>
      </Accordion>
      <Toast ref={toast} />
    </div>
  );
};

export default PackingMeasurementPanel;

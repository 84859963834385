import { instance } from "./axios";

export const getAllMetrologies = () => {
  try {
    return instance.get("/metrology/");
  } catch (error) {
    throw error;
  }
};

export const createOneMetrology = (visit_id, instrument_id) => {
  try {
    return instance.post(`/metrology/only/`, visit_id, instrument_id);
  } catch (error) {
    throw error;
  }
}

export const updateMetrology = (payload, id) => {
  try {
    return instance.put(`/visit/metrology/${id}`, payload);
  } catch (error) {
    throw error;
  }
};

export const deleteMetrology = (id) => {
  try {
    return instance.delete(`/metrology/${id}`);
  } catch (error) {
    throw error;
  }
};
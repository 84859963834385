import { useState, useEffect } from "react";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { getAllBrands } from "../../api/brands";
import { getSpecies } from "../../api/species";
import { getCustomers } from "../../api/customers";
import { getPlaceById } from "../../api/places";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { updateCommercialVisit } from "../../api/visits";
import "./GeneralInfo.css";

const GeneralInfo = (props) => {
  const [selectedUser, setSelectedUser] = useState(props.visit.user_id);
  const [selectedBrand, setSelectedBrand] = useState(props.visit.brand_id);
  const [brands, setBrands] = useState([]);
  const [species, setSpecies] = useState([]);
  const [selectedSpecie, setSelectedSpecie] = useState(props.visit.specie_id);
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(
    props.visit.customer_id
  );
  const [places, setPlaces] = useState([]);
  const [selectedPlace, setSelectedPlace] = useState(props.visit.place_id);
  const [contact, setContact] = useState(props.visit.person_in_charge);
  const [visitDate, setVisitDate] = useState(
    new Date(props.visit.date_of_visit)
  );
  const [startTime, setStartTime] = useState(props.visit.start_time);
  const [endTime, setEndTime] = useState(props.visit.end_time);

  const handleSave = async () => {
    const payload = {
      user_id: selectedUser,
      brand_id: selectedBrand,
      specie_id: selectedSpecie,
      customer_id: selectedCustomer,
      place_id: selectedPlace,
      person_in_charge: contact,
      date_of_visit: visitDate,
      start_time: startTime,
      end_time: endTime,
    };
    try {
      await updateCommercialVisit(payload, props.visit.id);
      props.showToast(
        "success",
        "Operación Exitosa",
        "Visita actualizada con éxito"
      );
      props.loadVisits();
    } catch (error) {
      console.error("Error updating visit:", error);
      props.showToast("error", "Error", "Error al actualizar la visita");
    }
  };

  const loadPlaces = async () => {
    try {
      const places = await getPlaceById(selectedCustomer);
      setPlaces(places.data);
    } catch (error) {
      console.error("Error fetching places:", error);
    }
  };

  const loadData = async () => {
    try {
      const species = await getSpecies();
      setSpecies(species.data);
      const brands = await getAllBrands();
      setBrands(brands.data);
      const customers = await getCustomers();
      setCustomers(customers.data);
    } catch (error) {
      console.error("Error fetching species:", error);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    loadPlaces();
    // eslint-disable-next-line
  }, [selectedCustomer]);

  return (
    <div>
      <div className="row">
        <div>Coordinador:</div>
        <Dropdown
          optionLabel="name"
          optionValue="id"
          value={selectedUser}
          options={props.users}
          onChange={(e) => setSelectedUser(e.value)}
          placeholder="Coordinador"
        />
        <div>Marca:</div>
        <Dropdown
          optionLabel="name"
          optionValue="id"
          value={selectedBrand}
          options={brands}
          onChange={(e) => setSelectedBrand(e.value)}
          placeholder="Marca"
        />
        <div>Especie:</div>
        <Dropdown
          optionLabel="name"
          optionValue="id"
          value={selectedSpecie}
          options={species}
          onChange={(e) => setSelectedSpecie(e.value)}
          placeholder="Especie"
        />
      </div>
      <div className="row">
        <div>Cliente:</div>
        <Dropdown
          optionLabel="name"
          optionValue="id"
          value={selectedCustomer}
          options={customers}
          onChange={(e) => setSelectedCustomer(e.value)}
          placeholder="Cliente"
        />
        <div>Lugar:</div>
        <Dropdown
          optionLabel="name"
          optionValue="id"
          value={selectedPlace}
          options={places}
          onChange={(e) => setSelectedPlace(e.value)}
          placeholder="Lugar"
        />
        <div>Contacto:</div>
        <InputText
          value={contact}
          onChange={(e) => setContact(e.target.value)}
          placeholder="Contacto"
          style={{ width: "140px" }}
        />
      </div>
      <div className="row">
        <div>Fecha Visita:</div>
        <Calendar
          value={visitDate ? new Date(visitDate) : null}
          onChange={(e) => setVisitDate(e.value)}
          placeholder="Fecha de Visita"
          style={{ width: "120px" }}
          dateFormat="dd/mm/yy"
        />

        <div>Hora Inicio:</div>
        <Calendar
          value={startTime ? new Date(startTime) : null}
          onChange={(e) => setStartTime(e.value)}
          placeholder="Fecha de Visita"
          timeOnly
          style={{ width: "90px" }}
          dateFormat="dd/mm/yy"
        />

        <div>Hora Termino:</div>
        <Calendar
          value={endTime ? new Date(endTime) : null}
          onChange={(e) => setEndTime(e.value)}
          placeholder="Fecha de Visita"
          timeOnly
          style={{ width: "90px" }}
          dateFormat="dd/mm/yy"
        />
      </div>
      <Button label="Guardar" icon="pi pi-check" onClick={handleSave} />
    </div>
  );
};

export default GeneralInfo;

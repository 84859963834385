const DATA_URL = process.env.REACT_APP_S3_DATA_URL;

export const getImageUrl = (imageName) => {
  return `${DATA_URL}/${imageName}`;
};

const VISIT_URL = process.env.REACT_APP_S3_VISITS_URL;

export const getVisitImageUrl = (imageName) => {
  return `${VISIT_URL}/${imageName}`;
};
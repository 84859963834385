import xlsx from "json-as-xlsx";

export const generateXls = (jsonData) => {
  let data = [
    {
      sheet: "Datos Generales",
      columns: [
        { label: "N° Reporte", value: "id" },
        { label: "Tema", value: "visit_type_name" },
        { label: "Fecha de Reporte", value: "report_date" },
        { label: "Marca", value: "brand_name" },
        { label: "Cliente", value: "customer_name" },
        { label: "Lugar", value: "place_name" },
        { label: "Fecha de Visita", value: "date_of_visit" },
        { label: "Días entre Visita y Reporte", value: "days_between" },
        { label: "Hora Inicio", value: "start_time" },
        { label: "Hora Término", value: "end_time" },
        { label: "Duración Visita", value: "duration" },
        { label: "Persona a Cargo", value: "person_in_charge" },
        { label: "Especie", value: "specie_name" },
        { label: "Inspector", value: "user_name" },
        { label: "Conclusiones", value: "conclusions" },
        { label: "Recomendaciones", value: "recommendations" },
        { label: "Otros Comentarios", value: "other_comments" },
      ],
      content: jsonData.general,
    },
    {
      sheet: "Instrumentos",
      columns: [
        { label: "N° Reporte", value: "visit_id" },
        { label: "Instrumento", value: "instrument_type" },
        { label: "Código", value: "instrument_name" },
        { label: "Fecha de Calibración", value: "verified_at" },
      ],
      content: jsonData.metrology,
    },
    {
      sheet: "Mediciones de Gases",
      columns: [
        { label: "N° Reporte", value: "visit_id" },
        { label: "Caja", value: "row_num" },
        { label: "Número de Cámara", value: "room_code" },
        { label: "Temperatura Ambiente", value: "room_temperature" },
        { label: "Velocidad Viento mínima (m/s)", value: "min_wind_speed" },
        { label: "Velocidad Viento Máxima (m/s)", value: "max_wind_speed" },
        {
          label: "Velocidad del viento promedio (m/s)",
          value: "avg_wind_speed",
        },
        { label: "Número de Pallet", value: "pallet" },
        { label: "Variedad", value: "variety_name" },
        { label: "Formato de Envase (kgs)", value: "kilograms" },
        { label: "Código Producto", value: "product_code" },
        { label: "Fecha Embalaje", value: "packaging_date" },
        { label: "Fecha de Sellado", value: "sealling_date" },
        { label: "Días desde Sellado", value: "sealling_days" },
        { label: "% CO2", value: "co2" },
        { label: "% O2", value: "o2" },
        { label: "Temperatura de Pulpa (ºC)", value: "pulp_temperature" },
        { label: "Ubicación en el pallet", value: "box_ubication" },
      ],
      content: jsonData.measurements,
    },
    {
      sheet: "Preparación de Material",
      columns: [
        { label: "N° Reporte", value: "visit_id" },
        { label: "Comentario", value: "packaging_materials_comment" },
      ],
      content: jsonData.packaging_materials,
    },
    {
      sheet: "Recepción",
      columns: [
        { label: "N° Reporte", value: "visit_id" },
        { label: "Temperatura de ambiente (°C)", value: "room_temperature" },
        { label: "Temperatura de pulpa (°C)", value: "pulp_temperature" },
        { label: "Humedad Relativa (%)", value: "relative_humidity" },
        { label: "Comentario", value: "reception_measurements_comment" },
      ],
      content: jsonData.reception_measurements,
    },
    {
      sheet: "Materia Prima",
      columns: [
        { label: "N° Reporte", value: "visit_id" },
        { label: "Número de Cámara", value: "room_code" },
        { label: "Temperatura de ambiente (°C)", value: "room_temperature" },
        { label: "Velocidad Viento mínima (m/s)", value: "min_wind_speed" },
        { label: "Velocidad Viento Máxima (m/s)", value: "max_wind_speed" },
        {
          label: "Velocidad del viento promedio (m/s)",
          value: "avg_wind_speed",
        },
        { label: "Humedad Relativa (%)", value: "relative_humidity" },
        { label: "Variedad", value: "variety_name" },
        { label: "Fecha de Cosecha", value: "harvest_date" },
        { label: "Antigüedad (Días)", value: "age_days" },
        { label: "Temperatura de pulpa (°C)", value: "pulp_temperature" },
        { label: "Comentario", value: "comment" },
      ],
      content: jsonData.raw_material_chamber,
    },
    {
      sheet: "Packing",
      columns: [
        { label: "N° Reporte", value: "visit_id" },
        { label: "Número de Línea", value: "line_number" },
        { label: "Temperatura de ambiente (°C)", value: "room_temperature" },
        {
          label: "Temperatura de pulpa al vaciado (°C)",
          value: "vacuum_pulp_temperature",
        },
        {
          label: "Temperatura del agua en la zona de vaciado (°C)",
          value: "vacuum_water_temperature",
        },
        {
          label: "Temperatura del agua zona corta palitos (°C)",
          value: "water_cutting_temperature",
        },
        {
          label: "Temperatura fungicida (°C)",
          value: "fungicide_temperature",
        },
        {
          label: "Temperatura de pulpa previo al sellado (°C)",
          value: "sealing_pulp_temperature",
        },
        {
          label: "Comentarios",
          value: "comment",
        },
      ],
      content: jsonData.packing_measurements,
    },
    {
      sheet: "Sellado",
      columns: [
        { label: "N° Reporte", value: "visit_id" },
        { label: "Número de Sala", value: "room_code" },
        { label: "Temperatura ambiente (°C)", value: "room_temperature" },
        { label: "Humedad Relativa (%)", value: "relative_humidity" },
        {
          label: "Temperatura de pulpa previo al sellado (°C)",
          value: "pulp_temperature",
        },
        {
          label: "Temperatura mínima máquina selladora (°C)",
          value: "min_temperature",
        },
        {
          label: "Temperatura máxima máquina selladora (°C)",
          value: "max_temperature",
        },
        {
          label: "Temperatura promedio máquina selladora (°C)",
          value: "avg_temperature",
        },
        {
          label: "Velocidad mínima maquina selladora (m/s)",
          value: "min_speed",
        },
        {
          label: "Velocidad máxima maquina selladora (m/s)",
          value: "max_speed",
        },
        {
          label: "Velocidad promedio máquina selladora (m/s)",
          value: "avg_speed",
        },
        { label: "Calidad del sellado", value: "sealing_quality" },
        { label: "Comentario", value: "comment" },
      ],
      content: jsonData.sealing_room_measurements,
    },
    {
      sheet: "Palletizaje",
      columns: [
        { label: "N° Reporte", value: "visit_id" },
        { label: "Número de Sala", value: "room_code" },
        { label: "Temperatura de ambiente (°C)", value: "room_temperature" },
        {
          label: "Temperatura de pulpa (°C)",
          value: "pulp_temperature",
        },
        { label: "Humedad relativa (%)", value: "relative_humidity" },
        { label: "Comentario", value: "comment" },
      ],
      content: jsonData.palletized_measurements,
    },
    {
      sheet: "Producto Terminado",
      columns: [
        { label: "N° Reporte", value: "visit_id" },
        { label: "Número de Cámara", value: "room_code" },
        {
          label: "Temperatura de ambiente mínima (°C)",
          value: "min_room_temperature",
        },
        {
          label: "Temperatura de ambiente máxima (°C)",
          value: "max_room_temperature",
        },
        {
          label: "Temperatura de ambiente promedio (°C)",
          value: "avg_room_temperature",
        },
        {
          label: "Temperatura de pulpa mínima (°C)",
          value: "min_pulp_temperature",
        },
        {
          label: "Temperatura de pulpa máxima (°C)",
          value: "max_pulp_temperature",
        },
        {
          label: "Temperatura de pulpa promedio (°C)",
          value: "avg_pulp_temperature",
        },
        { label: "Velocidad del viento mínima (m/s)", value: "min_wind_speed" },
        { label: "Velocidad del viento máxima (m/s)", value: "max_wind_speed" },
        {
          label: "Velocidad del viento promedio (m/s)",
          value: "avg_wind_speed",
        },
        { label: "Comentario", value: "comment" },
      ],
      content: jsonData.finished_product_measurements,
    },
    {
      sheet: "Anden de Carga",
      columns: [
        { label: "N° Reporte", value: "visit_id" },
        { label: "Número de Cámara", value: "room_code" },
        {
          label: "Temperatura de ambiente mínima (°C)",
          value: "min_room_temperature",
        },
        {
          label: "Temperatura de ambiente máxima (°C)",
          value: "max_room_temperature",
        },
        {
          label: "Temperatura de ambiente promedio (°C)",
          value: "avg_room_temperature",
        },
        {
          label: "Temperatura de pulpa mínima (°C)",
          value: "min_pulp_temperature",
        },
        {
          label: "Temperatura de pulpa máxima (°C)",
          value: "max_pulp_temperature",
        },
        {
          label: "Temperatura de pulpa promedio (°C)",
          value: "avg_pulp_temperature",
        },
        { label: "Velocidad del viento mínima (m/s)", value: "min_wind_speed" },
        { label: "Velocidad del viento máxima (m/s)", value: "max_wind_speed" },
        {
          label: "Velocidad del viento promedio (m/s)",
          value: "avg_wind_speed",
        },
        { label: "Comentario", value: "comment" },
      ],
      content: jsonData.loading_dock_measurements,
    },
  ];

  let settings = {
    fileName: "BD Visitas Técnicas", // Name of the resulting spreadsheet
    extraLength: 3, // A bigger number means that columns will be wider
    writeMode: "writeFile", // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
    writeOptions: {}, // Style options from https://docs.sheetjs.com/docs/api/write-options
    RTL: false, // Display the columns from right-to-left (the default value is false)
  };

  xlsx(data, settings);
};

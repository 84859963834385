import React from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

const ConfirmationModal = ({ visible, onHide, onConfirm, onCancel, message }) => {
  return (
    <Dialog visible={visible} onHide={onHide} header="Confirmación" modal>
      <div>
        <p>¿Estás seguro de que deseas eliminar {message}?</p>
      </div>
      <div className="p-mt-2">
        <Button
          label="Eliminar"
          icon="pi pi-trash"
          onClick={() => {
            onHide();
            onConfirm();
          }}
          className="p-button-danger p-ml-2"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          onClick={onCancel}
          className="p-button-secondary p-ml-2"
        />
      </div>
    </Dialog>
  );
};

export default ConfirmationModal;
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";

const Filter = (props) => {
  return (
    <div
      style={{
        padding: 20,
        border: "1px solid gray",
        marginBottom: 10,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div className="p-inputgroup" style={{ marginRight: 10 }}>
          <span style={{ marginRight: 10, marginTop: 10 }}>Desde:</span>
          <span className="p-inputgroup-addon">
            <i className="pi pi-calendar"></i>
          </span>
          <Calendar
            id="startDate"
            placeholder="dd/mm/yyyy"
            value={props.startDate}
            onChange={(e) => props.onStartDateChange(e.value)}
            dateFormat="dd/mm/yy"
          />
        </div>
        <div className="p-inputgroup" style={{ marginRight: 10 }}>
          <span style={{ marginRight: 10, marginTop: 10 }}>Hasta:</span>
          <span className="p-inputgroup-addon">
            <i className="pi pi-calendar"></i>
          </span>
          <Calendar
            id="endDate"
            placeholder="Hasta"
            value={props.endDate}
            onChange={(e) => props.onEndDateChange(e.value)}
            dateFormat="dd/mm/yy"
          />
        </div>
        {/* Adding margin to the left of the button for spacing */}
        <div className="p-inputgroup" style={{ marginLeft: "auto" }}>
          <Button label="Buscar" icon="pi pi-search" onClick={props.onSearch} />
        </div>
      </div>
    </div>
  );
};

export default Filter;

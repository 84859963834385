import { instance } from "./axios";

export const getPlaces = async () => {
  try {
    return await instance.get("/places");
  } catch (error) {
    throw error;
  }
};

export const getPlaceById = async (id) => {
  try {
    return await instance.get(`/places/${id}`);
  } catch (error) {
    throw error;
  }
};

export const createPlace = async (
  name,
  address,
  region,
  commune,
  place_type_id,
  customer_id,
  created_by
) => {
  try {
    return await instance.post("/places", {
      name,
      address,
      region,
      commune,
      place_type_id,
      customer_id,
      created_by,
    });
  } catch (error) {
    throw error;
  }
};

export const updatePlace = async (
  id,
  name,
  address,
  region,
  commune,
  place_type_id
) => {
  try {
    return await instance.put(`/places/${id}`, {
      name,
      address,
      region,
      commune,
      place_type_id,
    });
  } catch (error) {
    throw error;
  }
};

export const deletePlace = async (id) => {
  try {
    return await instance.delete(`/places/${id}`);
  } catch (error) {
    throw error;
  }
};

export const getPlaceTypes = async () => {
  try {
    return await instance.get("/place-types");
  } catch (error) {
    throw error;
  }
};

export const createPlaceType = async (name) => {
  try {
    return await instance.post("/place-types", { name });
  } catch (error) {
    throw error;
  }
};

export const updatePlaceType = async (id, name) => {
  try {
    return await instance.put(`/place-types/${id}`, { name });
  } catch (error) {
    throw error;
  }
};

export const deletePlaceType = async (id) => {
  try {
    return await instance.delete(`/place-types/${id}`);
  } catch (error) {
    throw error;
  }
};

import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import ConfirmationModal from "./ConfirmationModal";
import {
  getContactsById,
  createContact,
  deleteContact,
  updateContact,
} from "../api/contacts";
import { getPlaceById } from "../api/places";

const ContactsTab = ({ customer }) => {
  const [nameError, setNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [contacts, setContacts] = useState([]);
  const [selectedContact, setSelectedContact] = useState(null);
  const [visible, setVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [places, setPlaces] = useState([]);
  const [confirmationModalVisible, setConfirmationModalVisible] =
    useState(false);
  const [contactForm, setContactForm] = useState({
    name: "",
    last_name: "",
    email: "",
    phone: "",
    corporate: false,
    place_id: null,
  });
  const [corporateOptions] = useState([
    { label: "Sí", value: true },
    { label: "No", value: false },
  ]);

  useEffect(() => {
    loadContacts();
    loadPlaces();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer]);

  const loadContacts = async () => {
    try {
      if (customer) {
        const response = await getContactsById(customer.id);
        if (Array.isArray(response.data)) {
          setContacts(response.data);
        } else {
          console.error(
            "La respuesta de getContacts no es un array:",
            response
          );
        }
      } else {
        setContacts([]);
      }
    } catch (e) {
      console.error("Error al obtener contactos:", e);
    }
  };

  const loadPlaces = async () => {
    try {
      if (customer) {
        const response = await getPlaceById(customer.id);
        if (Array.isArray(response.data)) {
          setPlaces(response.data);
        } else {
          console.error("La respuesta de getPlaces no es un array:", response);
        }
      } else {
        setPlaces([]);
      }
    } catch (error) {
      console.error("Error al obtener lugares:", error);
    }
  };

  const openDialog = (contact) => {
    loadPlaces();
    setSelectedContact(contact);
    setContactForm({
      name: contact?.name || "",
      last_name: contact?.last_name || "",
      email: contact?.email || "",
      phone: contact?.phone || "",
      corporate: contact?.corporate || false,
      place_id: contact?.place_id || null,
    });
    setVisible(true);
    setIsEditing(!!contact);
  };

  const hideDialog = () => {
    setVisible(false);
    setSelectedContact(null);
    setContactForm({
      name: "",
      last_name: "",
      email: "",
      phone: "",
      corporate: false,
      place_id: null,
    });
    setNameError("");
    setIsEditing(false);
  };

  const saveContact = async () => {
    try {
      if (!contactForm.name) {
        setNameError("El nombre del contacto es obligatorio");
        return;
      }

      if (!contactForm.last_name) {
        setLastNameError("El apellido del contacto es obligatorio");
        return;
      }

      if (!contactForm.email) {
        setEmailError("El correo electrónico del contacto es obligatorio");
        return;
      }

      if (!contactForm.phone) {
        setPhoneError("El teléfono del contacto es obligatorio");
        return;
      }

      await updateOrCreateContact();
    } catch (error) {
      console.error("Error al guardar el contacto:", error);
      showToast("error", "Error", "Error al guardar el contacto");
    }
  };

  const created_by = parseInt(localStorage.getItem("id"));

  const updateOrCreateContact = async () => {
    try {
      if (selectedContact) {
        await updateContact(
          selectedContact.id,
          contactForm.name,
          contactForm.last_name,
          contactForm.email,
          contactForm.phone,
          contactForm.corporate,
          contactForm.place_id
        );
      } else {
        await createContact(
          contactForm.name,
          contactForm.last_name,
          contactForm.email,
          contactForm.phone,
          contactForm.corporate,
          customer.id,
          contactForm.place_id,
          created_by
        );
      }

      loadContacts();
      hideDialog();
      showToast("success", "Operación Exitosa", "Contacto guardado con éxito");
    } catch (error) {
      console.error("Error al guardar el contacto:", error);
      showToast("error", "Error", "Error al guardar el contacto");
    }
  };

  const confirmDelete = async () => {
    try {
      await deleteContact(selectedContact.id);
      loadContacts();
      hideDialog();
      showToast("success", "Operación Exitosa", "Contacto eliminado con éxito");
    } catch (error) {
      console.error("Error al eliminar el contacto:", error);
      showToast("error", "Error", "Error al eliminar el contacto");
    }
  };

  const showToast = (severity, summary, detail) => {
    toast.current.show({ severity, summary, detail });
  };

  const onCorporateChange = (e) => {
    const isCorporate = e.value;
    setContactForm({ ...contactForm, corporate: isCorporate, place_id: null });
  };

  const onPlaceIdChange = (e) => {
    setContactForm({ ...contactForm, place_id: e.value });
  };

  const corporateBody = (rowData) => {
    return <span>{rowData.corporate ? "Sí" : "No"}</span>;
  };

  const toast = React.useRef(null);

  return (
    <div
      className="Table"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <div className="TableData">
        <div className="TableHeader">
          <div>
            <Button
              label="Agregar Contacto"
              icon="pi pi-plus"
              onClick={() => openDialog(null)}
              className="p-mb-2"
            />
          </div>
        </div>
        <DataTable
          value={contacts}
          selectionMode="single"
          onSelectionChange={(e) => openDialog(e.value)}
        >
          <Column field="name" header="Nombre" sortable></Column>
          <Column field="last_name" header="Apellido" sortable></Column>
          <Column field="email" header="Correo Electrónico" sortable></Column>
          <Column field="phone" header="Teléfono" sortable></Column>
          <Column
            field="corporate"
            header="Corporativo"
            body={corporateBody}
          ></Column>
          <Column 
            field="place_name" 
            header="Lugar"
            sortable            
          ></Column>
        </DataTable>
      </div>

      <Dialog
        visible={visible}
        onHide={hideDialog}
        header={isEditing ? "Editar Contacto" : "Nuevo Contacto"}
        style={{ width: "80vw", maxWidth: "800px" }}
      >
        <div className="p-fluid">
          <div className="p-field">
            <label htmlFor="name" className="title">
              <b>Nombre</b>
            </label>
            <InputText
              id="name"
              value={contactForm.name}
              onChange={(e) =>
                setContactForm({ ...contactForm, name: e.target.value })
              }
            />
            {nameError && <small className="p-error">{nameError}</small>}
          </div>
          <div className="p-field">
            <label htmlFor="last_name" className="title">
              <b>Apellido</b>
            </label>
            <InputText
              id="last_name"
              value={contactForm.last_name}
              onChange={(e) =>
                setContactForm({ ...contactForm, last_name: e.target.value })
              }
            />
            {lastNameError && (
              <small className="p-error">{lastNameError}</small>
            )}
          </div>
          <div className="p-field">
            <label htmlFor="email" className="title">
              <b>Email</b>
            </label>
            <InputText
              id="email"
              value={contactForm.email}
              onChange={(e) =>
                setContactForm({ ...contactForm, email: e.target.value })
              }
            />
            {emailError && <small className="p-error">{emailError}</small>}
          </div>
          <div className="p-field">
            <label htmlFor="phone" className="title">
              <b>Teléfono</b>
            </label>
            <InputText
              id="phone"
              value={contactForm.phone}
              onChange={(e) =>
                setContactForm({ ...contactForm, phone: e.target.value })
              }
            />
            {phoneError && <small className="p-error">{phoneError}</small>}
          </div>
          <div className="p-field">
            <label className="title">
              <b>Corporativo</b>
            </label>
            <Dropdown
              value={contactForm.corporate ? true : false}
              options={corporateOptions}
              onChange={onCorporateChange}
              placeholder="Seleccione Sí o No"
            />
          </div>
          {contactForm.corporate === false && (
            <div className="p-field">
              <label className="title">
                <b>Lugar</b>
              </label>
              <Dropdown
                optionLabel="name"
                optionValue="id"
                value={contactForm.place_id}
                options={places}
                onChange={onPlaceIdChange}
                placeholder="Seleccione un lugar"
              />
            </div>
          )}
        </div>
        <div className="p-mt-2">
          <Button label="Guardar" icon="pi pi-check" onClick={saveContact} />
          <Button
            label="Cancelar"
            icon="pi pi-times"
            className="p-button-secondary p-ml-2"
            onClick={hideDialog}
          />
          {selectedContact && (
            <Button
              label="Eliminar"
              icon="pi pi-trash"
              className="p-button-danger p-ml-2"
              onClick={() => setConfirmationModalVisible(true)}
            />
          )}
        </div>
      </Dialog>

      <ConfirmationModal
        visible={confirmationModalVisible}
        onHide={() => setConfirmationModalVisible(false)}
        onConfirm={confirmDelete}
        onCancel={() => setConfirmationModalVisible(false)}
        message={"este contacto"}
      />

      <Toast ref={toast} />
    </div>
  );
};

export default ContactsTab;

import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import ConfirmationModal from "./ConfirmationModal";
import "./TablesStyle.css";
import {
  getInstrumentTypes,
  createInstrumentType,
  updateInstrumentType,
  deleteInstrumentType,
} from "../api/instruments";

const InstrumentsTypes = () => {
  const [instrumentsTypes, setInstrumentsTypes] = useState([]);
  const [selectedType, setSelectedType] = useState(null);
  const [visible, setVisible] = useState(false);
  const [instrumentTypeForm, setInstrumentTypeForm] = useState({
    name: "",
    logo: "",
    logoPreview: null,
  });
  const [confirmationModalVisible, setConfirmationModalVisible] =
    useState(false);
  const [searchText, setSearchText] = useState("");
  const [nameError, setNameError] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [disableSave, setDisableSave] = useState(false);
  useEffect(() => {
    loadInstrumentsTypes();
  }, []);

  const loadInstrumentsTypes = async () => {
    try {
      const response = await getInstrumentTypes();
      setInstrumentsTypes(response.data);
    } catch (error) {
      console.error("Error al obtener tipos:", error);
    }
  };

  const openDialog = (type) => {
    setSelectedType(type);
    setInstrumentTypeForm({
      name: type?.name || "",
    });
    setVisible(true);
    setIsEditing(!!type);
  };

  const hideDialog = () => {
    setVisible(false);
    setSelectedType(null);
    setInstrumentTypeForm({ name: "" });
    setNameError("");
    setIsEditing(false);
  };

  const saveInstrumentType = async () => {
    try {
      if (!instrumentTypeForm.name) {
        setNameError("El nombre es obligatorio");
        return;
      }
      await updateOrCreateInstrumentType();
    } catch (error) {
      console.error("Error al guardar tipo de instrumento:", error);
      showToast("error", "Error", "Error al guardar tipo de instrumento");
    }
  };

  const updateOrCreateInstrumentType = async () => {
    setDisableSave(true);
    try {
      if (selectedType) {
        await updateInstrumentType(selectedType.id, instrumentTypeForm.name);
      } else {
        await createInstrumentType(instrumentTypeForm.name);
      }
      loadInstrumentsTypes();
      hideDialog();
      showToast("success", "Operación Exitosa", "Tipo guardado con éxito");
      setDisableSave(false);
    } catch (error) {
      console.error("Error al guardar tipo de instrumento:", error);
      showToast("error", "Error", "Error al guardar tipo de instrumento");
      setDisableSave(false);
    }
  };

  const showConfirmationModal = () => {
    setConfirmationModalVisible(true);
  };

  const hideConfirmationModal = () => {
    setConfirmationModalVisible(false);
  };

  const confirmDelete = async () => {
    try {
      await deleteInstrumentType(selectedType.id);
      loadInstrumentsTypes();
      hideDialog();
      showToast("success", "Operación Exitosa", "Tipo eliminado con éxito");
    } catch (error) {
      console.error("Error al eliminar tipo:", error);
      showToast("error", "Error", "Error al eliminar tipo de instrumento");
    }
  };

  const showToast = (severity, summary, detail) => {
    toast.current.show({ severity, summary, detail });
  };

  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  const filteredInstrumentsTypes = instrumentsTypes.filter((type) =>
    type.name.toLowerCase().includes(searchText.toLowerCase())
  );

  const toast = React.useRef(null);

  return (
    <div className="Table">
      <div className="TableData">
        <h2>Tipos de Instrumentos</h2>
        <div className="TableHeader">
          <div>
            <Button
              label="Agregar Tipo de Instrumento"
              icon="pi pi-plus"
              onClick={() => openDialog(null)}
              className="p-mb-2"
            />
          </div>
          <div>
            <span className="p-input-icon-left">
              <i className="pi pi-search" />
              <InputText
                placeholder="Buscar por nombre"
                value={searchText}
                onChange={handleSearch}
              />
            </span>
          </div>
        </div>
        <DataTable
          value={filteredInstrumentsTypes}
          selectionMode="single"
          onSelectionChange={(e) => openDialog(e.value)}
        >
          <Column field="name" header="Nombre" sortable></Column>
        </DataTable>
      </div>

      <Dialog
        visible={visible}
        onHide={hideDialog}
        header={
          isEditing ? "Editar Tipo de Instrumento" : "Nuevo Tipo de Instrumento"
        }
        style={{
          width: isEditing ? "90vw" : "40%",
          height: isEditing ? "100vh" : "25vw",
          overflow: "auto",
        }}
      >
        <div style={{ textAlign: "left", paddingLeft: "7rem" }}>
          <label htmlFor="name">Nombre</label>
          <div
            className="p-fluid"
            style={{ display: "flex", justifyContent: "flex-start" }}
          >
            <div className="p-field">
              <InputText
                id="name"
                value={instrumentTypeForm.name}
                onChange={(e) => {
                  setInstrumentTypeForm({
                    ...instrumentTypeForm,
                    name: e.target.value,
                  });
                  setNameError("");
                }}
              />
              {nameError && <small className="p-error">{nameError}</small>}
            </div>
          </div>
          {instrumentTypeForm.logoPreview && (
            <img
              src={instrumentTypeForm.logoPreview}
              alt="Vista previa del logo"
              style={{
                width: isEditing ? "15%" : "25%",
                objectFit: "cover",
                marginTop: "0.5rem",
              }}
            />
          )}
          <div className="p-mt-2">
            <Button
              label="Guardar"
              icon="pi pi-check"
              onClick={saveInstrumentType}
              disabled={disableSave}
            />
            <Button
              label="Cancelar"
              icon="pi pi-times"
              className="p-button-secondary p-ml-2"
              onClick={hideDialog}
            />
            {selectedType && (
              <Button
                label="Eliminar"
                icon="pi pi-trash"
                className="p-button-danger p-ml-2"
                onClick={showConfirmationModal}
              />
            )}
          </div>

          {isEditing && (
            <hr
              style={{
                border: "none",
                borderBottom: "1.5px solid #ccc",
                margin: "1.5rem 0 1rem",
                width: "90%",
              }}
            />
          )}
        </div>
      </Dialog>

      <ConfirmationModal
        visible={confirmationModalVisible}
        onHide={hideConfirmationModal}
        onConfirm={confirmDelete}
        onCancel={hideConfirmationModal}
      />

      <Toast ref={toast} />
    </div>
  );
};

export default InstrumentsTypes;

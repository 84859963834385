import { instance } from "./axios";

export const sendEmailWithRecipients = async (file, recipients, visitData) => {
  try {
    const emails = [];
    const formData = new FormData();
    formData.append("pdf", file);
    recipients.forEach(
      (recipient) => recipient.email && emails.push(recipient.email)
    );

    formData.append("emails", emails.join(","));

    formData.append("visitData", JSON.stringify(visitData));

    const response = await instance.post(`/email`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const setSendedAt = async (visitId) => {
  try {
    const response = await instance.put(`/visit/sended-at/${visitId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

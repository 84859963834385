import { useState, useEffect } from "react";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { MultiSelect } from "primereact/multiselect";
import { getCustomers } from "../../../api/customers";
import { getUsers } from "../../../api/users";
import { getPlaceById } from "../../../api/places";
import { getReportData } from "../../../api/reports";
import "../Styles.css";
import { Oval } from "react-loader-spinner";
import { generateXls } from "./excel";
const Trips = (props) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [customers, setCustomers] = useState([]);
  const [visitors, setVisitors] = useState([]);
  const [places, setPlaces] = useState([]);
  const [selectedCustomers, setSelectedCustomers] = useState(null);
  const [selectedVisitors, setSelectedVisitors] = useState(null);
  const [selectedPlaces, setSelectedPlaces] = useState(null);
  const [isDownloading, setIsDownloading] = useState(false);

  const loadCustomers = async () => {
    try {
      const { data } = await getCustomers();
      setCustomers(data);
    } catch (error) {
      console.error("Error al obtener clientes:", error);
    }
  };

  const loadVisitors = async () => {
    try {
      const { data } = await getUsers();
      setVisitors(data);
    } catch (error) {
      console.error("Error al obtener técnicos:", error);
    }
  };

  const loadPlaces = () => {
    const places = [];

    selectedCustomers.map(async (customer) => {
      try {
        const { data } = await getPlaceById(customer.id);
        places.push(...data);
      } catch (error) {
        console.error("Error al obtener lugares:", error);
      }
    });
    setPlaces(places);
  };

  useEffect(() => {
    if (selectedCustomers) loadPlaces();
    // eslint-disable-next-line
  }, [selectedCustomers]);

  useEffect(() => {
    loadCustomers();
    loadVisitors();
  }, []);

  const fieldsValidation = () => {
    if (
      !startDate ||
      !endDate ||
      !selectedCustomers ||
      !selectedPlaces ||
      !selectedVisitors
    ) {
      props.showToast("error", "Error", "Debe completar todos los campos");
      return false;
    }
    return true;
  };

  const onSearch = async () => {
    const isValid = fieldsValidation();
    if (!isValid) return;
    setIsDownloading(true);
    const places = selectedPlaces.map((place) => place.id);
    const visitors = selectedVisitors.map((visitor) => visitor.id);
    const formattedStartDate = new Date(startDate).toISOString().slice(0, 10);
    const formattedEndDate = new Date(endDate).toISOString().slice(0, 10);
    const payload = {
      name: "Traslados",
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      places,
      visitors,
    };
    try {
      const { data } = await getReportData(payload);
      console.log("Reporte obtenido:", data);
      setIsDownloading(false);
      generateXls(data);
    } catch (err) {
      setIsDownloading(false);
      console.error(err);
      props.showToast("error", "Error", "Error al obtener reporte");
    }
  };

  const userTemplate = (option) => `${option.name} ${option.last_name}`;

  return (
    <div>
      <div className="row">
        <div className="p-inputgroup" style={{ marginRight: 10 }}>
          <span className="dateLabel">Desde:</span>
          <Calendar
            id="startDate"
            placeholder="Desde"
            value={startDate}
            onChange={(e) => setStartDate(e.value)}
            dateFormat="dd/mm/yy"
          />
        </div>
        <div className="p-inputgroup" style={{ marginRight: 10 }}>
          <span className="dateLabel">Hasta:</span>
          <Calendar
            id="endDate"
            placeholder="Hasta"
            value={endDate}
            onChange={(e) => setEndDate(e.value)}
            dateFormat="dd/mm/yy"
          />
        </div>
      </div>
      <div className="row">
        <div className="p-inputgroup" style={{ marginRight: 10 }}>
          <span className="dateLabel">Cliente:</span>
          <MultiSelect
            value={selectedCustomers}
            onChange={(e) => setSelectedCustomers(e.value)}
            options={customers}
            optionLabel="name"
            placeholder="Seleccione Clientes"
            maxSelectedLabels={1}
          />
        </div>
        <div className="p-inputgroup" style={{ marginRight: 10 }}>
          <span className="dateLabel">Lugares:</span>
          <MultiSelect
            value={selectedPlaces}
            onChange={(e) => setSelectedPlaces(e.value)}
            options={places}
            optionLabel="name"
            placeholder="Seleccione Lugares"
            maxSelectedLabels={1}
          />
        </div>
      </div>
      <div className="row">
        <div className="p-inputgroup" style={{ marginRight: 10 }}>
          <span className="dateLabel">Técnico:</span>
          <MultiSelect
            value={selectedVisitors}
            onChange={(e) => setSelectedVisitors(e.value)}
            options={visitors}
            placeholder="Seleccione Técnicos"
            maxSelectedLabels={1}
            optionLabel={userTemplate}
          />
        </div>
      </div>
      <div className="p-inputgroup">
        {isDownloading ? (
          <div style={{ padding: 10 }}>
            <Oval visible={true} height="40" width="40" color="#0078d4" />
          </div>
        ) : (
          <Button label="Exportar" icon="pi pi-file-excel" onClick={onSearch} />
        )}
      </div>
    </div>
  );
};

export default Trips;

import React, { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import TechnicalVisits from "./TechnicalVisits";
import Trips from "./Trips";
import { Toast } from "primereact/toast";

const Reports = () => {
  const [visible, setVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const toast = React.useRef(null);
  const showToast = (severity, summary, detail) => {
    toast.current.show({ severity, summary, detail });
  };
  const reports = [
    {
      id: 1,
      name: "Visitas técnicas realizadas",
      component: <TechnicalVisits showToast={showToast} />,
    },
    {
      id: 2,
      name: "Información de traslados",
      component: <Trips showToast={showToast} />,
    },
  ];
  const openDialog = (selectedRow) => {
    setSelectedRow(selectedRow);
    setVisible(true);
  };

  return (
    <div className="Table">
      <div className="TableData">
        <h2>Reportes</h2>
        <DataTable
          value={reports}
          selectionMode="single"
          onSelectionChange={(e) => openDialog(e.value)}
        >
          <Column field="id" header="#"></Column>
          <Column field="name" header="Nombre"></Column>
        </DataTable>
      </div>
      <Dialog
        header={selectedRow?.name}
        visible={visible}
        style={{ width: "40vw" }}
        onHide={() => {
          if (!visible) return;
          setVisible(false);
        }}
      >
        {selectedRow?.component}
      </Dialog>
      <Toast ref={toast} />
    </div>
  );
};

export default Reports;

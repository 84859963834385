import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { isAdmin, isReviewer, logOut } from "../services/auth";

import "./Login.css";

export default function Login() {
  const { login } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const { token, user } = await login(values.email, values.password);
        localStorage.setItem("token", token);
        localStorage.setItem("user", JSON.stringify(user));
        window.location.href = "/";
      } catch (error) {
        console.error(error);
        setError("Correo o contraseña inválidos");
      } finally {
        setLoading(false);
      }
    },
    validate: (values) => {
      const errors = {};

      if (!values.email) {
        errors.email = "Email requerido";
      }

      if (!values.password) {
        errors.password = "Contraseña requerida";
      }

      return errors;
    },
  });

  useEffect(() => {
    if (!localStorage.getItem("token")) return;
    try {
      if (isAdmin()) {
        navigate("/clientes");
        return;
      }
      if (isReviewer()) {
        navigate("/visitas");
        return;
      }
    } catch (err) {
      console.debug("Error al validar el perfil del usuario", err);
      logOut();
    }

    navigate("/visitas-comerciales");
  }, [navigate]);

  return (
    <div className="loginForm">
      <h1>Iniciar sesión</h1>
      <form onSubmit={formik.handleSubmit}>
        <div className="p-fluid">
          <div className="p-field">
            <label htmlFor="email">Correo electrónico</label>
            <InputText
              id="email"
              name="email"
              type="email"
              onChange={formik.handleChange}
              value={formik.values.email}
            />
            {formik.touched.email && formik.errors.email && (
              <small className="p-error">{formik.errors.email}</small>
            )}
          </div>
          <div className="p-field">
            <label htmlFor="password">Contraseña</label>
            <InputText
              id="password"
              name="password"
              type="password"
              onChange={formik.handleChange}
              value={formik.values.password}
            />
            {formik.touched.password && formik.errors.password && (
              <small className="p-error">{formik.errors.password}</small>
            )}
          </div>
        </div>
        {error && <div className="p-error">{error}</div>}
        <Button
          type="submit"
          label="Iniciar sesión"
          loading={loading}
          disabled={!formik.isValid || !formik.dirty}
        />
      </form>
    </div>
  );
}

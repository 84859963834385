import React from "react";
import Customers from "../components/Customers";
import Users from "../components/Users";
import Instruments from "../components/Instruments";
import Species from "../components/Species";
import Varieties from "../components/Varieties";
import Visits from "../components/Visit/Visit";
import Brands from "../components/Brands";
import InstrumentsTypes from "../components/InstrumentsTypes";
import Products from "../components/Products";
import PlacesTypes from "../components/PlacesTypes";
import Packings from "../components/Packings";
import Commercialvisits from "../components/CommercialVisit";
import Reports from "../components/Reports";
import { validateToken } from "../api/users";
import "./Dashboard.css";
import Sidebar from "../components/Sidebar.js";
import { useLocation } from "react-router-dom";
import { isAdmin, isReviewer, isCommercial } from "../services/auth";
import { logOut, getUserData } from "../services/auth";
const Dashboard = () => {
  const location = useLocation();
  const tokenValidation = async () => {
    const user = getUserData();
    if (!user || !user.profiles) {
      logOut();
    }

    const isValid = await validateToken();
    if (!isValid) {
      logOut();
    }
  };
  tokenValidation();

  return (
    <div className="card" style={{ backgroundColor: "#fff" }}>
      <Sidebar />
      {isAdmin() && location.pathname === "/clientes" && <Customers />}
      {isAdmin() && location.pathname === "/usuarios" && <Users />}
      {isAdmin() && location.pathname === "/instrumentos" && <Instruments />}
      {isAdmin() && location.pathname === "/especies" && <Species />}
      {isAdmin() && location.pathname === "/variedades" && <Varieties />}
      {(isAdmin() || isReviewer()) && location.pathname === "/visitas" && (
        <Visits />
      )}
      {isAdmin() && location.pathname === "/marcas" && <Brands />}
      {isAdmin() && location.pathname === "/tipos-instrumentos" && (
        <InstrumentsTypes />
      )}
      {isAdmin() && location.pathname === "/productos" && <Products />}
      {isAdmin() && location.pathname === "/tipos-lugares" && <PlacesTypes />}
      {isAdmin() && location.pathname === "/formatos" && <Packings />}
      {(isAdmin() || isCommercial()) &&
        location.pathname === "/visitas-comerciales" && <Commercialvisits />}
      {(isAdmin() || isReviewer()) && location.pathname === "/reportes" && (
        <Reports />
      )}
    </div>
  );
};

export default Dashboard;

import React, { useState } from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { updatePackagingMaterialsComment } from "../api/visits";

const PackagingMaterials = ({
  packaging_materials_comment,
  visitId,
  showToastMessage,
  parentInputChange,
}) => {
  const [comment, setComment] = useState(packaging_materials_comment);

  const updateComment = async () => {
    try {
      await updatePackagingMaterialsComment(
        { packaging_materials_comment: comment },
        visitId
      );
      parentInputChange("packaging_materials_comment", comment);
      showToastMessage("success", "Listo!", "Comentario actualizado");
    } catch (error) {
      showToastMessage(
        "error",
        "Error!",
        "No se pudo actualizar el comentario"
      );
      console.error("Error updating packaging materials comment:", error);
    }
  };
  return (
    <div>
      <Accordion>
        <AccordionTab header="Preparación de Material">
          <InputTextarea
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            style={{ width: "100%", height: "200px" }}
            placeholder="Preparación de Material"
          />
          <Button label="Guardar" icon="pi pi-check" onClick={updateComment} />
        </AccordionTab>
      </Accordion>
    </div>
  );
};

export default PackagingMaterials;

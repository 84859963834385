import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import ConfirmationModal from "./ConfirmationModal";
import {
  getPlaceById,
  createPlace,
  deletePlace,
  updatePlace,
  getPlaceTypes,
} from "../api/places";
import { getRegions } from "../api/regions";

const PlacesTab = ({ customer }) => {
  const [places, setPlaces] = useState([]);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [visible, setVisible] = useState(false);
  const [placeForm, setPlaceForm] = useState({
    name: "",
    address: "",
    region: "",
    commune: "",
    placeTypeId: 0,
  });
  const [nameError, setNameError] = useState("");
  const [placeTypeError, setPlaceTypeError] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [placeTypes, setPlaceTypes] = useState([]);
  const [regions, setRegions] = useState([]);
  const [communes, setCommunes] = useState([]);
  const [confirmationModalVisible, setConfirmationModalVisible] =
    useState(false);
  const [allRegions, setAllRegions] = useState([]);
  const [allCommunes, setAllCommunes] = useState([]);

  useEffect(() => {
    loadPlaces();
    loadPlaceTypes();
    loadRegions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer]);

  const loadRegions = async () => {
    try {
      const response = await getRegions();
      setRegions(response.data);
      setAllRegions(response.data);
    } catch (error) {
      console.error("Error al obtener regiones:", error);
    }
  };

  const loadCommunes = async (regionName) => {
    try {
      const region = regions.find((r) => r.region === regionName);
      if (region) {
        setCommunes(region.provincias.flatMap((p) => p.comunas));
        setAllCommunes(region.provincias.flatMap((p) => p.comunas));
      } else {
        setCommunes([]);
        setAllCommunes([]);
      }
    } catch (error) {
      console.error("Error al cargar comunas:", error);
    }
  };

  const loadPlaces = async () => {
    try {
      if (customer) {
        const response = await getPlaceById(customer.id);
        if (Array.isArray(response.data)) {
          console.log(response.data);
          setPlaces(response.data);
        } else {
          console.error(
            "La respuesta de getPlaceById no es un array:",
            response
          );
        }
      } else {
        setPlaces([]);
      }
    } catch (error) {
      console.error("Error al obtener lugares:", error);
    }
  };

  const loadPlaceTypes = async () => {
    try {
      const response = await getPlaceTypes();
      setPlaceTypes(response.data);
    } catch (error) {
      console.error("Error al obtener tipos de lugares:", error);
    }
  };

  const openDialog = (place) => {
    setSelectedPlace(place);
    const selectedRegion = place?.region || "";
    const selectedCommune = place?.commune || "";

    if (allRegions.length > 0) {
      setCommunes(allCommunes);

      setPlaceForm({
        name: place?.name || "",
        address: place?.address || "",
        region: selectedRegion,
        commune: selectedCommune,
        placeTypeId: place?.place_type_id || 0,
      });
      loadCommunes(selectedRegion);
      setVisible(true);
      setIsEditing(!!place);
    }
  };

  const hideDialog = () => {
    setVisible(false);
    setSelectedPlace(null);
    setPlaceForm({
      name: "",
      address: "",
      region: "",
      commune: "",
      placeTypeId: 0,
    });
    setNameError("");
    setIsEditing(false);
  };

  const savePlace = async () => {
    try {
      if (!placeForm.name) {
        setNameError("El nombre del lugar es obligatorio");
        return;
      }

      if (!placeForm.placeTypeId) {
        setPlaceTypeError("El tipo de lugar es obligatorio");
        return;
      }

      await updateOrCreatePlace();
    } catch (error) {
      console.error("Error al guardar el lugar:", error);
      showToast("error", "Error", "Error al guardar el lugar");
    }
  };

  const created_by = parseInt(localStorage.getItem("id"));

  const updateOrCreatePlace = async () => {
    try {
      if (selectedPlace) {
        await updatePlace(
          selectedPlace.id,
          placeForm.name,
          placeForm.address,
          placeForm.region,
          placeForm.commune,
          placeForm.placeTypeId
        );
      } else {
        await createPlace(
          placeForm.name,
          placeForm.address,
          placeForm.region,
          placeForm.commune,
          placeForm.placeTypeId,
          customer.id,
          created_by
        );
      }

      loadPlaces();
      hideDialog();
      showToast("success", "Operación Exitosa", "lugar guardado con éxito");
    } catch (error) {
      console.error("Error al guardar el lugar:", error);
      showToast("error", "Error", "Error al guardar el lugat");
    }
  };

  const confirmDelete = async () => {
    try {
      await deletePlace(selectedPlace.id);
      loadPlaces();
      hideDialog();
      showToast("success", "Operación Exitosa", "Lugar eliminado con éxito");
    } catch (error) {
      console.error("Error al eliminar el lugar:", error);
      showToast("error", "Error", "Error al eliminar el lugar");
    }
  };

  const showToast = (severity, summary, detail) => {
    toast.current.show({ severity, summary, detail });
  };

  const onRegionChange = (e) => {
    const regionName = e.target.value;
    setPlaceForm({ ...placeForm, region: regionName });
    loadCommunes(regionName);
  };

  const onCommuneChange = (e) => {
    setPlaceForm({ ...placeForm, commune: e.target.value });
  };

  const onPlaceTypeIdChange = (e) => {
    setPlaceForm({ ...placeForm, placeTypeId: e.value });
  };

  const toast = React.useRef(null);

  return (
    <div
      className="Table"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <div className="TableData">
        <div className="TableHeader">
          <div>
            <Button
              label="Agregar Lugar"
              icon="pi pi-plus"
              onClick={() => openDialog(null)}
              className="p-mb-2"
            />
          </div>
        </div>
        <DataTable
          value={places}
          selectionMode="single"
          onSelectionChange={(e) => openDialog(e.value)}
        >
          <Column field="name" header="Nombre" sortable></Column>
          <Column field="place_type_name" header="Tipo de lugar" sortable></Column>
          <Column field="region" header="Región" sortable></Column>
          <Column field="commune" header="Comuna" sortable></Column>
        </DataTable>
      </div>

      <Dialog
        visible={visible}
        onHide={hideDialog}
        header={isEditing ? "Editar Lugar" : "Nuevo Lugar"}
        style={{ width: "80vw", maxWidth: "800px" }}
      >
        <div className="p-fluid">
          <div className="p-field">
            <label htmlFor="name" className="title">
              <b>Nombre</b>
            </label>
            <InputText
              id="name"
              value={placeForm.name}
              onChange={(e) => {
                setPlaceForm({ ...placeForm, name: e.target.value });
                setNameError("");
              }}
            />
            {nameError && <small className="p-error">{nameError}</small>}
          </div>
          <div className="p-field">
            <label htmlFor="address" className="title">
              <b>Dirección</b>
            </label>
            <InputText
              id="address"
              value={placeForm.address}
              onChange={(e) => {
                setPlaceForm({ ...placeForm, address: e.target.value });
              }}
            />
          </div>
          <div className="p-field">
            <label htmlFor="region" className="title">
              <b>Region</b>
            </label>
            <Dropdown
              optionLabel="region"
              optionValue="region"
              value={placeForm.region}
              options={regions}
              onChange={onRegionChange}
              placeholder="Seleccione una región"
            />
          </div>
          <div className="p-field">
            <label htmlFor="commune" className="title">
              <b>Comuna</b>
            </label>
            <Dropdown
              optionLabel="name"
              optionValue="name"
              value={placeForm.commune}
              options={communes}
              onChange={onCommuneChange}
              placeholder="Seleccione una comuna"
              disabled={!placeForm.region}
            />
          </div>
          <div className="p-field">
            <label className="title">
              <b>Tipo de Lugar</b>
            </label>
            <Dropdown
              optionLabel="name"
              optionValue="id"
              value={placeForm.placeTypeId}
              options={placeTypes}
              onChange={onPlaceTypeIdChange}
              placeholder="Seleccione un tipo de lugar"
            />
            {placeTypeError && (
              <small className="p-error">{placeTypeError}</small>
            )}
          </div>
        </div>
        <div className="p-mt-2">
          <Button label="Guardar" icon="pi pi-check" onClick={savePlace} />
          <Button
            label="Cancelar"
            icon="pi pi-times"
            className="p-button-secondary p-ml-2"
            onClick={hideDialog}
          />
          {selectedPlace && (
            <Button
              label="Eliminar"
              icon="pi pi-trash"
              className="p-button-danger p-ml-2"
              onClick={() => setConfirmationModalVisible(true)}
            />
          )}
        </div>
      </Dialog>

      <ConfirmationModal
        visible={confirmationModalVisible}
        onHide={() => setConfirmationModalVisible(false)}
        onConfirm={confirmDelete}
        onCancel={() => setConfirmationModalVisible(false)}
        message={"este lugar"}
      />

      <Toast ref={toast} />
    </div>
  );
};

export default PlacesTab;

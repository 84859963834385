import React, { useState, useEffect } from "react";
import { Sidebar } from "primereact/sidebar";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import USerPassword from "./UserPassword";
import "./Sidebar.css";
import "primeicons/primeicons.css";
import fullMenu from "../resources/menu";
import { logOut, isAdmin, getUserData } from "../services/auth";

export default function Side() {
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [menu, setMenu] = useState(null);

  const goTo = (path) => {
    navigate(path);
    setVisible(false);
  };

  const user = getUserData();

  const buildMenuByProfiles = () => {
    const filteredMenu = [];
    // eslint-disable-next-line
    fullMenu.map((item) => {
      const menuOption = {
        title: item.title,
        icon: item.icon,
        subMenu: [],
      };
      // eslint-disable-next-line
      item.subMenu.map((subItem) => {
        // eslint-disable-next-line
        subItem.profiles.map((profileId) => {
          // eslint-disable-next-line
          if (user.profiles.map((profile) => profile.id).includes(profileId)) {
            menuOption.subMenu.push(subItem);
          }
        });
      });
      if (menuOption.subMenu.length > 0) filteredMenu.push(menuOption);
    });

    setMenu(filteredMenu);
  };

  useEffect(() => {
    if (isAdmin()) {
      setMenu(fullMenu);
      return;
    }
    buildMenuByProfiles();
    // eslint-disable-next-line
  }, []);

  if (!menu) return null;
  return (
    <div className="card flex justify-content-center">
      <Sidebar visible={visible} onHide={() => setVisible(false)}>
        <h3>Hola {user.name}!</h3>
        {menu.map((item, index) => (
          <div key={index}>
            <div className="parent">
              <i className={item.icon}></i>
              {item.title}
            </div>
            {item.subMenu.map((subItem, subIndex) => (
              <div
                className="child"
                key={subIndex}
                onClick={() => goTo(subItem.path)}
              >
                {subItem.title}
              </div>
            ))}
          </div>
        ))}
        <div className="parent">
          <i className="pi pi-lock"></i>
          Seguridad
        </div>
        <div className="child" onClick={() => setShowModal(true)}>
          Cambiar Clave
        </div>
        <div className="child" onClick={() => logOut()}>
          Salir
        </div>
      </Sidebar>
      <Button icon="pi pi-bars" onClick={() => setVisible(true)} size="large" />
      <USerPassword
        visible={showModal}
        hideDialog={() => setShowModal(false)}
      />
    </div>
  );
}

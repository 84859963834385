import React, { useState, useEffect } from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { getVarieties } from "../api/variety";
import { getPackings } from "../api/packing";
import { getProducts } from "../api/products";
import { updateMeasurement } from "../api/measurements";
import { formatDate } from "../utils/formatDate";
import { getVisitImageUrl } from "../utils/getImageUrl";
import { Toast } from "primereact/toast";

const MeasurementPanel = ({ measurement, onChange }) => {
  const [varieties, setVarieties] = useState([]);
  const [packings, setPackings] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedMeasurement, setSelectedMeasurement] = useState(null);
  const [originalMeasurement, setOriginalMeasurement] = useState(null);
  const [displayModal, setDisplayModal] = useState(false);
  const [sortOrder, setSortOrder] = useState(1);

  useEffect(() => {
    loadVarieties();
    loadPackings();
    loadProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadVarieties = async () => {
    try {
      const response = await getVarieties();
      setVarieties(response.data);
    } catch (error) {
      console.error("Error fetching varieties:", error);
    }
  };

  const loadPackings = async () => {
    try {
      const response = await getPackings();
      setPackings(response.data);
    } catch (error) {
      console.error("Error fetching packings:", error);
    }
  };

  const loadProducts = async () => {
    try {
      const response = await getProducts();
      setProducts(response.data);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const handleInputChange = (field, value) => {
    setSelectedMeasurement((prevMeasurement) => ({
      ...prevMeasurement,
      [field]: value,
    }));
  };

  const showToast = (severity, summary, detail) => {
    toast.current.show({ severity, summary, detail });
  };

  const toast = React.useRef(null);

  const handleUpdateClick = () => {
    if (selectedMeasurement && selectedMeasurement.id) {
      const formattedMeasurement = {
        ...selectedMeasurement,
        packaging_date: formatDate(
          new Date(selectedMeasurement.packaging_date)
        ),
        sealing_date: formatDate(new Date(selectedMeasurement.sealing_date)),
      };
      updateMeasurement(formattedMeasurement, selectedMeasurement.id)
        .then(() => {
          const updatedMeasurements = [
            ...measurement.filter((m) => m.id !== selectedMeasurement.id),
            selectedMeasurement,
          ];
          const sortedMeasurements = updatedMeasurements.sort(
            (a, b) => sortOrder * (a.id - b.id)
          );
          onChange(sortedMeasurements);
          setDisplayModal(false);
          showToast("success", "Éxito", "Medición actualizada correctamente");
        })
        .catch((error) => {
          console.error("Error al actualizar measurement:", error);
          showToast("error", "Error", "Error al actualizar medición");
        });
    }
  };

  const openModal = (measurement) => {
    setSelectedMeasurement(measurement);
    setOriginalMeasurement({ ...measurement });
    setDisplayModal(true);
  };

  const closeModal = () => {
    setSelectedMeasurement(null);
    if (originalMeasurement) {
      setSelectedMeasurement({ ...originalMeasurement });
      setOriginalMeasurement(null);
    }
    setDisplayModal(false);
  };

  const handleDeleteImage = (index) => {
    const updatedMeasurement = { ...selectedMeasurement };
    const updatedDocuments = JSON.parse(updatedMeasurement.documents || "[]");
    updatedDocuments.splice(index, 1);
    updatedMeasurement.documents = JSON.stringify(updatedDocuments);
    setSelectedMeasurement(updatedMeasurement);
  };

  return (
    <div>
      <Accordion>
        <AccordionTab header="Mediciones de gases">
          <DataTable
            value={measurement}
            selectionMode="single"
            selection={selectedMeasurement}
            onSelectionChange={(e) => openModal(e.value)}
            onSort={(e) => {
              setSortOrder(e.sortOrder);
            }}
          >
            <Column
              header="N° Caja"
              body={(data, options) => options.rowIndex + 1}
            ></Column>
            <Column field="pallet" header="N° Pallet"></Column>
            <Column field="product_code" header="Cod. Producto"></Column>
            <Column field="sealing_days" header="Días sellado"></Column>
            <Column field="co2" header="CO2"></Column>
            <Column field="o2" header="O2"></Column>
            <Column field="pulp_temperature" header="T° Pulpa"></Column>
            <Column field="min_wind_speed" header="Vel Min"></Column>
            <Column field="max_wind_speed" header="Vel Max"></Column>
          </DataTable>
        </AccordionTab>
      </Accordion>

      <Dialog
        visible={displayModal}
        header={"Editar Medición"}
        style={{ width: "50vw" }}
        onHide={closeModal}
      >
        <div>
          <div className="field-title">Código de cámara</div>
          <InputText
            value={selectedMeasurement?.room_code || ""}
            onChange={(e) => handleInputChange("room_code", e.target.value)}
            placeholder="Código de cámara"
          />

          <div className="field-title">Temperatura de cámara</div>
          <InputText
            value={selectedMeasurement?.room_temperature || ""}
            onChange={(e) =>
              handleInputChange("room_temperature", e.target.value)
            }
            placeholder="Temperatura de cámara"
          />

          <div className="field-title">Velocidad Mínima del Viento</div>
          <InputText
            value={selectedMeasurement?.min_wind_speed || ""}
            onChange={(e) =>
              handleInputChange("min_wind_speed", e.target.value)
            }
            placeholder="Velocidad Mínima del Viento"
          />

          <div className="field-title">Velocidad Máxima del Viento</div>
          <InputText
            value={selectedMeasurement?.max_wind_speed || ""}
            onChange={(e) =>
              handleInputChange("max_wind_speed", e.target.value)
            }
            placeholder="Velocidad Máxima del Viento"
          />

          <div className="field-title">Humedad Ambiental</div>
          <InputText
            value={selectedMeasurement?.ambient_humidity || ""}
            onChange={(e) =>
              handleInputChange("ambient_humidity", e.target.value)
            }
            placeholder="Humedad Ambiental"
          />

          <div className="field-title">Pallet</div>
          <InputText
            value={selectedMeasurement?.pallet || ""}
            onChange={(e) => handleInputChange("pallet", e.target.value)}
            placeholder="Pallet"
          />

          <div className="field-title">Variedad</div>
          <Dropdown
            optionLabel="name"
            optionValue="id"
            value={selectedMeasurement?.variety_id}
            options={varieties}
            onChange={(e) => handleInputChange("variety_id", e.value)}
            placeholder="Id de Variedad"
          />

          <div className="field-title">Packing</div>
          <Dropdown
            optionLabel="name"
            optionValue="id"
            value={selectedMeasurement?.packing_id}
            options={packings}
            onChange={(e) => handleInputChange("packing_id", e.value)}
            placeholder="Id de Packing"
          />

          <div className="field-title">Fecha de Embalaje</div>
          <Calendar
            value={
              selectedMeasurement?.packaging_date
                ? new Date(selectedMeasurement.packaging_date)
                : null
            }
            onChange={(e) => handleInputChange("packaging_date", e.value)}
            placeholder="Fecha de Embalaje"
            dateFormat="dd/mm/yy"
          />

          <div className="field-title">Fecha de Sellado</div>
          <Calendar
            value={
              selectedMeasurement?.sealing_date
                ? new Date(selectedMeasurement.sealing_date)
                : null
            }
            onChange={(e) => handleInputChange("sealing_date", e.value)}
            placeholder="Fecha de Sellado"
            dateFormat="dd/mm/yy"
          />

          <div className="field-title">Días de Sellado</div>
          <InputText
            value={selectedMeasurement?.sealing_days || ""}
            onChange={(e) => handleInputChange("sealing_days", e.target.value)}
            placeholder="Días de Sellado"
          />

          <div className="field-title">Co2</div>
          <InputText
            value={selectedMeasurement?.co2 || ""}
            onChange={(e) => handleInputChange("co2", e.target.value)}
            placeholder="CO2"
          />

          <div className="field-title">O2</div>
          <InputText
            value={selectedMeasurement?.o2 || ""}
            onChange={(e) => handleInputChange("o2", e.target.value)}
            placeholder="O2"
          />

          <div className="field-title">Temperatura de Pulpa</div>
          <InputText
            value={selectedMeasurement?.pulp_temperature || ""}
            onChange={(e) =>
              handleInputChange("pulp_temperature", e.target.value)
            }
            placeholder="Temperatura de Pulpa"
          />

          <div className="field-title">Ubicación de Caja</div>
          <InputText
            value={selectedMeasurement?.box_ubication || ""}
            onChange={(e) => handleInputChange("box_ubication", e.target.value)}
            placeholder="Ubicación de Caja"
          />

          <div className="field-title">Producto</div>
          <Dropdown
            optionLabel="name"
            optionValue="id"
            value={selectedMeasurement?.product_id}
            options={products}
            onChange={(e) => handleInputChange("product_id", e.value)}
            placeholder="Id de Producto"
          />

          <div className="field-title">Comentario</div>
          <InputTextarea
            autoResize={true}
            value={selectedMeasurement?.comment || ""}
            onChange={(e) => handleInputChange("comment", e.target.value)}
            placeholder="Comentario"
          />
          {selectedMeasurement &&
          selectedMeasurement.documents &&
          JSON.parse(selectedMeasurement.documents).length > 0 ? (
            <div>
              <div className="field-title">Imágenes</div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                {Array.isArray(JSON.parse(selectedMeasurement.documents)) ? (
                  JSON.parse(selectedMeasurement.documents).map(
                    (document, index) => {
                      try {
                        const documentData = document;
                        const imageUrl = getVisitImageUrl(documentData.name);

                        return (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginRight: "10px",
                            }}
                          >
                            <img
                              src={imageUrl}
                              alt={`Documento ${documentData.name}`}
                              style={{ width: "100px", height: "100px" }}
                            />
                            <i
                              className="pi pi-trash"
                              style={{
                                cursor: "pointer",
                                color: "red",
                                marginTop: "2px",
                              }}
                              onClick={() => handleDeleteImage(index)}
                            />
                          </div>
                        );
                      } catch (error) {
                        console.error("Error al parsear el documento:", error);
                        return (
                          <div key={index}>
                            <span style={{ color: "red" }}>
                              Error al procesar la imagen.
                            </span>
                          </div>
                        );
                      }
                    }
                  )
                ) : (
                  <div>No hay imágenes válidas.</div>
                )}
              </div>
            </div>
          ) : (
            <div>No hay imágenes asociadas.</div>
          )}

          <div className="p-mt-2">
            <Button
              label="Guardar"
              icon="pi pi-check"
              onClick={handleUpdateClick}
            />
          </div>
        </div>
      </Dialog>
      <Toast ref={toast} />
    </div>
  );
};

export default MeasurementPanel;

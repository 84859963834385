import { instance } from "./axios";

export const getVarieties = async () => {
  try {
    return await instance.get("/varieties");
  } catch (error) {
    throw error;
  }
};

export const getVarietyById = async (id) => {
    try {
        return await instance.get(`/varieties/${id}`);
    } catch (error) {
        throw error;
    }
};

export const createVariety = async (name, specie_id) => {
    try {
        return await instance.post("/varieties", {
            name,
            specie_id,
        });
    } catch (error) {
        throw error;
    }
};

export const updateVariety = async (id, name, specie_id) => {
    try {
        return await instance.put(`/varieties/${id}`, {
            name,
            specie_id,
        });
    } catch (error) {
        throw error;
    }
};

export const deleteVariety = async (id) => {
    try {
        return await instance.delete(`/varieties/${id}`);
    } catch (error) {
        throw error;
    }
};

import { instance } from "./axios";
import { formatDate } from "../utils/formatDate";

export const getInstruments = async () => {
  try {
    return await instance.get("/instruments");
  } catch (error) {
    throw error;
  }
};

export const createInstrument = async (
  name,
  verified_at,
  next_verification,
  instrument_type
) => {
  try {
    const data = {
      name,
      verified_at: verified_at ? formatDate(new Date(verified_at)) : null,
      next_verification: next_verification
        ? formatDate(new Date(next_verification))
        : null,
      instrument_type,
    };

    return await instance.post("/instruments", data);
  } catch (error) {
    throw error;
  }
};

export const updateInstrument = async (
  id,
  name,
  verified_at,
  next_verification,
  instrument_type
) => {
  try {
    const data = {
      name,
      verified_at: verified_at ? formatDate(new Date(verified_at)) : null,
      next_verification: next_verification
        ? formatDate(new Date(next_verification))
        : null,
      instrument_type,
    };

    return await instance.put(`/instruments/${id}`, data);
  } catch (error) {
    throw error;
  }
};

export const deleteInstrument = async (id) => {
  try {
    return await instance.delete(`/instruments/${id}`);
  } catch (error) {
    throw error;
  }
};

export const getInstrumentTypes = async () => {
  try {
    return await instance.get("/instrument-types");
  } catch (error) {
    throw error;
  }
};

export const createInstrumentType = async (name) => {
  try {
    return await instance.post("/instrument-types", { name });
  } catch (error) {
    throw error;
  }
};

export const updateInstrumentType = async (id, name) => {
  try {
    return await instance.put(`/instrument-types/${id}`, { name });
  } catch (error) {
    throw error;
  }
};

export const deleteInstrumentType = async (id) => {
  try {
    return await instance.delete(`/instrument-types/${id}`);
  } catch (error) {
    throw error;
  }
};

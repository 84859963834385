import jsPDF from "jspdf";
import { getVisitImageUrl } from "../utils/getImageUrl";
import { formatTime, formatDateWithoutTime } from "../utils/formatDate";
import { splitLines } from "../utils/pdfHelpers";
import autoTable from "jspdf-autotable";

export const createCommercialReport = (visit) => {
  const aspackLogo = getVisitImageUrl("Logo+AsPack+2020.jpg");
  const imageWidth = 25;
  const margin = 10;
  const fontFace = "helvetica";
  const doc = new jsPDF("", "", "", true);
  const franjaWidth = doc.internal.pageSize.width - 2 * margin;
  let yPosition = 20;

  const setSubTitleFont = () => {
    doc.setFontSize(9);
    doc.setFont(fontFace, "bold");
    doc.setTextColor(28, 46, 122);
  };

  const setNormalFont = () => {
    doc.setFontSize(9);
    doc.setFont(fontFace, "normal");
    doc.setTextColor(0, 0, 0);
  };

  doc.addImage(
    visit.brand_logo,
    "JPEG",
    margin,
    yPosition,
    imageWidth,
    imageWidth
  );
  doc.addImage(
    visit.customer_logo,
    "JPEG",
    doc.internal.pageSize.width / 2 - imageWidth / 2,
    yPosition,
    imageWidth,
    imageWidth
  );

  const rightMargin = doc.internal.pageSize.width - 40;
  doc.addImage(aspackLogo, "JPEG", rightMargin, yPosition, 35, 15);

  const evaluateNewPage = () => {
    const spaceLeft = doc.internal.pageSize.height - yPosition;
    if (spaceLeft < margin) {
      doc.addPage();
      yPosition = margin;
    }
  };

  // PPAL TITLE
  doc.setFillColor(28, 46, 122);
  doc.rect(margin, 50, doc.internal.pageSize.width - 2 * margin, 15, "F");
  doc.setFontSize(24);
  doc.setTextColor(255, 255, 255);
  doc.text("REPORTE", doc.internal.pageSize.width / 2, 60.5, "center");
  yPosition = 80;

  setNormalFont();

  /* Visit general data */
  const colOnetexts = [
    "Tema",
    "Marca",
    "Cliente",
    "Lugar",
    "Fecha Visita",
    "Coordinador",
    "Contacto",
  ];

  colOnetexts.forEach((text, index) => {
    doc.text(text, margin, yPosition + index * 5);
  });

  const colTwoTexts = [
    "Visita Comercial",
    visit.brand_name,
    visit.customer_name,
    visit.place_name,
    formatDateWithoutTime(new Date(visit.date_of_visit)),
    `${visit.user_name} ${visit.user_last_name}`,
    visit.person_in_charge,
  ];

  colTwoTexts.forEach((text, index) => {
    doc.text(text, 50, yPosition + index * 5);
  });

  const colThreeTexts = [
    "Fecha Reporte",
    "Coordenadas",
    "Hora Inicio Visita",
    "Hora Término Visita",
    "Número de reporte",
    "Especie",
  ];

  colThreeTexts.forEach((text, index) => {
    doc.text(text, 120, yPosition + index * 5);
  });

  const endData = JSON.parse(visit.end_data);
  const latitude = endData.coords.latitude.toFixed(6).substring(0, 10);
  const longitude = endData.coords.longitude.toFixed(6).substring(0, 10);

  const colFourTexts = [
    formatDateWithoutTime(new Date()),
    `${latitude}, ${longitude}`,
    formatTime(new Date(visit.start_time)),
    formatTime(new Date(visit.end_time)),
    `${visit.id} `,
    visit.specie_name,
  ];

  colFourTexts.forEach((text, index) => {
    doc.text(text, 160, yPosition + index * 5);
  });
  yPosition += 40;
  /* End Visit general data */

  /* Attendees section */
  let attendees = "";
  if (visit.attendees) {
    doc.setFont(fontFace, "bold");
    doc.text("Asistentes", margin, yPosition);

    yPosition += 5;
    doc.setFont(fontFace, "normal");

    visit.attendees.forEach((attendee, i) => {
      attendees += `${attendee.name} (${attendee.position})`;
      if (i < visit.attendees.length - 1) attendees += ", ";
    });
    attendees = splitLines(attendees, doc, franjaWidth);

    doc.text(attendees, margin, yPosition);
  }
  /* End Attendees section */
  if (visit.purpose) {
    yPosition += attendees.length * 10;
    doc.setFillColor(28, 46, 122);
    doc.rect(margin, yPosition, franjaWidth, 1, "F");

    /* Purpose Section*/
    yPosition += 10;
    setSubTitleFont();
    doc.text("I- Objetivos de la reunión:", margin, yPosition);

    yPosition += 5;

    const purpose = splitLines(visit.purpose, doc, franjaWidth);
    setNormalFont();
    purpose.forEach((line) => {
      evaluateNewPage();
      doc.text(line, margin, yPosition);
      yPosition += 5;
    });
  }

  /* End Purpose Section*/
  if (visit.topics) {
    yPosition += 5;
    doc.setFillColor(28, 46, 122);
    doc.rect(margin, yPosition, franjaWidth, 1, "F");

    /* Topics Section*/
    const topics = splitLines(visit.topics, doc, franjaWidth);
    yPosition += 10;
    setSubTitleFont();
    evaluateNewPage();
    doc.text("II- Desarrollo de la reunión:", margin, yPosition);

    yPosition += 5;
    setNormalFont();
    topics.forEach((line) => {
      evaluateNewPage();
      doc.text(line, margin, yPosition);
      yPosition += 5;
    });
  }
  /* End Topics Section*/

  yPosition += 5;
  /* Commitments Section*/

  if (visit.commitments.length > 0) {
    doc.setFillColor(28, 46, 122);
    doc.rect(margin, yPosition, franjaWidth, 1, "F");
    yPosition += 10;
    setSubTitleFont();
    evaluateNewPage();
    doc.text("III- Siguientes pasos:", margin, yPosition);
    evaluateNewPage();

    yPosition += 5;
    setNormalFont();

    const tableBody = visit.commitments.map((commitment) => [
      `${commitment.name} ${commitment.last_name}`,
      commitment.activity,
      formatDateWithoutTime(new Date(commitment.deadline)),
    ]);

    autoTable(doc, {
      startY: yPosition,
      head: [["Responsable", "Actividad", "Fecha límite"]],
      body: tableBody,
      styles: {
        cellPadding: 1,
        fontSize: 9,
        valign: "middle",
        halign: "center",
        fillColor: false,
        textColor: [0, 0, 0],
      },
      margin: margin,
      didParseCell: function (data) {
        if (data.cell.section !== "head") {
          data.cell.styles.halign = "left"; // Align body text to right
          data.cell.styles.fillColor = false;
        }
      },
    });
  }
  /* End Commitments Section*/

  const fileName = `Visita_Comercial-${visit.specie_name}-${visit.id}-${visit.customer_name}-${visit.place_name}.pdf`;
  doc.save(fileName);
};

import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { downloadPDF } from "./pdfFormat";
import { Oval } from "react-loader-spinner";

const PdfDownload = ({ visit, subComponentData }) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const handleDownloadPDF = async () => {
    await downloadPDF(visit, subComponentData);
    setTimeout(() => {
      setIsDownloading(false);
    }, 1000);
  };

  useEffect(() => {
    if (isDownloading)
      setTimeout(() => {
        handleDownloadPDF();
      }, 1000);
    // eslint-disable-next-line
  }, [isDownloading]);

  return (
    <>
      {isDownloading ? (
        <div style={{ padding: 10 }}>
          <Oval visible={true} height="40" width="40" color="#0078d4" />
        </div>
      ) : (
        <Button onClick={() => setIsDownloading(true)} label="Descargar PDF" />
      )}
    </>
  );
};

export default PdfDownload;

import axios from "axios";

const BASE_URL = process.env.REACT_APP_HOST;

export const loginUser = async (email, password) => {
  try {
    const { data } = await axios.post(`${BASE_URL}/auth`, {
      email,
      password,
    });
    return data;
  } catch (error) {
    throw error;
  }
};
export const changePassword = async (id, payload) => {
  try {
    const { data } = await axios.put(`${BASE_URL}/auth/${id}`, payload);
    return data;
  } catch (error) {
    throw error;
  }
};
export const replacePassword = async (id, payload) => {
  try {
    const { data } = await axios.put(`${BASE_URL}/auth/replace/${id}`, payload);
    return data;
  } catch (error) {
    throw error;
  }
};

import { Dialog } from "primereact/dialog";
import { changePassword } from "../api/auth";
import { Button } from "primereact/button";
import React, { useState } from "react";
import { Toast } from "primereact/toast";

export default function USerPassword(props) {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const toast = React.useRef(null);
  const showToast = (severity, summary, detail) => {
    toast.current.show({ severity, summary, detail });
  };

  const handleChangePassword = async () => {
    try {
      const id = localStorage.getItem("id");
      if (newPassword !== confirmPassword) {
        showToast("error", "Error!", "Las claves no coinciden");
        return;
      }
      const payload = {
        oldPassword,
        newPassword,
      };
      await changePassword(id, payload);
      showToast("success", "Listo!", "Clave actualizada!");
    } catch (e) {
      console.log(e);
      showToast("error", "Error!", e.response.data.error);
    }
  };
  return (
    <Dialog
      visible={props.visible}
      onHide={props.hideDialog}
      header="Cambiar Clave"
    >
      <div className="p-fluid">
        <div className="p-field">
          <label htmlFor="current_password">Clave Actual</label>
          <input
            id="current_password"
            type="password"
            className="p-inputtext p-component"
            onChange={(e) => setOldPassword(e.target.value)}
          />
        </div>
        <div className="p-field">
          <label htmlFor="new_password">Nueva Clave</label>
          <input
            id="new_password"
            type="password"
            className="p-inputtext p-component"
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </div>
        <div className="p-field">
          <label htmlFor="confirm_password">Confirmar Clave</label>
          <input
            id="confirm_password"
            type="password"
            className="p-inputtext p-component"
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </div>
        <Button
          label="Cambiar Clave"
          onClick={handleChangePassword}
          className="p-mb-2"
        />
      </div>
      <Toast ref={toast} />
    </Dialog>
  );
}

import React, { useState, useEffect } from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { getVarieties } from "../api/variety";
import { updateRawMaterialChamber } from "../api/rawMaterialChamber";
import { formatDate } from "../utils/formatDate";
import { InputTextarea } from "primereact/inputtextarea";

const RawMaterialChamberPanel = ({ rawMaterialChamber, onChange }) => {
  const [varieties, setVarieties] = useState([]);
  const [selectedMeasure, setSelectedMeasure] = useState(null);
  const [originalMeasure, setOriginalMeasure] = useState(null);
  const [displayModal, setDisplayModal] = useState(false);
  const [sortOrder, setSortOrder] = useState(1);

  useEffect(() => {
    loadVarieties();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadVarieties = async () => {
    try {
      const response = await getVarieties();
      setVarieties(response.data);
    } catch (error) {
      console.error("Error fetching varieties:", error);
    }
  };

  const handleInputChange = (field, value) => {
    setSelectedMeasure((prevMeasure) => ({
      ...prevMeasure,
      [field]: value,
    }));
  };

  const showToast = (severity, summary, detail) => {
    toast.current.show({ severity, summary, detail });
  };

  const toast = React.useRef(null);

  const handleUpdateClick = () => {
    if (selectedMeasure && selectedMeasure.id) {
      const formattedMeasure = {
        ...selectedMeasure,
        harvest_date: formatDate(new Date(selectedMeasure.harvest_date)),
      };

      updateRawMaterialChamber(formattedMeasure, selectedMeasure.id)
        .then(() => {
          const updatedMeasurements = [
            ...rawMaterialChamber.filter((m) => m.id !== selectedMeasure.id),
            selectedMeasure,
          ];
          const sortedMeasurements = updatedMeasurements.sort(
            (a, b) => sortOrder * (a.id - b.id)
          );
          onChange(sortedMeasurements);
          setDisplayModal(false);
          showToast("success", "Éxito", "Medición actualizada correctamente");
        })
        .catch((error) => {
          console.error("Error al actualizar RawMaterialChamber:", error);
          showToast("error", "Error", "Error al actualizar la medición");
        });
    }
  };

  const openModal = (measure) => {
    setSelectedMeasure(measure);
    setOriginalMeasure({ ...measure });
    setDisplayModal(true);
  };

  const closeModal = () => {
    setSelectedMeasure(null);
    if (originalMeasure) {
      setSelectedMeasure({ ...originalMeasure });
      setOriginalMeasure(null);
    }
    setDisplayModal(false);
  };

  return (
    <div>
      <Accordion>
        <AccordionTab header="Materia Prima">
          <DataTable
            value={rawMaterialChamber}
            selectionMode="single"
            selection={selectedMeasure}
            onSelectionChange={(e) => openModal(e.value)}
            onSort={(e) => setSortOrder(e.sortOrder)}
          >
            <Column field="room_code" header="Cod Cámara"></Column>
            <Column
              field="room_temperature"
              header="Temperatura Cámara"
            ></Column>
            <Column
              field="pulp_temperature"
              header="Temperatura Pulpa"
            ></Column>
            <Column field="antiquity" header="Antiguedad"></Column>
            <Column field="comment" header="Comentario" />
          </DataTable>

          <Dialog
            visible={displayModal}
            header={"Editar Cámara de Materia Prima"}
            style={{ width: "50vw" }}
            onHide={closeModal}
          >
            <div>
              <div className="field-title">Código de cámara</div>
              <InputText
                value={selectedMeasure?.room_code || ""}
                onChange={(e) => handleInputChange("room_code", e.target.value)}
                placeholder="Código de cámara"
              />
              <div className="field-title">Temperatura de cámara</div>
              <InputText
                value={selectedMeasure?.room_temperature || ""}
                onChange={(e) =>
                  handleInputChange("room_temperature", e.target.value)
                }
                placeholder="Temperatura de cámara"
              />
              <div className="field-title">Temperatura de Pulpa</div>
              <InputText
                value={selectedMeasure?.pulp_temperature || ""}
                onChange={(e) =>
                  handleInputChange("pulp_temperature", e.target.value)
                }
                placeholder="Temperatura de Pulpa"
              />
              <div className="field-title">Velocidad Mínima del Viento</div>
              <InputText
                value={selectedMeasure?.min_wind_speed || ""}
                onChange={(e) =>
                  handleInputChange("min_wind_speed", e.target.value)
                }
                placeholder="Velocidad Mínima del Viento"
              />
              <div className="field-title">Velocidad Máxima del Viento</div>
              <InputText
                value={selectedMeasure?.max_wind_speed || ""}
                onChange={(e) =>
                  handleInputChange("max_wind_speed", e.target.value)
                }
                placeholder="Velocidad Máxima del Viento"
              />
              <div className="field-title">Humedad relativa</div>
              <InputText
                value={selectedMeasure?.relative_humidity || ""}
                onChange={(e) =>
                  handleInputChange("relative_humidity", e.target.value)
                }
                placeholder="Humedad relativa"
              />
              <div className="field-title">Antiguedad</div>
              <InputText
                value={selectedMeasure?.antiquity || ""}
                onChange={(e) => handleInputChange("antiquity", e.target.value)}
                placeholder="Antiguedad"
              />
              <div className="field-title">Variedad</div>
              <Dropdown
                value={selectedMeasure?.variety_id || ""}
                options={varieties}
                onChange={(e) => handleInputChange("variety_id", e.value)}
                placeholder="Variedad"
                optionLabel="name"
                optionValue="id"
              />
              <div className="field-title">Fecha de cosecha</div>
              <Calendar
                value={
                  selectedMeasure?.harvest_date
                    ? new Date(selectedMeasure.harvest_date)
                    : null
                }
                onChange={(e) => handleInputChange("harvest_date", e.value)}
                placeholder="Fecha de cosecha"
                dateFormat="dd/mm/yy"
              />
              <div className="field-title">Comentario</div>
              <InputTextarea
                autoResize={true}
                value={selectedMeasure?.comment || ""}
                onChange={(e) => handleInputChange("comment", e.target.value)}
                placeholder="Comentario"
              />
              <div className="p-mt-2">
                <Button
                  label="Guardar"
                  icon="pi pi-check"
                  onClick={handleUpdateClick}
                />
              </div>
            </div>
          </Dialog>
        </AccordionTab>
      </Accordion>
      <Toast ref={toast} />
    </div>
  );
};

export default RawMaterialChamberPanel;

import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { generateBlob } from "./pdfFormat";
import { Oval } from "react-loader-spinner";

const PdfBlob = ({
  visit,
  subComponentData,
  onBlobGenerated,
  filterContacts,
}) => {
  const [isGenerating, setIsGenerating] = useState(false);

  useEffect(() => {
    if (isGenerating)
      setTimeout(() => {
        handleGenerateBlob();
      }, 1000);
    // eslint-disable-next-line
  }, [isGenerating]);

  const handleGenerateBlob = async () => {
    setIsGenerating(true);
    const blob = await generateBlob(visit, subComponentData);
    onBlobGenerated(blob);
    filterContacts();
    setTimeout(() => {
      setIsGenerating(false);
    }, 1000);
  };

  if (isGenerating)
    return (
      <div style={{ padding: 10 }}>
        <Oval visible={true} height="40" width="40" color="#0078d4" />
      </div>
    );

  return (
    <Button onClick={() => setIsGenerating(true)} label="Enviar por Correo" />
  );
};

export default PdfBlob;

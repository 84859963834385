import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { formatDateWithoutTime, formatTime } from "./formatDate";
import { getVisitImageUrl } from "./getImageUrl";
import { splitLines } from "./pdfHelpers";
const generateCommon = (doc, visit, subComponentData) => {
  const fontFace = "helvetica";
  const titlesStyle = {
    fillColor: [220, 230, 242],
    textColor: [0, 0, 0],
    fontSize: 9,
  };

  const lineHeight = 5;

  if (visit) {
    // CÓDIGO RELACIONADO A LA CABECERA DEL DOCUMENTO (LOGOS DE EMPRESA Y TÍTULO DE REPORTES)
    const margin = 15;
    const franjaWidth = doc.internal.pageSize.width - 2 * margin;
    const customerLogo = visit.customer_logo || "";
    const userPhoto = visit.user_photo || "";
    const separatorHeight = 1;
    const aspackLogo = getVisitImageUrl("Logo+AsPack+2020.jpg");
    const imageWidth = 25;

    doc.addImage(visit.brand_logo, "JPEG", margin, 20, imageWidth, imageWidth);

    doc.addImage(
      customerLogo,
      "JPEG",
      doc.internal.pageSize.width / 2 - imageWidth / 2,
      20,
      imageWidth,
      imageWidth
    );

    doc.addImage(
      aspackLogo,
      "JPEG",
      doc.internal.pageSize.width - margin - 34,
      23,
      35,
      15
    );

    doc.setFillColor(28, 46, 122);
    doc.rect(margin, 50, doc.internal.pageSize.width - 2 * margin, 15, "F");
    doc.setFontSize(24);
    doc.setTextColor(255, 255, 255);
    doc.text("REPORTE", doc.internal.pageSize.width / 2, 60.5, "center");

    // FIN DEL CÓDIGO RELACIONADO A LA CABECERA

    // AQUÍ COMIENZA EL CÓDIGO RELACIONADO A LOS DATOS QUE APARECEN ABAJO DEL TÍTULO (TODOS SON DATOS DE LA VISITA)
    doc.setFontSize(9);
    doc.setTextColor(0, 0, 0);

    const column1Titles = [
      "Tema",
      "Cliente",
      "Lugar",
      "Fecha Visita",
      "Especie",
      "Persona a Cargo",
    ];
    const column2Titles = [
      "Fecha Reporte",
      "Marca",
      "Coordenadas",
      "Hora Inicio Visita",
      "Hora Término Visita",
      "Número de reporte",
    ];
    const column1Data = [
      visit.visit_type_name || "",
      visit.customer_name || "",
      visit.place_name || "",
      formatDateWithoutTime(new Date(visit.date_of_visit)) || "",
      visit.specie_name || "",
      visit.person_in_charge || "",
    ];
    const endData = JSON.parse(visit.end_data);
    const latitude = endData.coords.latitude.toFixed(6).substring(0, 10);
    const longitude = endData.coords.longitude.toFixed(6).substring(0, 10);

    const column2Data = [
      formatDateWithoutTime(new Date()) || "",
      visit.brand_name || "",
      `${latitude}, ${longitude}`,
      formatTime(new Date(visit.start_time)) || "",
      formatTime(new Date(visit.end_time)) || "",
      visit.id.toString() || "",
    ];

    // Calcular el ancho de cada columna
    const columnWidth = (doc.internal.pageSize.width - 2 * margin) / 4;

    // Definir la posición inicial de las columnas
    let column1X = margin;
    let column2X = column1X + columnWidth - 11;
    let column3X = column2X + columnWidth + 18;
    let column4X = column3X + columnWidth - 5;

    // Definir la posición inicial en y
    let yPosition = 75;

    const evaluateNewPage = () => {
      const spaceLeft = doc.internal.pageSize.height - yPosition;
      if (spaceLeft < margin) {
        doc.addPage();
        yPosition = margin;
      }
    };

    // Iterar sobre los títulos y datos para agregarlos a las columnas
    column1Titles.forEach((title, index) => {
      doc.text(title, column1X, yPosition);
      doc.text(column1Data[index], column2X, yPosition);
      doc.text(column2Titles[index], column3X, yPosition);
      doc.text(column2Data[index], column4X, yPosition);
      yPosition += 5;
    });

    if (userPhoto) {
      doc.addImage(userPhoto, "JPEG", 49, 125, 22, 20);
    }

    yPosition += 10;
    doc.setFont(fontFace, "bold");
    doc.text("Inspector", margin, yPosition - 1);
    doc.text("Instrumentos", 112, yPosition - 1);
    doc.setFont(fontFace, "normal");

    doc.text(`${visit.user_name} ${visit.user_last_name}`, 49, yPosition);
    yPosition += 5;
    doc.text(`${visit.user_email}`, 49, yPosition);

    // Agrupar los instrumentos por tipo
    const instrumentsByType = subComponentData.Metrology.reduce(
      (acc, instrument) => {
        if (!acc[instrument.instrument_type]) {
          acc[instrument.instrument_type] = [];
        }
        acc[instrument.instrument_type].push(instrument.instrument_name);
        return acc;
      },
      {}
    );

    Object.keys(instrumentsByType).forEach((type, index) => {
      const instruments = instrumentsByType[type];
      doc.text(`${type}`, 112, yPosition);
      instruments.forEach((instrument) => {
        doc.text(`${instrument}`, 157.5, yPosition);
        yPosition += 3;
      });
      yPosition += 3; // Añadir espacio entre tipos de instrumentos
    });

    yPosition += 20;

    const addCellWithValidation = (value, minLimit, maxLimit, label) => {
      // ESTA VARIABLE TIENE LA FUNCIÓN DE ENCONTRAR LOS ERRORES DE LAS MEDICIONES Y PINTAR LA FILA AFECTADA.
      const numericValue = parseFloat(value);

      const cellStyles = {
        fillColor: [...defaultFillColor],
        textColor: [0, 0, 0],
        hasError: false,
      };

      if (!isNaN(numericValue)) {
        if (
          (minLimit !== null && numericValue < minLimit) ||
          (maxLimit !== null && numericValue > maxLimit)
        ) {
          cellStyles.fillColor = [...errorFillColor];
          cellStyles.hasError = true;
        }

        if (minLimit !== null && numericValue < minLimit) {
          label = `!${label}`;
          cellStyles.textColor = [255, 0, 0];
        }

        if (maxLimit !== null && numericValue > maxLimit) {
          label = `!${label}`;
          cellStyles.textColor = [255, 0, 0];
        }
      }

      return { content: `${value} ${label}`, styles: cellStyles };
    };

    const errorFillColor = [252, 233, 217];
    const defaultFillColor = [255, 255, 255];

    // AQUÍ TERMINA EL CÓDIGO RELACIONADO A LOS DATOS PRINCIPALES DE LA VISITA

    // AQUÍ COMIENZA EL CÓDIGO DE LA PRIMERA TABLA (DE MEDICIÓN DE GASES)

    doc.setFillColor(28, 46, 122);
    doc.rect(margin, yPosition, franjaWidth, separatorHeight, "F");
    if (
      subComponentData.Measurements &&
      subComponentData.Measurements.length > 0
    ) {
      yPosition += 10;
      const measurementsTitle = "I- Medición de gases:";
      doc.setFont(fontFace);
      doc.setTextColor(28, 46, 122); //ACÁ CORRESPONDE AL TÍTULO QUE SE MUESTRA SOBRE LA TABLA
      doc.setFontSize(9);
      doc.text(measurementsTitle, margin, yPosition);
      yPosition += 10;

      const measurements = subComponentData.Measurements || [];
      const palletNumbers = Array.from(
        new Set(measurements.map((measurement) => measurement.pallet))
      );

      const measurementLimits = {
        // ESTA VARIABLE TIENE LA FUNCIÓN DE MOSTRAR LOS LÍMITES DE LAS MEDICIONES.
        min_o2: visit.min_o2,
        max_o2: visit.max_o2,
        min_co2: visit.min_co2,
        max_co2: visit.max_co2,
        min_pulp_temperature: visit.min_pulp_temperature,
        max_pulp_temperature: visit.max_pulp_temperature,
      };
      let totalMeasurementCount = 1;
      palletNumbers.forEach((palletNumber, rowIndex) => {
        // ESTA VARIABLE TIENE LA FUNCIÓN DE MOSTRAR LOS DATOS DE LAS MEDICIONES, COMENZANDO POR EL ENCABEZADO DE LA TABLA.
        const tableData = [
          [
            {
              content: "Caja",
              styles: titlesStyle,
            },
            {
              content: "Código \n producto",
              styles: titlesStyle,
            },
            {
              content: "Fecha \n embalaje",
              styles: titlesStyle,
            },
            {
              content: "Días \n sellado",
              styles: titlesStyle,
            },
            {
              content: "%CO2",
              styles: titlesStyle,
            },
            {
              content: "%O2",
              styles: titlesStyle,
            },
            {
              content: "T° pulpa",
              styles: titlesStyle,
            },
            {
              content: "Ubicación \n en pallet",
              styles: titlesStyle,
            },
          ],
        ];

        const firstMeasurement = measurements.find(
          (measurement) => measurement.pallet === palletNumber
        );

        if (firstMeasurement) {
          doc.setFont(fontFace, "normal");
          doc.setTextColor(0, 0, 0);
          doc.setFontSize(9);
          let tableTopData = `Cámara ${firstMeasurement.room_code} `;
          if (firstMeasurement.room_temperature)
            tableTopData += ` - T° Amb.: ${firstMeasurement.room_temperature}°C`;
          if (firstMeasurement.pallet)
            tableTopData += ` / Pallet N°: ${palletNumber}`;
          if (firstMeasurement.variety_name)
            tableTopData += ` / Var: ${firstMeasurement.variety_name}`;
          if (firstMeasurement.kilograms)
            tableTopData += ` / Formato: ${firstMeasurement.kilograms}`;

          doc.text(tableTopData, margin, yPosition - 1, "left");

          let windText = "";
          const windLabel = "Vel. Viento:";
          if (
            firstMeasurement.min_wind_speed &&
            firstMeasurement.max_wind_speed
          ) {
            windText = `${windLabel} ${firstMeasurement.min_wind_speed} a ${firstMeasurement.max_wind_speed} (m/s)`;
          }

          if (
            firstMeasurement.min_wind_speed &&
            !firstMeasurement.max_wind_speed
          ) {
            windText = `${windLabel} ${firstMeasurement.min_wind_speed} (m/s)`;
          }

          if (
            !firstMeasurement.min_wind_speed &&
            firstMeasurement.max_wind_speed
          ) {
            windText = `${windLabel} ${firstMeasurement.max_wind_speed} (m/s)`;
          }
          if (windText.length > 0) {
            yPosition += 4;
            tableTopData += doc.text(windText, margin, yPosition - 1, "left");
          }
        }

        const palletMeasurements = measurements.filter(
          (measurement) => measurement.pallet === palletNumber
        );

        palletMeasurements.forEach((measurement) => {
          console.log(measurement.documents);
          const haveImages =
            measurement.documents !== "[]" && measurement.documents.length > 0; // ESTA ES UNA VALIDACIÓN PARA MOSTRAR EL * SI ES QUE LA MEDICIÓN TIENE UNA IMAGEN ASOCIADA.
          const rowData = [
            {
              content: `${totalMeasurementCount}${haveImages ? "*" : ""}`,
              styles: { fillColor: [255, 255, 255], textColor: [0, 0, 0] },
            },
            addCellWithValidation(measurement.product_code, null, null, ""),
            addCellWithValidation(
              measurement.packaging_date
                ? formatDateWithoutTime(new Date(measurement.packaging_date))
                : "",
              null,
              null,
              ""
            ),
            addCellWithValidation(measurement.sealing_days, null, null, ""), // AQUÍ SE ESTÁ RELLENANDO LA TABLA CON LOS DATOS CORRESPONDIENTES (UNA FILA A LA VEZ) DEBAJO DE LAS CABECERAS DEFINIDAS ANTERIORMENTE.
            addCellWithValidation(
              measurement.co2,
              measurementLimits.min_co2,
              measurementLimits.max_co2,
              ""
            ),
            addCellWithValidation(
              measurement.o2,
              measurementLimits.min_o2,
              measurementLimits.max_o2,
              ""
            ),
            addCellWithValidation(
              measurement.pulp_temperature,
              measurementLimits.min_pulp_temperature,
              measurementLimits.max_pulp_temperature,
              ""
            ),
            addCellWithValidation(measurement.box_ubication, null, null, ""),
          ];

          const rowHasError = rowData.some((cell) => cell.styles.hasError);

          if (rowHasError) {
            tableData.push(
              rowData.map((cell) => ({
                ...cell,
                styles: { ...cell.styles, fillColor: errorFillColor }, // AQUÍ SE CEREA UNA FILA DE COLOR ROJO SI ES QUE HAY UN ERROR EN ALGUNA MEDICIÓN.
              }))
            );
          } else {
            tableData.push(rowData);
          }

          totalMeasurementCount += 1;
        });

        let initialYPosition = yPosition;

        const table = {
          // ACÁ SE ARMA LA TABLA EN SÍ, GRACIAS A TODOS LOS DATOS ANTERIORMENTE DESCRITOS.
          startY: yPosition,
          body: tableData,
          styles: {
            cellPadding: 1,
            fontSize: 9,
            valign: "middle",
            halign: "center",
          },
          margin: margin,
        };

        autoTable(doc, table); // AQUÍ SE AGREGA LA TABLA AL DOCUMENTO.

        yPosition = parseInt(initialYPosition);

        const heightTable = parseInt(doc.lastAutoTable.finalY - yPosition); // CÁLCULO DE LA ALTURA DE LA ÚLTIMA TABLA CREADA

        if (palletMeasurements.length === 1) {
          // AQUÍ SE SACA LA DISTANCIA QUE DEBE HABER ENTRE TABLAS
          yPosition += heightTable * 2;
        } else {
          yPosition += heightTable + 10;
        }

        const spaceLeft = parseInt(doc.internal.pageSize.height - yPosition);
        if (spaceLeft < 50) {
          // ESTO LO QUE HACE ES QUE IMPIDE QUE EL DOCUMENTO SE SALTE HOJAS INNECESARIAMENTE, POR LO QUE ACORTO UN POCO LA ALTURA DE LA HOJA EVITANDO DESCUADRES INDESEADOS.
          doc.addPage();
          yPosition = margin;
        }
      });

      let finalYPosition = yPosition; // ESTA ES LA POSICIÓN FINAL DESPUÉS DE QUE SE HAYA CREADO LA ÚLTIMA TABLA, DE AQUÍ TOMAN REFERENCIA LOS ELEMENTOS QUE VIENEN MÁS ABAJO

      doc.setFontSize(9);
      doc.setTextColor(0, 0, 0);
      doc.setFont(fontFace, "normal");
      doc.text(
        // AQUÍ ESTÁ EL TEXTO SOLICITADO PARA GUIAR AL USUARIO SOBRE EL SIGNIFICADO DE LOS ASTERISCOS
        "Notas: \n 1. La ubicación de la caja en el pallet, hace referencia a la altura de la caja en el mismo. Superior (tercio superior), \n Centro (Tercio central) e Inferior (Tercio Inferior).\n 2. Los valores indicados en rojo, son aquellos que están por debajo o sobre el rango esperado o recomendado. \n 3. Los números de caja que presentan un (*), cuentan con fotografías asociadas. Ubicadas al final del informe.",
        margin,
        finalYPosition
      );
      yPosition = finalYPosition;
      doc.setFont(fontFace, "normal");

      yPosition += 20;
      doc.setFillColor(28, 46, 122);
      doc.rect(margin, yPosition, franjaWidth, separatorHeight, "F"); // ESTAS SON LAS FRANJAS DE SEPARACIÓN QUE SE ENCUENTRAN EN TODAS LAS TABLAS DEL DOCUMENTO.
      yPosition += 10;

      doc.setTextColor(0, 0, 0);
      doc.setFontSize(9);
    }

    const visitCommentsMapping = [
      // AQUÍ FILTRO LOS COMENTARIOS NECESARIOS PARA ESTA PRIMERA TABLA
      { key: "conclusions", title: "Conclusiones:" },
      { key: "recommendations", title: "Recomendaciones:" },
      {
        key: "other_comments",
        title: "Otros comentarios:",
      },
    ];

    visitCommentsMapping.forEach(({ key, title }) => {
      // Y AQUÍ ESTOY MOSTRANDO CADA UNO DE ELLOS CUIDANDO TAMBIÉN EL EVITAR LOS DESCUADRES.
      const comment = visit[key] || "";
      if (comment.trim() !== "") {
        const commentsBlock = `${title}\n- ${comment}`;
        const lines = splitLines(commentsBlock, doc, franjaWidth);
        yPosition += lineHeight;

        lines.forEach((line, index) => {
          if (index === 0 && line.endsWith(":")) {
            doc.setTextColor(28, 46, 122);
            doc.setFont(fontFace, "normal");
          } else {
            doc.setTextColor(0, 0, 0);
            doc.setFont(fontFace, "normal");
          }

          if (yPosition + 5 <= doc.internal.pageSize.height - margin) {
            doc.text(line, margin, yPosition);
            yPosition += lineHeight;
          } else {
            doc.addPage();
            yPosition = margin;
            doc.text(line, margin, yPosition);
            yPosition += lineHeight;
          }
        });
      }
    });
    const spaceLineLeft = parseInt(doc.internal.pageSize.height - yPosition); // ESTO ES LO MISMO QUE EXPLICABA MÁS ARRIBA, EVITA DESCUADRES
    if (spaceLineLeft < 40) {
      doc.addPage();
      yPosition = margin;
    }
    yPosition += lineHeight;

    doc.setFillColor(28, 46, 122);
    doc.rect(margin, yPosition, franjaWidth, separatorHeight, "F"); // LÍNEA DIVISORIA
    yPosition += 10;

    const secondSectionTitlePosition = yPosition;
    let isSecondSectionTitlePrinted = false;

    const printSecondSectionTitle = () => {
      const follow_up =
        "II- Seguimiento a las condiciones del proceso de embalaje:";
      doc.setFont(fontFace);
      doc.setTextColor(28, 46, 122);
      doc.setFontSize(9);
      doc.text(follow_up, margin, secondSectionTitlePosition);
      isSecondSectionTitlePrinted = true;
    };
    yPosition += 10;

    if (visit.packaging_materials_comment) {
      if (!isSecondSectionTitlePrinted) printSecondSectionTitle();

      const preparationMaterial = "Preparación de material";
      doc.setFont(fontFace);
      doc.setTextColor(28, 46, 122);
      doc.setFontSize(9);
      doc.text(preparationMaterial, margin, yPosition);
      doc.rect(margin, yPosition + 1, 35, 0.3, "F");
      yPosition += 10;
      doc.text("Comentario:", margin, yPosition);
      yPosition += lineHeight;
      const packingComment = [
        { key: "packaging_materials_comment", title: "" }, // COMENTARIOS DE PREPARACIÓN DE MATERIAL
      ];

      packingComment.forEach(({ key, title }) => {
        // IMPLEMENTACION DE LOS COMENTARIOS
        const comment = visit[key] || "";
        if (comment.trim() !== "") {
          const commentsBlock = `${title}\n- ${comment}`;
          const lines = splitLines(commentsBlock, doc, franjaWidth);
          lines.forEach((line, index) => {
            if (index === 0 && line.endsWith(":")) {
              doc.setFont(fontFace);
            } else {
              doc.setTextColor(0, 0, 0);
              doc.setFont(fontFace, "normal");
            }
            evaluateNewPage();
            doc.text(line, margin, yPosition);
            yPosition += lineHeight;
          });
        }
      });
      doc.setFillColor(28, 46, 122);
      doc.rect(margin, yPosition, franjaWidth, separatorHeight, "F"); // LÍNEA DIVISORIA
    }

    yPosition += lineHeight;

    const spaceLeft = parseInt(doc.internal.pageSize.height - yPosition);
    if (spaceLeft < 60) {
      doc.addPage();
      yPosition = margin;
    }

    const receptionMeasurements = subComponentData.ReceptionMeasurements || [];
    if (receptionMeasurements.length > 0) {
      if (!isSecondSectionTitlePrinted) printSecondSectionTitle();
      // AQUÍ COMIENZA LA TABLA DE RECEPCIÓN (LA LÓGICA EN MUCHAS COSAS ES SIMILAR A LA ANTERIOR, SOLO QUE AQUÍ PUEDE HABER SOLO UNA TABLA)
      yPosition += lineHeight;
      const receptionMeasurementsTitle = "Patio de recepción";
      doc.setFont(fontFace);
      doc.setTextColor(28, 46, 122);
      doc.setFontSize(9);
      doc.text(receptionMeasurementsTitle, margin, yPosition);
      doc.rect(margin, yPosition + 1, 27, 0.3, "F");
      yPosition += lineHeight;

      const receptionMeasurementsTableData = [];

      receptionMeasurementsTableData.push([
        {
          content: "T° Ambiente (°C)",
          styles: titlesStyle,
        },
        {
          content: "T° pulpa (°C)",
          styles: titlesStyle, // CABECERA DE LA TABLA
        },
        {
          content: "Humedad Relativa (%)",
          styles: titlesStyle,
        },
      ]);

      receptionMeasurements.forEach((measurement) => {
        const rowData = [
          {
            content: measurement.room_temperature,
            styles: { fillColor: [255, 255, 255] },
          },
          addCellWithValidation(measurement.pulp_temperature, null, null, ""),
          addCellWithValidation(measurement.relative_humidity, null, null, ""), // INFORMACIÓN DE LA TABLA
        ];

        receptionMeasurementsTableData.push(rowData);
      });

      let initialYPosition = yPosition;

      const receptionMeasurementsTable = {
        // CREACIÓN Y MONTAJE DE LA TABLA
        startY: yPosition,
        body: receptionMeasurementsTableData,
        styles: {
          cellPadding: 1,
          fontSize: 9,
          valign: "middle",
          halign: "center",
        },
        margin: margin,
      };

      autoTable(doc, receptionMeasurementsTable); // AGREGADO DE LA TABLA AL DOCUMENTO

      yPosition = parseInt(initialYPosition); // SE VUELVE AL INICIO DE LA TABLA RECIÉN CREADA PARA CALCULO DE ALTURA
      const heightTable = parseInt(doc.lastAutoTable.finalY - yPosition); // CÁLCULO DE LA ALTURA DE LA TABLA

      if (receptionMeasurements.length === 1) {
        // CÁLCULO DE LA DISTANCIA ENTRE TABLAS
        yPosition += heightTable * 2;
      } else {
        yPosition += heightTable + 10;
      }

      const spaceLeft = parseInt(doc.internal.pageSize.height - yPosition); // EVITA DESCUADRES
      if (spaceLeft < 60) {
        doc.addPage();
        yPosition = margin;
      }

      doc.setFontSize(9);

      const receptionComments = [
        { key: "reception_measurements_comment", title: "Comentario:" }, // COMENTARIOS DE LA TABLA
      ];

      receptionComments.forEach(({ key, title }) => {
        // AQUÍ SE MONTAN LOS COMENTARIOS DE ESTA TABLA
        const comment = visit[key] || "";
        if (comment.trim() !== "") {
          const commentsBlock = `${title}\n- ${comment}`;
          const lines = splitLines(commentsBlock, doc, franjaWidth);

          lines.length > 1 &&
            lines.forEach((line, index) => {
              evaluateNewPage();
              if (index === 0 && line.endsWith(":")) {
                // Titulo Comentarios:
                doc.text(line, margin, yPosition);
                yPosition += 10;
              } else {
                // Escribe una linea de comentario
                doc.setTextColor(0, 0, 0);
                doc.text(line, margin, yPosition);
                yPosition += lineHeight;
              }
            });
        }
      });

      yPosition += lineHeight;
      doc.setFillColor(28, 46, 122);
      doc.rect(margin, yPosition, franjaWidth, separatorHeight, "F"); // LÍNEA DIVISORIA
      yPosition += 10;

      const spaceLineLeft = parseInt(doc.internal.pageSize.height - yPosition); // EVITA DESCUADRES
      if (spaceLineLeft < 60) {
        doc.addPage();
        yPosition = margin;
      }
    }

    const rawMaterialChamberMeasurements =
      subComponentData.RawMaterialChamber || [];
    if (rawMaterialChamberMeasurements.length > 0) {
      if (!isSecondSectionTitlePrinted) printSecondSectionTitle();
      // AQUÍ COMIENZA LA TABLA DE CÁMARAS DE MATERIA PRIMA
      yPosition += 2;
      const rawMaterialChamberTitle = "Cámara(s) de materia prima";
      doc.setFont(fontFace);
      doc.setTextColor(28, 46, 122);
      doc.setFontSize(9);
      doc.text(rawMaterialChamberTitle, margin, yPosition);
      doc.rect(margin, yPosition + 1, 40, 0.3, "F");
      yPosition += 10;

      const rawMaterialChamberTableData = [];

      rawMaterialChamberTableData.push([
        {
          content: "N° cámara",
          styles: titlesStyle,
        },
        {
          content: "T° amb. (°C)", // CABECERA DE LA TABLA DE MATERIA PRIMA
          styles: titlesStyle,
        },
        {
          content: "T° pulpa (°C)",
          styles: titlesStyle,
        },
        {
          content: "Vel. viento min(m/s)",
          styles: titlesStyle,
        },
        {
          content: "Vel. viento max(m/s)",
          styles: titlesStyle,
        },
        {
          content: "Humedad Rel. (%)",
          styles: titlesStyle,
        },
        {
          content: "Variedad",
          styles: titlesStyle,
        },
        {
          content: "Fecha cosecha",
          styles: titlesStyle,
        },
        {
          content: "Antigüedad (días)",
          styles: titlesStyle,
        },
      ]);

      rawMaterialChamberMeasurements.forEach((measurement, index) => {
        const rowData = [
          addCellWithValidation(measurement.room_code, null, null, ""),
          addCellWithValidation(measurement.room_temperature, null, null, ""),
          addCellWithValidation(measurement.pulp_temperature, null, null, ""),
          addCellWithValidation(measurement.min_wind_speed, null, null, ""),
          addCellWithValidation(measurement.max_wind_speed, null, null, ""),
          addCellWithValidation(measurement.relative_humidity, null, null, ""),
          addCellWithValidation(measurement.variety_name, null, null, ""),
          addCellWithValidation(
            formatDateWithoutTime(new Date(measurement.harvest_date)),
            null,
            null,
            ""
          ),
          addCellWithValidation(measurement.antiquity, null, null, ""),
        ];

        rawMaterialChamberTableData.push(rowData);
      });

      let initialYPosition = yPosition;

      const rawMaterialChamberTable = {
        // CREACIÓN Y MONTAJE DE LA TABLA
        startY: yPosition,
        body: rawMaterialChamberTableData,
        styles: {
          cellPadding: 1,
          fontSize: 9,
          halign: "center",
        },
        margin: margin,
      };

      autoTable(doc, rawMaterialChamberTable); // AGREGADO DE LA TABLA AL DOCUMENTO

      yPosition = parseInt(initialYPosition); // SE VUELVE AL INICIO DE LA TABLA RECIÉN CREADA PARA CALCULO DE ALTURA

      const heightTable = parseInt(doc.lastAutoTable.finalY - yPosition); // CÁLCULO DE LA ALTURA DE LA TABLA

      if (rawMaterialChamberMeasurements.length === 1) {
        // CÁLCULO DE LA DISTANCIA ENTRE TABLAS
        yPosition += heightTable * 2;
      } else {
        yPosition += heightTable + 10;
      }

      const spaceLeft = parseInt(doc.internal.pageSize.height - yPosition); // EVITA DESCUADRES
      if (spaceLeft < 60) {
        doc.addPage();
        yPosition = margin;
      }

      doc.setFontSize(9);

      // Comentario Cámara de materia prima
      const rawMaterialChamberComments = rawMaterialChamberMeasurements
        .map((measurement) => measurement.comment)
        .filter((comment) => comment !== "")
        .filter((comment, index, self) => self.indexOf(comment) === index);

      if (rawMaterialChamberComments.length > 0) {
        doc.setTextColor(28, 46, 122);
        doc.setFont(fontFace, "normal");
        doc.setFontSize(9);
        doc.text("Comentario:", margin, yPosition);
        yPosition += 10;
        doc.setTextColor(0, 0, 0);

        const maxCommentWidth = franjaWidth - margin * 2; // Calculate the maximum width for each comment line
        rawMaterialChamberComments.forEach((comment) => {
          const lines = splitLines(comment, doc, maxCommentWidth);
          lines.forEach((line) => {
            doc.setFont(fontFace, "normal");
            evaluateNewPage();
            doc.text(line, margin, yPosition);
            yPosition += lineHeight;
          });
        });
      }
      yPosition += lineHeight;
      doc.setFillColor(28, 46, 122);
      doc.rect(margin, yPosition, franjaWidth, separatorHeight, "F"); // LÍNEA DIVISORIA
      yPosition += 10;

      const spaceLineLeft = parseInt(doc.internal.pageSize.height - yPosition); // EVITA DESCUADRES
      if (spaceLineLeft < 60) {
        doc.addPage();
        yPosition = margin;
      }
    }

    const packingMeasurements = subComponentData.PackingMeasurement || []; // AQUÍ COMIENZA LA TABLA DE EMBALAJE
    if (packingMeasurements.length > 0) {
      if (!isSecondSectionTitlePrinted) printSecondSectionTitle();
      yPosition += 2;
      const packingMeasurementsTitle = "Packing (cámara de proceso)";
      doc.setFont(fontFace);
      doc.setTextColor(28, 46, 122);
      doc.setFontSize(9);
      doc.text(packingMeasurementsTitle, margin, yPosition);
      doc.rect(margin, yPosition + 1, 42, 0.3, "F");
      yPosition += 10;

      const packingMeasurementsTableData = [];

      packingMeasurementsTableData.push([
        {
          content: "N° línea",
          styles: {
            fillColor: [220, 230, 242],
            textColor: [0, 0, 0],
            fontSize: 10,
          },
        },
        {
          content: "T° ambiente (°C)",
          styles: {
            fillColor: [220, 230, 242],
            textColor: [0, 0, 0],
            fontSize: 10,
          },
        },
        {
          content: "T° pulpa al vaciado (°C)",
          styles: {
            fillColor: [220, 230, 242],
            textColor: [0, 0, 0],
            fontSize: 10,
          },
        },
        {
          content: "T° agua zona de vaciado (°C)",
          styles: {
            fillColor: [220, 230, 242],
            textColor: [0, 0, 0],
            fontSize: 10,
          },
        },
        {
          content: "T° agua zona corta palitos (°C)",
          styles: {
            fillColor: [220, 230, 242],
            textColor: [0, 0, 0],
            fontSize: 10,
          },
        },
        {
          content: "T° fungicida (°C)",
          styles: {
            fillColor: [220, 230, 242],
            textColor: [0, 0, 0],
            fontSize: 10,
          },
        },
        {
          content: "T° pulpa previo al sellado (°C)",
          styles: {
            fillColor: [220, 230, 242],
            textColor: [0, 0, 0],
            fontSize: 10,
          },
        },
      ]);

      packingMeasurements.forEach((measurement) => {
        const rowData = [
          addCellWithValidation(measurement.line_number, null, null, ""),
          addCellWithValidation(measurement.room_temperature, null, null, ""),
          addCellWithValidation(
            measurement.vacuum_pulp_temperature,
            null,
            null,
            ""
          ),
          addCellWithValidation(
            measurement.vacuum_water_temperature,
            null,
            null,
            ""
          ),
          addCellWithValidation(
            measurement.water_cutting_temperature, // INFORMACIÓN DE LA TABLA
            null,
            null,
            ""
          ),
          addCellWithValidation(
            measurement.fungicide_temperature,
            null,
            null,
            ""
          ),
          addCellWithValidation(
            measurement.sealing_pulp_temperature,
            null,
            null,
            ""
          ),
        ];

        packingMeasurementsTableData.push(rowData);
      });

      let initialYPosition = yPosition;

      const packingMeasurementsTable = {
        // CREACIÓN Y MONTAJE DE LA TABLA
        startY: yPosition,
        body: packingMeasurementsTableData,
        styles: {
          cellPadding: 1,
          fontSize: 9,
          valign: "middle",
          halign: "center",
          border: {
            bottom: 0.1,
            color: [0, 0, 0],
          },
        },
        margin: margin,
      };

      autoTable(doc, packingMeasurementsTable); // AGREGADO DE LA TABLA AL DOCUMENTO

      yPosition = parseInt(initialYPosition); // SE VUELVE AL INICIO DE LA TABLA RECIÉN CREADA PARA CALCULO DE ALTURA

      const heightTable = parseInt(doc.lastAutoTable.finalY - yPosition); // CÁLCULO DE LA ALTURA DE LA TABLA

      if (packingMeasurements.length === 1) {
        // CÁLCULO DE LA DISTANCIA ENTRE TABLAS
        yPosition += heightTable * 2;
      } else {
        yPosition += heightTable + 10;
      }

      const spaceLeft = parseInt(doc.internal.pageSize.height - yPosition); // EVITA DESCUADRES
      if (spaceLeft < 60) {
        doc.addPage();
        yPosition = margin;
      }

      // Comentario Packaing Measurements
      const packingMeasurementsComments = packingMeasurements
        .map((measurement) => measurement.comment)
        .filter((comment) => comment !== "")
        .filter((comment, index, self) => self.indexOf(comment) === index);

      if (packingMeasurementsComments.length > 0) {
        const packingMeasurementsCommentsSection = "Comentario:";
        doc.setTextColor(28, 46, 122);
        doc.setFont(fontFace, "normal");
        doc.setFontSize(9);
        doc.text(packingMeasurementsCommentsSection, margin, yPosition);
        yPosition += 10;
        doc.setTextColor(0, 0, 0);

        const maxCommentWidth = franjaWidth - margin * 2; // Calculate the maximum width for each comment line
        packingMeasurementsComments.forEach((comment) => {
          if (!comment) return;
          const lines = splitLines(comment, doc, maxCommentWidth);
          lines.forEach((line) => {
            doc.setFont(fontFace, "normal");
            evaluateNewPage();
            doc.text(line, margin, yPosition);
            yPosition += lineHeight;
          });
        });
      }
      doc.setFontSize(9);

      doc.setFillColor(28, 46, 122);
      doc.rect(margin, yPosition, franjaWidth, separatorHeight, "F"); // LÍNEA DIVISORIA
      yPosition += 10;

      const spaceLineLeft = parseInt(doc.internal.pageSize.height - yPosition); // EVITA DESCUADRES
      if (spaceLineLeft < 60) {
        doc.addPage();
        yPosition = margin;
      }
    }

    const sealingRoomMeasurements =
      subComponentData.SealingRoomMeasurement || []; // AQUÍ COMIENZA LA TABLA DE SELLADO
    if (sealingRoomMeasurements.length > 0) {
      if (!isSecondSectionTitlePrinted) printSecondSectionTitle();
      const sealingRoomTitle = "Sellado";
      doc.setFont(fontFace);
      doc.setTextColor(28, 46, 122);
      doc.setFontSize(9);
      doc.text(sealingRoomTitle, margin, yPosition);
      doc.rect(margin, yPosition + 1, 11, 0.3, "F");
      yPosition += 10;

      const sealingRoomTableData = [];

      sealingRoomTableData.push([
        {
          content: "N°",
          styles: {
            fillColor: [220, 230, 242],
            textColor: [0, 0, 0],
            fontSize: 10,
          },
        },
        {
          content: "T° ambiente",
          styles: {
            fillColor: [220, 230, 242],
            textColor: [0, 0, 0],
            fontSize: 10,
          },
        },
        {
          content: "Humedad Relativa", // CABECERA DE LA TABLA
          styles: {
            fillColor: [220, 230, 242],
            textColor: [0, 0, 0],
            fontSize: 10,
          },
        },
        {
          content: "T° pulpa",
          styles: {
            fillColor: [220, 230, 242],
            textColor: [0, 0, 0],
            fontSize: 10,
          },
        },
        {
          content: "T° seteo máquina selladora",
          colSpan: 2,
          styles: {
            fillColor: [220, 230, 242],
            textColor: [0, 0, 0],
            fontSize: 10,
          },
        },
        {
          content: "Vel. Maquina Selladora",
          colSpan: 2,
          styles: {
            fillColor: [220, 230, 242],
            textColor: [0, 0, 0],
            fontSize: 10,
          },
        },
        {
          content: "Calidad de Sellado",
          styles: {
            fillColor: [220, 230, 242],
            textColor: [0, 0, 0],
            fontSize: 10,
          },
        },
      ]);

      sealingRoomTableData.push([
        {
          content: "Cámara",
          styles: {
            fillColor: [220, 230, 242],
            textColor: [0, 0, 0],
            fontSize: 10,
          },
        },
        {
          content: "(°C)",
          styles: {
            fillColor: [220, 230, 242],
            textColor: [0, 0, 0],
            fontSize: 10,
          },
        },
        {
          content: "(%)",
          styles: {
            fillColor: [220, 230, 242],
            textColor: [0, 0, 0],
            fontSize: 10,
          },
        },
        {
          content: "(°C)",
          styles: {
            fillColor: [220, 230, 242],
            textColor: [0, 0, 0],
            fontSize: 10,
          },
        },
        {
          content: "Mínimo (°C)",
          styles: {
            fillColor: [220, 230, 242],
            textColor: [0, 0, 0],
            fontSize: 10,
          },
        },
        {
          content: "Máximo (°C)",
          styles: {
            fillColor: [220, 230, 242], // SEGUNDA CABECERA DE LA TABLA, DONDE SE ENCUENTRAN LOS DETALLES
            textColor: [0, 0, 0],
            fontSize: 10,
          },
        },
        {
          content: "Mínimo (m/s)",
          styles: {
            fillColor: [220, 230, 242],
            textColor: [0, 0, 0],
            fontSize: 10,
          },
        },
        {
          content: "Máximo (m/s)",
          styles: {
            fillColor: [220, 230, 242],
            textColor: [0, 0, 0],
            fontSize: 10,
          },
        },
        {
          content: "",
          styles: {
            fillColor: [220, 230, 242],
            textColor: [0, 0, 0],
            fontSize: 10,
          },
        },
      ]);

      sealingRoomMeasurements.forEach((measurement, index) => {
        const rowData = [
          // INFORMACIÓN DE LA TABLA
          addCellWithValidation(measurement.room_code, null, null, ""),
          addCellWithValidation(measurement.room_temperature, null, null, ""),
          addCellWithValidation(measurement.relative_humidity, null, null, ""),
          addCellWithValidation(measurement.pulp_temperature, null, null, ""),
          addCellWithValidation(measurement.min_temperature, null, null, ""),
          addCellWithValidation(measurement.max_temperature, null, null, ""),
          addCellWithValidation(measurement.min_speed, null, null, ""),
          addCellWithValidation(measurement.max_speed, null, null, ""),
          addCellWithValidation(measurement.sealing_quality, null, null, ""),
        ];

        sealingRoomTableData.push(rowData);
      });

      let initialYPosition = yPosition; // SE GUARDA LA POSICIÓN INICIAL DE LA TABLA

      const sealingRoomTable = {
        // CREACIÓN Y MONTAJE DE LA TABLA
        startY: yPosition,
        body: sealingRoomTableData,
        styles: {
          cellPadding: 1,
          fontSize: 9,
          valign: "middle",
          halign: "center",
        },
        margin: margin,
      };

      autoTable(doc, sealingRoomTable); // AGREGADO DE LA TABLA AL DOCUMENTO

      yPosition = parseInt(initialYPosition); // SE VUELVE AL INICIO DE LA TABLA RECIÉN CREADA PARA CALCULO DE ALTURA

      const heightTable = parseInt(doc.lastAutoTable.finalY - yPosition); // CÁLCULO DE LA ALTURA DE LA TABLA

      if (sealingRoomMeasurements.length === 1) {
        // CÁLCULO DE LA DISTANCIA ENTRE TABLAS
        yPosition += heightTable * 2;
      } else {
        yPosition += heightTable + 10;
      }

      const spaceLeft = parseInt(doc.internal.pageSize.height - yPosition); // EVITA DESCUADRES
      if (spaceLeft < 50) {
        doc.addPage();
        yPosition = margin;
      }

      doc.setTextColor(0, 0, 0);
      doc.setFontSize(9);

      Object.entries(subComponentData).forEach(
        // IMPLEMENTACIÓN DE LOS COMENTARIOS
        ([measurementType, measurements]) => {
          if (measurementType === "SealingRoomMeasurement") {
            const typeComments = measurements
              .map((measurement) => measurement.comment)
              .filter((comment) => comment !== "");
            if (typeComments.length > 0) {
              const commentsBlock = `${typeComments
                .map((comment) => `- ${comment}`)
                .join("\n")}`;
              const lines = splitLines(
                commentsBlock,
                doc,
                franjaWidth - margin * 2
              );

              if (lines.length > 0) {
                doc.setTextColor(28, 46, 122);
                doc.setFontSize(9);
                doc.setFont(fontFace);
                doc.text("Comentario:", margin, yPosition);
                yPosition += 10;

                lines.forEach((line, index) => {
                  doc.setTextColor(0, 0, 0);
                  doc.setFont(fontFace, "normal");

                  evaluateNewPage();
                  doc.text(line, margin, yPosition);
                  yPosition += lineHeight;
                });
              }
            }
          }
        }
      );

      yPosition += lineHeight;
      doc.setFillColor(28, 46, 122);
      doc.rect(margin, yPosition, franjaWidth, separatorHeight, "F"); // LÍNEA DIVISORIA
      yPosition += 10;

      const spaceLineLeft = parseInt(doc.internal.pageSize.height - yPosition); // EVITA DESCUADRES
      if (spaceLineLeft < 50) {
        doc.addPage();
        yPosition = margin;
      }
    }

    const palletizedMeasurements = subComponentData.PalletizedMeasurement || []; // AQUÍ COMIENZA LA TABLA DE PALLETAJE
    if (palletizedMeasurements.length > 0) {
      if (!isSecondSectionTitlePrinted) printSecondSectionTitle();
      yPosition += 3;
      const palletizedMeasurementsTitle = "Palletizaje";
      doc.setTextColor(28, 46, 122);
      doc.setFontSize(9);
      doc.text(palletizedMeasurementsTitle, margin, yPosition);
      doc.rect(margin, yPosition + 1, 15, 0.3, "F");
      yPosition += 10;

      const palletizedMeasurementsTableData = [];

      palletizedMeasurementsTableData.push([
        {
          content: "N° cámara",
          styles: titlesStyle,
        },
        {
          content: "T° ambiente (°C)",
          styles: titlesStyle,
        },
        {
          content: "T° Pulpa (°C)",
          styles: titlesStyle, // CABECERA DE LA TABLA
        },
      ]);

      palletizedMeasurements.forEach((measurement, index) => {
        const rowData = [
          addCellWithValidation(measurement.room_code, null, null, ""),
          addCellWithValidation(measurement.room_temperature, null, null, ""),
          addCellWithValidation(measurement.pulp_temperature, null, null, ""),
        ];

        palletizedMeasurementsTableData.push(rowData);
      });

      let initialYPosition = yPosition; // SE GUARDA LA POSICIÓN INICIAL DE LA TABLA

      const palletizedMeasurementsTable = {
        // CREACIÓN Y MONTAJE DE LA TABLA
        startY: yPosition,
        body: palletizedMeasurementsTableData,
        styles: {
          cellPadding: 1,
          fontSize: 9,
          valign: "middle",
          halign: "center",
        },
        margin: margin,
      };

      autoTable(doc, palletizedMeasurementsTable); // AGREGADO DE LA TABLA AL DOCUMENTO

      yPosition = parseInt(initialYPosition); // SE VUELVE AL INICIO DE LA TABLA RECIÉN CREADA PARA CALCULO DE ALTURA

      const heightTable = parseInt(doc.lastAutoTable.finalY - yPosition); // CÁLCULO DE LA ALTURA DE LA TABLA

      if (palletizedMeasurements.length === 1) {
        // CÁLCULO DE LA DISTANCIA ENTRE TABLAS
        yPosition += heightTable * 2;
      } else {
        yPosition += heightTable + 10;
      }

      const spaceLeft = parseInt(doc.internal.pageSize.height - yPosition); // EVITA DESCUADRES
      if (spaceLeft < 60) {
        doc.addPage();
        yPosition = margin;
      }

      const uniqueCommentsSet = new Set(); // Conjunto para almacenar comentarios únicos

      Object.entries(subComponentData).forEach(
        ([measurementType, measurements]) => {
          if (measurementType === "PalletizedMeasurement") {
            const typeComments = measurements
              .map((measurement) => measurement.comment)
              .filter((comment) => comment !== "");

            // Agregar comentarios únicos al conjunto
            typeComments.forEach((comment) => {
              uniqueCommentsSet.add("- " + comment);
            });
          }
        }
      );

      const uniqueCommentsArray = Array.from(uniqueCommentsSet); // Convertir conjunto a array

      if (uniqueCommentsArray.length > 0) {
        doc.setTextColor(28, 46, 122);
        doc.setFontSize(9);
        doc.setFont(fontFace);
        doc.text("Comentario", margin, yPosition);
        yPosition += 10;
        doc.setTextColor(0, 0, 0);
        // Mostrar los comentarios en el documento PDF
        uniqueCommentsArray.forEach((comment) => {
          const lines = splitLines(comment, doc, franjaWidth - margin * 2);

          lines.forEach((line, index) => {
            doc.setTextColor(0, 0, 0);
            doc.setFont(fontFace, "normal");
            evaluateNewPage();
            doc.text(line, margin, yPosition);
            yPosition += lineHeight;
          });
        });
      }

      const spaceLineLeft = parseInt(doc.internal.pageSize.height - yPosition); // EVITA DESCUADRES
      if (spaceLineLeft < 60) {
        doc.addPage();
        yPosition = margin;
      }

      yPosition += lineHeight;
      doc.setFillColor(28, 46, 122);
      doc.rect(margin, yPosition, franjaWidth, separatorHeight, "F"); // LÍNEA DIVISORIA
      yPosition += 10;
    }

    const otherMeasurements = subComponentData.OtherMeasurement || []; // AQUÍ COMIENZA LA TABLA DE OTRAS MEDICIONES
    const enumMeasurements = {};

    if (otherMeasurements.length > 0 && !isSecondSectionTitlePrinted)
      printSecondSectionTitle();

    otherMeasurements.forEach((measurement) => {
      // SEPARACIÓN DE LAS MEDICIONES POR TIPO
      if (!enumMeasurements[measurement.measurement_type]) {
        enumMeasurements[measurement.measurement_type] = [];
      }
      enumMeasurements[measurement.measurement_type].push(measurement);
    });

    Object.entries(enumMeasurements).forEach(([enumValue, measurements]) => {
      // CREACIÓN DE LAS TABLAS DE OTRAS MEDICIONES
      const enumTitle =
        enumValue === "loading_dock"
          ? "Anden de carga"
          : "Cámara de producto terminado";

      const typeComments = measurements
        .map((measurement) => measurement.comment)
        .filter((comment) => comment);
      createOtherMeasurementsTable(enumTitle, measurements, typeComments);
    });

    function createOtherMeasurementsTable(title, measurements, typeComments) {
      // CREACIÓN Y MONTAJE DE LAS TABLAS
      doc.setFont(fontFace);
      doc.setTextColor(0, 51, 102);
      doc.setFontSize(9);
      doc.text(title, margin, yPosition);
      doc.rect(
        margin,
        yPosition + 1,
        title === "Anden de carga" ? 23 : 44,
        0.3,
        "F"
      );
      yPosition += 10;

      const otherMeasurementsTableData = [];

      otherMeasurementsTableData.push([
        {
          content: "N° cámara",
          styles: titlesStyle,
        },
        {
          content: "T° amb. mínima (°C)", // CABECERA DE LA TABLA
          styles: titlesStyle,
        },
        {
          content: "T° amb. máxima (°C)",
          styles: titlesStyle,
        },
        {
          content: "T° pulpa mínima (°C)",
          styles: titlesStyle,
        },
        {
          content: "T° pulpa máxima (°C)",
          styles: titlesStyle,
        },
        {
          content: "Vel. viento min (m/s)",
          styles: titlesStyle,
        },
        {
          content: "Vel. viento max (m/s)",
          styles: titlesStyle,
        },
      ]);

      measurements.forEach((measurement, index) => {
        const rowData = [
          {
            content: measurement.room_code,
            styles: { fillColor: [255, 255, 255] },
          },
          addCellWithValidation(
            measurement.min_room_temperature,
            null,
            null,
            ""
          ),
          addCellWithValidation(
            measurement.max_room_temperature,
            null,
            null,
            ""
          ),
          addCellWithValidation(
            measurement.min_pulp_temperature, // INFORMACIÓN DE LA TABLA
            null,
            null,
            ""
          ),
          addCellWithValidation(
            measurement.max_pulp_temperature,
            null,
            null,
            ""
          ),
          addCellWithValidation(measurement.min_wind_speed, null, null, ""),
          addCellWithValidation(measurement.max_wind_speed, null, null, ""),
        ];

        otherMeasurementsTableData.push(rowData);
      });

      let initialYPosition = yPosition; // SE GUARDA LA POSICIÓN INICIAL DE LA TABLA

      const otherMeasurementsTable = {
        // CREACIÓN Y MONTAJE DE LA TABLA
        startY: yPosition,
        body: otherMeasurementsTableData,
        styles: {
          cellPadding: 1,
          fontSize: 9,
          valign: "middle",
          halign: "center",
        },
        margin: margin,
      };

      autoTable(doc, otherMeasurementsTable); // AGREGADO DE LA TABLA AL DOCUMENTO

      yPosition = parseInt(initialYPosition); // SE VUELVE AL INICIO DE LA TABLA RECIÉN CREADA PARA CALCULO DE ALTURA

      const heightTable = parseInt(doc.lastAutoTable.finalY - yPosition); // CÁLCULO DE LA ALTURA DE LA TABLA

      if (measurements.length === 1) {
        // DISTANCIA ENTRE TABLAS
        yPosition += heightTable * 2;
      } else {
        yPosition += heightTable + 10;
      }

      const spaceLeft = doc.internal.pageSize.height - yPosition; // EVITA DESCUADRES
      if (spaceLeft < 30) {
        doc.addPage();
        yPosition = margin;
      }

      if (typeComments.length > 0) {
        // IMPLEMENTACIÓN DE LOS COMENTARIOS
        const commentsBlock = `${typeComments
          .map((comment) => `- ${comment}`)
          .join("\n")}`;
        const lines = splitLines(commentsBlock, doc, franjaWidth - margin * 2);

        doc.setTextColor(28, 46, 122);
        doc.setFontSize(9);
        doc.setFont(fontFace);
        doc.text("Comentario:", margin, yPosition);
        yPosition += 10;

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(9);

        lines.forEach((line, index) => {
          doc.setTextColor(0, 0, 0);
          doc.setFont(fontFace, "normal");
          evaluateNewPage();

          doc.text(line, margin, yPosition);
          yPosition += lineHeight;
        });
      }

      yPosition += lineHeight;
      doc.setFillColor(28, 46, 122);
      doc.rect(margin, yPosition, franjaWidth, separatorHeight, "F");
      yPosition += 10;

      const spaceLineLeft = doc.internal.pageSize.height - yPosition; // EVITA DESCUADRES
      if (spaceLineLeft < 40) {
        doc.addPage();
        yPosition = margin;
      }
    }

    ////////////////////////////////////////// AQUÍ COMIENZA LA SECCIÓN DE LAS IMAGENES //////////////////////////////////////////////////////

    const spaceBetweenImages = 10; // ESPACIO ENTRE IMÁGENES
    const imagesPerRow = 2; // CANTIDAD DE IMÁGENES POR FILA
    const imagesWidth = 85; // ANCHO DE LAS IMÁGENES
    const imagesHeight = 75; // ALTO DE LAS IMÁGENES

    const imagesTitlePosition = yPosition;
    const imagesTitlePage = doc.internal.getNumberOfPages();

    // Funcion es invocada si hay almenos una imagen
    const printImagesTitle = () => {
      const generalPhotosTitle = "Fotos generales:";
      doc.setPage(imagesTitlePage);
      doc.setFont(fontFace, "bold");
      doc.setTextColor(28, 46, 122);
      doc.setFontSize(9);
      doc.text(generalPhotosTitle, margin, imagesTitlePosition);
      doc.setPage(doc.internal.getNumberOfPages());
    };

    yPosition += 3;

    let imageCount = 0; // CONTADOR DE IMÁGENES
    let measurementCounter = 1; // CONTADOR DE MEDICIONES

    Object.entries(subComponentData).forEach(
      // AQUÍ SE HACE EL FILTRO DE LAS MEDICIONES QUE NO NOS INTERESAN PORQUE NO TIENEN IMAGENES
      ([measurementType, measurementsArray]) => {
        if (
          measurementType !== "ReceptionMeasurements" &&
          measurementType !== "RawMaterialChamber" &&
          measurementType !== "Metrology"
        ) {
          measurementsArray.forEach((measurement) => {
            if (measurementType === "Measurements") {
              // AQUÍ ES UNA SECCIÓN EXCLUSIVA PARA LAS MEDICIONES DE GASES (IMAGENES NUMERADAS)
              const measurementDocs = measurement.documents || [];

              try {
                const parsedDocs = JSON.parse(measurementDocs);

                const hasImages = parsedDocs.some((docData) => docData.name); // SE FILTRAN LAS IMÁGENES

                if (hasImages || parsedDocs.length === 0) {
                  const cajaText = `Caja ${measurementCounter}`; // TEXTO QUE MUESTRA LA PERTENENCIA A LA CAJA CORRESPONDIENTE

                  if (hasImages) {
                    // AQUÍ SE MONTAN LAS IMÁGENES
                    parsedDocs.forEach((docData) => {
                      if (docData.name) {
                        const imageName = docData.name;
                        const imageUrl = getVisitImageUrl(imageName);

                        if (imageCount % imagesPerRow === 0 && imageCount > 0) {
                          yPosition += imagesHeight + spaceBetweenImages;
                        }

                        if (
                          // EVITA DESCUADRES
                          yPosition + imagesHeight >
                          doc.internal.pageSize.height - margin
                        ) {
                          doc.addPage();
                          yPosition = margin;
                        }

                        const rowPosition = imageCount % imagesPerRow;
                        const xPos =
                          margin +
                          rowPosition * (imagesWidth + spaceBetweenImages);
                        const yPos = yPosition;

                        doc.addImage(
                          // AGREGADO DE LA IMAGEN AL DOCUMENTO
                          imageUrl,
                          "JPEG",
                          xPos,
                          yPos,
                          imagesWidth,
                          imagesHeight
                        );

                        doc.setFillColor(28, 46, 122);
                        doc.rect(
                          // LÍNEA DIVISORIA
                          xPos,
                          yPosition + imagesHeight + 2,
                          imagesWidth,
                          separatorHeight,
                          "F"
                        );

                        doc.setTextColor(0, 0, 0);
                        doc.setFont(fontFace, "normal");
                        doc.setFontSize(9);

                        const textXPos =
                          margin +
                          rowPosition * (imagesWidth + spaceBetweenImages) +
                          (imagesWidth + spaceBetweenImages) / 2;
                        doc.text(
                          // TEXTO QUE MUESTRA LA PERTENENCIA A LA CAJA CORRESPONDIENTE
                          textXPos,
                          yPosition + imagesHeight + separatorHeight + 6,
                          cajaText,
                          { align: "center" }
                        );

                        imageCount++; // AUMENTO DEL CONTADOR DE IMÁGENES
                        if (imageCount === 1) printImagesTitle();
                      }
                    });
                  }

                  measurementCounter += 1; // AUMENTO DEL CONTADOR DE MEDICIONES
                }
              } catch (error) {
                console.error("Error al analizar measurementDocs:", error);
              }
            } else {
              const measurementDocs = measurement.documents || []; // AQUÍ ES UNA SECCIÓN EXCLUSIVA PARA LAS MEDICIONES QUE NO SON DE GASES (IMAGENES NO NUMERADAS)

              try {
                const parsedDocs = JSON.parse(measurementDocs);

                if (parsedDocs.length > 0) {
                  // AQUÍ SE MONTAN LAS IMÁGENES
                  parsedDocs.forEach((docData) => {
                    if (docData.name) {
                      const imageName = docData.name;
                      const imageUrl = getVisitImageUrl(imageName);

                      if (imageCount % imagesPerRow === 0 && imageCount > 0) {
                        yPosition += imagesHeight + spaceBetweenImages;
                      }

                      if (
                        yPosition + imagesHeight >
                        doc.internal.pageSize.height - margin
                      ) {
                        doc.addPage();
                        yPosition = margin;
                      }

                      const rowPosition = imageCount % imagesPerRow;
                      const xPos =
                        margin +
                        rowPosition * (imagesWidth + spaceBetweenImages);
                      const yPos = yPosition;

                      doc.addImage(
                        // AGREGADO DE LA IMAGEN AL DOCUMENTO
                        imageUrl,
                        "JPEG",
                        xPos,
                        yPos,
                        imagesWidth,
                        imagesHeight
                      );

                      imageCount++; // AUMENTO DEL CONTADOR DE IMÁGENES GENERALES
                      if (imageCount === 1) printImagesTitle();
                    }
                  });
                }
              } catch (error) {
                console.error("Error al analizar measurementDocs:", error);
              }
            }
          });
        }
      }
    );

    const pageCount = doc.internal.getNumberOfPages(); // AQUÍ SE MONTAN LOS NÚMEROS DE PÁGINA

    for (let i = 1; i <= pageCount; i++) {
      // MONTAJE DE LOS NÚMEROS DE PÁGINA
      doc.setPage(i);

      doc.setFontSize(9);
      doc.setTextColor(0, 0, 0);
      doc.text(
        `${i}`,
        doc.internal.pageSize.width - 20,
        doc.internal.pageSize.height - 10
      );
    }
  }
  return doc;
};
const generateBlob = (visit, subComponentData) => {
  // SE GENERA EL BLOB QUE SE PROCESA PARA SER USADO EN EL ARCHIVO: pdfBlob.js
  const doc = new jsPDF("", "", "", true);
  generateCommon(doc, visit, subComponentData);
  return doc.output("blob");
};

const downloadPDF = async (visit, subComponentData) => {
  // SE DESCARGA EL PDF EL CUAL ES USADO EN EL ARCHIVO: pdfDownload.js
  const doc = new jsPDF("", "", "", true);
  await generateCommon(doc, visit, subComponentData);
  const fileName = `Visita_tecnica-${visit.specie_name}-${visit.id}-${visit.customer_name}-${visit.place_name}.pdf`;
  await doc.save(fileName);
};

export { generateBlob, downloadPDF }; // EXPORTACIÓN DE LAS FUNCIONES PARA SU USO EN OTROS ARCHIVOS

import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { getAllVisits, updateVisit, getAllVisitTypes } from "../../api/visits";
import { getUsers } from "../../api/users";
import { getAllBrands } from "../../api/brands";
import { getSpecies } from "../../api/species";
import { getPlaceById } from "../../api/places";
import { formatDate } from "../../utils/formatDate";
import { getCustomers } from "../../api/customers";
import { getContacts } from "../../api/contacts";
import VisitDialog from "./VisitDialog";
import Filter from "../Filter";

const VisitsTab = () => {
  const [visits, setVisits] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [visitTypes, setVisitTypes] = useState([]);
  const [users, setUsers] = useState([]);
  const [brands, setBrands] = useState([]);
  const [species, setSpecies] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [selectedVisit, setSelectedVisit] = useState(null);
  const [visible, setVisible] = useState(false);
  const [filteredPlaces, setFilteredPlaces] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [formData, setFormData] = useState({
    visit_type_id: null,
    user_id: null,
    brand_id: null,
    specie_id: null,
    place_id: null,
    customer_id: null,
    date_of_visit: null,
    person_in_charge: "",
    person_in_charge_email: "",
    person_in_charge_number: "",
    site: null,
    start_time: null,
    end_time: null,
    sampled_cameras: null,
    number_of_cameras: null,
    report_number: null,
    report_date: null,
    other_comments: "",
    recommendations: "",
    conclusions: "",
  });

  const [subComponentData, setSubComponentData] = useState({
    Metrology: {},
    Measurements: {},
    ReceptionMeasurements: {},
    RawMaterialChamber: {},
    PackingMeasurement: {},
    SealingRoomMeasurement: {},
    PalletizedMeasurement: {},
    OtherMeasurement: {},
  });

  useEffect(() => {
    loadVisits();
    loadVisitTypes();
    loadUsers();
    loadBrands();
    loadSpecies();
    loadCustomers();
    loadContacts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (formData.customer_id) {
      loadPlacesById();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.customer_id]);

  const loadContacts = async () => {
    try {
      const response = await getContacts(formData.place_id);
      setContacts(response.data);
    } catch (error) {
      console.error("Error fetching contacts:", error);
    }
  };

  const loadPlacesById = async () => {
    try {
      const response = await getPlaceById(formData.customer_id);
      setFilteredPlaces(response.data);
    } catch (error) {
      console.error("Error fetching filtered places:", error);
    }
  };

  const loadVisits = async () => {
    try {
      const response = await getAllVisits(startDate, endDate);
      setVisits(response.data);
    } catch (error) {
      console.error("Error fetching visits:", error);
    }
  };

  const loadVisitTypes = async () => {
    try {
      const response = await getAllVisitTypes();
      setVisitTypes(response.data);
    } catch (error) {
      console.error("Error fetching visit types:", error);
    }
  };

  const loadCustomers = async () => {
    try {
      const response = await getCustomers();
      setCustomers(response.data);
    } catch (error) {
      console.error("Error fetching customers:", error);
    }
  };

  const loadUsers = async () => {
    try {
      const response = await getUsers();
      setUsers(response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const loadBrands = async () => {
    try {
      const response = await getAllBrands();
      setBrands(response.data);
    } catch (error) {
      console.error("Error fetching brands:", error);
    }
  };

  const loadSpecies = async () => {
    try {
      const response = await getSpecies();
      setSpecies(response.data);
    } catch (error) {
      console.error("Error fetching species:", error);
    }
  };

  const openDialog = (visit) => {
    console.log("VISITA: ", visit);
    setSelectedVisit(visit.visit);

    if (visit) {
      setVisible(true);
      setFormData({
        ...visit.visit,
      });

      setSubComponentData({
        Metrology: visit.Metrology || {},
        Measurements: visit.Measurements || {},
        ReceptionMeasurements: visit.ReceptionMeasurements || {},
        RawMaterialChamber: visit.RawMaterialChamber || {},
        PackingMeasurement: visit.PackingMeasurement || {},
        SealingRoomMeasurement: visit.SealingRoomMeasurement || {},
        PalletizedMeasurement: visit.PalletizedMeasurement || {},
        OtherMeasurement: visit.OtherMeasurement || {},
      });
    }
  };

  const hideDialog = () => {
    loadVisits();
    setVisible(false);
    setSelectedVisit(null);
    setFormData({
      visit_type_id: null,
      user_id: null,
      brand_id: null,
      specie_id: null,
      place_id: null,
      customer_id: null,
      date_of_visit: null,
      person_in_charge: "",
      person_in_charge_email: "",
      person_in_charge_number: "",
      site: null,
      start_time: null,
      end_time: null,
      sampled_cameras: null,
      number_of_cameras: null,
      report_number: null,
      report_date: null,
      other_comments: "",
      recommendations: "",
      conclusions: "",
    });

    setSubComponentData({
      Metrology: {},
      Measurements: {},
      ReceptionMeasurements: {},
      RawMaterialChamber: {},
      PackingMeasurement: {},
      SealingRoomMeasurement: {},
      PalletizedMeasurement: {},
      OtherMeasurement: {},
    });
  };

  const saveVisit = async () => {
    try {
      const formattedData = {
        ...formData,
        date_of_visit: formData.date_of_visit
          ? formatDate(new Date(formData.date_of_visit))
          : null,
        start_time: formData.start_time
          ? formatDate(new Date(formData.start_time))
          : null,
        end_time: formData.end_time
          ? formatDate(new Date(formData.end_time))
          : null,
        report_date: formData.report_date
          ? formatDate(new Date(formData.report_date))
          : null,
      };

      await updateVisit(
        {
          ...formattedData,
        },
        selectedVisit.id
      );
      showToast("success", "Éxito", "Visita actualizada correctamente");
      //hideDialog();
    } catch (error) {
      console.error("Error al actualizar la visita:", error);
      showToast("error", "Error", "Error al actualizar la visita");
    }
  };

  const handleInputChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const showToast = (severity, summary, detail) => {
    toast.current.show({ severity, summary, detail });
  };

  const toast = React.useRef(null);

  return (
    <div className="Table">
      <div className="TableData">
        <h2>Visitas Técnicas Post Cosecha</h2>
        <Filter
          startDate={startDate}
          endDate={endDate}
          onStartDateChange={(startDate) => {
            setStartDate(new Date(startDate));
          }}
          onEndDateChange={(endDate) => {
            setEndDate(new Date(endDate));
          }}
          onSearch={loadVisits}
        />
        <DataTable
          value={visits}
          selectionMode="single"
          onSelectionChange={(e) => openDialog(e.value)}
        >
          <Column field="visit.id" header="#" sortable />
          <Column
            field="visit.date_of_visit"
            header="Fecha de visita"
            body={(rowData) =>
              formatDate(new Date(rowData.visit.date_of_visit))
            }
            sortable
          />
          <Column
            field="visit.sended_at"
            header="Fecha de envío"
            body={(rowData) =>
              rowData.visit.sended_at
                ? formatDate(new Date(rowData.visit.sended_at))
                : ""
            }
            sortable
          />
          <Column
            field="visit.customer_name"
            header="Cliente"
            sortable
          ></Column>
          <Column field="visit.place_name" header="Planta" sortable></Column>
          <Column
            field="visit.user_name"
            header="Usuario que la realizó"
            sortable
          />
        </DataTable>
      </div>
      <VisitDialog
        visible={visible}
        hideDialog={hideDialog}
        formData={formData}
        subComponentData={subComponentData}
        setSubComponentData={setSubComponentData}
        saveVisit={saveVisit}
        handleInputChange={handleInputChange}
        visitTypes={visitTypes}
        users={users}
        customers={customers}
        brands={brands}
        species={species}
        places={filteredPlaces}
        contacts={contacts}
      />
      <Toast ref={toast} />
    </div>
  );
};

export default VisitsTab;

import { instance } from "./axios";

export const getUsers = async () => {
  try {
    return await instance.get("/users");
  } catch (error) {
    throw error;
  }
};

export const getUserById = async (id) => {
  try {
    return await instance.get(`/users/${id}`);
  } catch (error) {
    throw error;
  }
};

export const createUser = async (
  name,
  last_name,
  email,
  password,
  profiles,
  photo
) => {
  try {
    return await instance.post("/users", {
      name,
      last_name,
      email,
      password,
      profiles,
      photo,
    });
  } catch (error) {
    throw error;
  }
};

export const updateUser = async (
  id,
  name,
  last_name,
  email,
  password,
  profiles,
  photo
) => {
  try {
    return await instance.put(`/users/${id}`, {
      name,
      last_name,
      email,
      password,
      profiles,
      photo,
    });
  } catch (error) {
    throw error;
  }
};

export const deleteUser = async (id) => {
  try {
    return await instance.delete(`/users/${id}`);
  } catch (error) {
    throw error;
  }
};

export const getProfiles = async () => {
  try {
    return await instance.get("/profiles");
  } catch (error) {
    throw error;
  }
};

export const validateToken = async () => {
  try {
    await instance.get(`/validate-jwt`);
    return true;
  } catch (error) {
    return false;
  }
};

import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { FileUpload } from "primereact/fileupload";
import { Toast } from "primereact/toast";
import { Password } from "primereact/password";
import { MultiSelect } from "primereact/multiselect";
import ConfirmationModal from "./ConfirmationModal";
import {
  getUsers,
  createUser,
  deleteUser,
  updateUser,
  getProfiles,
} from "../api/users";
import "./TablesStyle.css";
import Cropper from "./Cropper";
import EditablePassword from "./EditablePassword";
import { replacePassword } from "../api/auth";

const UsersTab = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [visible, setVisible] = useState(false);
  const [usersForm, setUsersForm] = useState({
    name: "",
    last_name: "",
    email: "",
    password: "",
    profiles: [],
    photo: "",
    photoPreview: null,
  });
  const [confirmationModalVisible, setConfirmationModalVisible] =
    useState(false);
  const [searchText, setSearchText] = useState("");
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [profiles, setProfiles] = useState([]);
  const [disableSave, setDisableSave] = useState(false);
  const [displayImageCrop, setDisplayImageCrop] = useState(false);
  const [newImage, setNewImage] = useState(null);

  useEffect(() => {
    loadUsers();
    loadProfiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadProfiles = async () => {
    try {
      const response = await getProfiles();
      setProfiles(response.data);
    } catch (error) {
      showToast("error", "Error", "Error al obtener los perfiles");
      console.error("Error al obtener los perfiles:", error);
    }
  };

  const loadUsers = async () => {
    try {
      const response = await getUsers();
      setUsers(response.data);
    } catch (error) {
      showToast("error", "Error", "Error al obtener los usuarios");
      console.error("Error al obtener los usuarios:", error);
    }
  };

  const openDialog = (user) => {
    setSelectedUser(user);
    setUsersForm({
      name: user?.name || "",
      last_name: user?.last_name || "",
      email: user?.email || "",
      password: user?.password || "",
      profiles: user?.profiles.map((profile) => profile.id) || [],
      photo: user?.photo || "",
      photoPreview: user?.photo || null,
    });
    setVisible(true);
    setIsEditing(!!user);
  };

  const hideDialog = () => {
    setVisible(false);
    setSelectedUser(null);
    setUsersForm({
      name: "",
      last_name: "",
      email: "",
      password: "",
      profiles: [],
      photo: "",
      photoPreview: null,
    });
    setNameError("");
    setIsEditing(false);
  };

  const saveUser = async () => {
    try {
      if (!usersForm.name) {
        setNameError("El nombre del usuario es obligatorio");
        return;
      }

      if (!usersForm.email) {
        setEmailError("El correo del usuario es obligatorio");
        return;
      }

      if (!usersForm.password) {
        setPasswordError("La contraseña del usuario es obligatoria");
        return;
      }

      await updateOrCreateUser();
    } catch (error) {
      console.error("Error al guardar el usuario:", error);
      showToast("error", "Error", "Error al guardar el usuario");
    }
  };

  const updateOrCreateUser = async () => {
    setDisableSave(true);
    try {
      if (selectedUser) {
        await updateUser(
          selectedUser.id,
          usersForm.name,
          usersForm.last_name,
          usersForm.email,
          usersForm.password,
          usersForm.profiles,
          usersForm.photo
        );
      } else {
        await createUser(
          usersForm.name,
          usersForm.last_name,
          usersForm.email,
          usersForm.password,
          usersForm.profiles,
          usersForm.photo
        );
      }

      loadUsers();
      hideDialog();
      showToast("success", "Operación Exitosa", "Usuario guardado con éxito");
      setDisableSave(false);
    } catch (error) {
      console.error("Error al guardar el usuario:", error);
      showToast("error", "Error", "Error al guardar el usuario");
      setDisableSave(false);
    }
  };

  const showConfirmationModal = () => {
    setConfirmationModalVisible(true);
  };

  const hideConfirmationModal = () => {
    setConfirmationModalVisible(false);
  };

  const confirmDelete = async () => {
    try {
      await deleteUser(selectedUser.id);
      loadUsers();
      hideDialog();
      showToast("success", "Operación Exitosa", "Usuario eliminado con éxito");
    } catch (error) {
      console.error("Error al eliminar el usuario:", error);
      showToast("error", "Error", "Error al eliminar el usuario");
    }
  };

  const showToast = (severity, summary, detail) => {
    toast.current.show({ severity, summary, detail });
  };

  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  const onImageCrop = (image) => {
    setUsersForm((prevForm) => ({
      ...prevForm,
      photo: image,
      photoPreview: image,
    }));
    setDisplayImageCrop(false);
  };

  const onFileSelect = async (event) => {
    const file = event.files[0];
    if (!file) return;
    setNewImage(URL.createObjectURL(file));
    setDisplayImageCrop(true);
  };

  const filteredUsers = users.filter((user) =>
    user.name.toLowerCase().includes(searchText.toLowerCase())
  );

  const toast = React.useRef(null);

  const changePassword = async (password) => {
    if (!password) return;
    try {
      await replacePassword(selectedUser.id, { password });
      showToast(
        "success",
        "Operación Exitosa",
        "Contraseña cambiada con éxito"
      );
    } catch (error) {
      console.error("Error al cambiar la contraseña:", error);
      showToast("error", "Error", "Error al cambiar la contraseña");
    }
  };

  return (
    <div className="Table">
      <div className="TableData">
        <h2>Usuarios</h2>
        <div className="TableHeader">
          <div>
            <Button
              label="Agregar Usuario"
              icon="pi pi-plus"
              onClick={() => openDialog(null)}
              className="p-mb-2"
            />
          </div>
          <div>
            <span className="p-input-icon-left">
              <i className="pi pi-search" />
              <InputText
                placeholder="Buscar por nombre"
                value={searchText}
                onChange={handleSearch}
              />
            </span>
          </div>
        </div>
        <DataTable
          value={filteredUsers}
          selectionMode="single"
          onSelectionChange={(e) => openDialog(e.value)}
        >
          <Column field="id" header="#" sortable></Column>
          <Column field="name" header="Nombre" sortable></Column>
          <Column field="last_name" header="Apellido" sortable></Column>
          <Column field="email" header="Correo" sortable></Column>

          <Column
            header="Roles"
            body={(rowData) =>
              rowData.profiles.map((profile) => profile.name).join(", ")
            }
          />

          <Column field="photo" header="Foto" body={imageBody}></Column>
        </DataTable>
      </div>

      <Dialog
        visible={visible}
        onHide={hideDialog}
        header={isEditing ? "Editar Usuario" : "Nuevo Usuario"}
      >
        <div className="p-fluid">
          <div className="p-field">
            <label htmlFor="name">Nombre</label>
            <InputText
              id="name"
              value={usersForm.name}
              onChange={(e) => {
                setUsersForm({ ...usersForm, name: e.target.value });
                setNameError("");
              }}
            />
            {nameError && <small className="p-error">{nameError}</small>}
          </div>
          <div className="p-field">
            <label htmlFor="last_name">Apellido</label>
            <InputText
              id="last_name"
              value={usersForm.last_name}
              onChange={(e) =>
                setUsersForm({ ...usersForm, last_name: e.target.value })
              }
            />
          </div>
          <div className="p-field">
            <label htmlFor="email">Correo</label>
            <InputText
              id="email"
              value={usersForm.email}
              onChange={(e) => {
                setUsersForm({ ...usersForm, email: e.target.value });
                setEmailError("");
              }}
            />
            {emailError && <small className="p-error">{emailError}</small>}
          </div>
          {isEditing && (
            <div className="p-field">
              <label htmlFor="password">Cambiar Contraseña</label>
              <EditablePassword
                value=""
                label="Ingrese la nueva contraseña"
                onChange={(e) => {
                  changePassword(e);
                }}
              />
            </div>
          )}
          {!isEditing && (
            <div className="p-field">
              <label htmlFor="password">Contraseña</label>
              <Password
                id="password"
                value={usersForm.password}
                onChange={(e) => {
                  setUsersForm({ ...usersForm, password: e.target.value });
                  setPasswordError("");
                }}
                toggleMask
              />
              {passwordError && (
                <small className="p-error">{passwordError}</small>
              )}
            </div>
          )}
          <div className="p-field">
            <label htmlFor="profile">Roles</label>
            <MultiSelect
              optionLabel="name"
              optionValue="id"
              options={profiles}
              value={usersForm.profiles}
              onChange={(e) =>
                setUsersForm({ ...usersForm, profiles: e.value })
              }
              placeholder="Seleccione roles"
            />
          </div>
          <div className="p-field">
            <label htmlFor="photo">Foto</label>
            <FileUpload
              mode="basic"
              auto
              accept="image/*"
              onSelect={onFileSelect}
              chooseLabel="Imagen"
            />
            {usersForm.photoPreview && (
              <img
                src={usersForm.photoPreview}
                alt="Vista previa de la foto"
                style={{
                  width: "50%",
                  objectFit: "cover",
                  marginTop: "0.5rem",
                }}
              />
            )}
          </div>
        </div>
        <div className="p-mt-2">
          <Button
            label="Guardar"
            icon="pi pi-check"
            onClick={saveUser}
            disabled={disableSave}
          />
          {selectedUser && (
            <Button
              label="Eliminar"
              icon="pi pi-trash"
              className="p-button-danger p-ml-2"
              onClick={showConfirmationModal}
            />
          )}
        </div>
        <Cropper
          src={newImage}
          visible={displayImageCrop}
          onHide={() => {
            setDisplayImageCrop(false);
            setNewImage(null);
          }}
          onFinish={onImageCrop}
        />
      </Dialog>

      <ConfirmationModal
        visible={confirmationModalVisible}
        onHide={hideConfirmationModal}
        onConfirm={confirmDelete}
        onCancel={hideConfirmationModal}
        message={"este usuario"}
      />

      <Toast ref={toast} />
    </div>
  );
};

export default UsersTab;

function imageBody(rowData) {
  return rowData.photo ? (
    <img
      src={rowData.photo}
      alt={rowData.name}
      style={{ width: "50px", height: "50px", objectFit: "cover" }}
    />
  ) : null;
}

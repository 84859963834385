import { useState } from "react";
import { Password } from "primereact/password";

const EditablePassword = ({ value, onChange, label }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState(value);

  const startEditing = () => {
    setIsEditing(true);
  };
  const finishEditing = () => {
    setIsEditing(false);
    onChange(inputValue);
  };
  return (
    <div className="p-inputgroup">
      <Password
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        placeholder={label}
        disabled={!isEditing}
      />
      <span className="p-inputgroup-addon" style={{ height: 36 }}>
        {isEditing ? (
          <i
            className="pi pi-check"
            onClick={finishEditing}
            style={{ cursor: "pointer" }}
          />
        ) : (
          <i
            className="pi pi-pencil"
            onClick={startEditing}
            style={{ cursor: "pointer" }}
          />
        )}
      </span>
    </div>
  );
};

export default EditablePassword;

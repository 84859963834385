import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Accordion, AccordionTab } from "primereact/accordion";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import {
  updatePurpose,
  updatTopics,
  updateAttendees,
  updateCommitments,
  addNewAttendee,
  addNewCommitment,
  removeAttendee,
  removeCommitment,
} from "../../api/visits";
import { Toast } from "primereact/toast";
import EditableInput from "../EditableInput";
import { getUsers } from "../../api/users";
import EditableDropdown from "../EditableDropdown";
import EditableCalendar from "../EditableCalendar";
import { formatDate } from "../../utils/formatDate";
import { createCommercialReport } from "../../services/commercialReport";
import GeneralInfo from "./GeneralInfo";
import { Oval } from "react-loader-spinner";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";

const VisitDialog = (props) => {
  const [purpose, setPurpose] = useState("");
  const [topics, setTopics] = useState("");
  const [users, setUsers] = useState([]);
  const toast = React.useRef(null);
  const [isDownloading, setIsDownloading] = useState(false);
  const [newAttendeeName, setNewAttendeeName] = useState("");
  const [newAttendeePosition, setNewAttendeePosition] = useState("");
  const [newAttendeeCompany, setNewAttendeeCompany] = useState("");
  const [newCommitmentActivity, setNewCommitmentActivity] = useState("");
  const [newCommitmentResponsible, setNewCommitmentResponsible] =
    useState(null);
  const [newCommitmentDeadline, setNewCommitmentDeadline] = useState(null);

  const showToast = (severity, summary, detail) => {
    toast.current.show({ severity, summary, detail });
  };

  const loadUsers = async () => {
    try {
      const response = await getUsers();
      setUsers(response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const savePurpose = async () => {
    try {
      await updatePurpose({ purpose }, props.visit.id);
      showToast(
        "success",
        "Operación Exitosa",
        "Objetivos actualizados con éxito"
      );
      props.loadVisits();
    } catch (error) {
      showToast("error", "Error", "Error al actualizar los objetivos");
      console.error("Error updating visit:", error);
    }
  };

  const saveTopics = async () => {
    try {
      await updatTopics({ topics }, props.visit.id);
      showToast(
        "success",
        "Operación Exitosa",
        "Desarrollo actualizado con éxito"
      );
      props.loadVisits();
    } catch (error) {
      showToast(
        "error",
        "Error",
        "Error al actualizar el desarrollo de la reunión"
      );
      console.error("Error updating visit:", error);
    }
  };

  const saveAttendees = async (payload, id) => {
    try {
      await updateAttendees(payload, id);
      showToast("success", "Listo!", "Asistente actualizado con éxito");
      props.loadVisits();
    } catch (error) {
      showToast("error", "Error", "Error al actualizar asistente");
      console.error("Error updating visit:", error);
    }
  };

  const saveCommitments = async (payload, id) => {
    try {
      await updateCommitments(payload, id);
      showToast("success", "Listo!", "Tarea actualizada con éxito");
      props.loadVisits();
    } catch (error) {
      showToast("error", "Error", "Error al actualizar tarea");
      console.error("Error updating visit:", error);
    }
  };

  const handleDownload = async () => {
    setIsDownloading(true);
    await createCommercialReport(props.visit);
    setIsDownloading(false);
  };

  const addAttendee = async () => {
    if (!newAttendeeName || !newAttendeePosition || !newAttendeeCompany) {
      showToast("error", "Error", "Todos los campos son requeridos");
      return;
    }
    try {
      const payload = [
        {
          name: newAttendeeName,
          position: newAttendeePosition,
          company: newAttendeeCompany,
          visit_id: props.visit.id,
        },
      ];
      await addNewAttendee(payload);
      showToast("success", "Listo!", "Asistente agregado con éxito");
      setNewAttendeeName("");
      setNewAttendeePosition("");
      setNewAttendeeCompany("");
      props.loadVisits();
    } catch (error) {
      showToast("error", "Error", "Error al agregar asistente");
      console.error("Error adding new attendee:", error);
    }
  };

  const addCommitment = async () => {
    if (
      !newCommitmentActivity ||
      !newCommitmentResponsible ||
      !newCommitmentDeadline
    ) {
      showToast("error", "Error", "Todos los campos son requeridos");
      return;
    }
    try {
      const payload = [
        {
          activity: newCommitmentActivity,
          responsible: newCommitmentResponsible,
          deadline: formatDate(new Date(newCommitmentDeadline)),
          visit_id: props.visit.id,
        },
      ];
      await addNewCommitment(payload);
      showToast("success", "Listo!", "Tarea agregada con éxito");
      setNewCommitmentActivity("");
      setNewCommitmentResponsible(null);
      setNewCommitmentDeadline(null);
      props.loadVisits();
    } catch (error) {
      showToast("error", "Error", "Error al agregar tarea");
      console.error("Error adding new attendee:", error);
    }
  };

  const deleteAttendee = async (id) => {
    const isConfirmed = window.confirm(
      "¿Estás seguro de que deseas eliminar este asistente?"
    );
    if (isConfirmed) {
      try {
        await removeAttendee(id);
        showToast("success", "Listo!", "Asistente eliminado con éxito");
        props.loadVisits();
      } catch (error) {
        showToast("error", "Error", "Error al eliminar asistente");
        console.error("Error deleting attendee:", error);
      }
    }
  };

  const deleteCommitment = async (id) => {
    const isConfirmed = window.confirm(
      "¿Estás seguro de que deseas eliminar esta tarea?"
    );
    if (isConfirmed) {
      try {
        await removeCommitment(id);
        showToast("success", "Listo!", "Tarea eliminada con éxito");
        props.loadVisits();
        props.hideDialog();
      } catch (error) {
        showToast("error", "Error", "Error al eliminar tarea");
        console.error("Error deleting commitment:", error);
      }
    }
  };

  useEffect(() => {
    if (!props.visit) return;
    setPurpose(props.visit.purpose);
    setTopics(props.visit.topics);
    loadUsers();
  }, [props.visit]);

  if (!props.visit) return null;
  return (
    <Dialog
      visible={props.visible}
      onHide={props.hideDialog}
      header={"Editar Visita"}
      style={{ width: "100vw", maxWidth: "800px" }}
      resizable={false}
    >
      <Accordion style={{ width: "100%", height: "100%" }}>
        <AccordionTab header="Información General">
          <GeneralInfo
            visit={props.visit}
            loadVisits={props.loadVisits}
            users={users}
            showToast={showToast}
          />
        </AccordionTab>
        <AccordionTab header="Nómina de Asistentes">
          <DataTable value={props.visit.attendees} className="p-datatable-sm">
            <Column
              header="Nombre"
              body={(rowData) => (
                <EditableInput
                  value={rowData.name}
                  label="Nombre"
                  onChange={(e) => {
                    saveAttendees({ name: e }, rowData.id);
                  }}
                />
              )}
            />
            <Column
              header="Cargo"
              body={(rowData) => (
                <EditableInput
                  value={rowData.position}
                  label="Cargo"
                  onChange={(e) => {
                    saveAttendees({ position: e }, rowData.id);
                  }}
                />
              )}
            />
            <Column
              field="company"
              header="Empresa"
              body={(rowData) => (
                <EditableInput
                  value={rowData.company}
                  label="Empresa"
                  onChange={(e) => {
                    saveAttendees({ company: e }, rowData.id);
                  }}
                />
              )}
            />
            <Column
              body={(rowData) => (
                <Button
                  style={{ top: -8 }}
                  icon="pi pi-trash"
                  className="p-button-danger"
                  onClick={() => deleteAttendee(rowData.id)}
                />
              )}
            />
          </DataTable>

          <div
            style={{
              marginLeft: 10,
              marginTop: 10,
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "10px",
            }}
          >
            <InputText
              placeholder="Nombre"
              value={newAttendeeName}
              style={{ width: 200 }}
              onChange={(e) => setNewAttendeeName(e.target.value)}
            />
            <InputText
              value={newAttendeePosition}
              placeholder="Cargo"
              style={{ width: 200 }}
              onChange={(e) => setNewAttendeePosition(e.target.value)}
            />
            <InputText
              value={newAttendeeCompany}
              placeholder="Empresa"
              style={{ width: 200 }}
              onChange={(e) => setNewAttendeeCompany(e.target.value)}
            />
            <Button
              icon="pi pi-plus"
              style={{ top: -8 }}
              onClick={addAttendee}
            />
          </div>
        </AccordionTab>
        <AccordionTab header="Objetivos de la Reunión">
          <InputTextarea
            autoResize={true}
            value={purpose}
            placeholder="Escriba aquí..."
            onChange={(e) => setPurpose(e.target.value)}
          />
          <Button label="Guardar" icon="pi pi-check" onClick={savePurpose} />
        </AccordionTab>
        <AccordionTab header="Desarrollo de la Reunión">
          <InputTextarea
            autoResize={true}
            value={topics}
            onChange={(e) => setTopics(e.target.value)}
            placeholder="Escriba aquí..."
          />
          <Button label="Guardar" icon="pi pi-check" onClick={saveTopics} />
        </AccordionTab>
        <AccordionTab header="Tareas Pendientes">
          <DataTable value={props.visit.commitments} className="p-datatable-sm">
            <Column
              header="Tarea"
              body={(rowData) => (
                <EditableInput
                  value={rowData.activity}
                  label="Actividad"
                  onChange={(e) => {
                    saveCommitments({ activity: e }, rowData.id);
                  }}
                />
              )}
            />
            <Column
              header="Responsable"
              body={(rowData) => (
                <EditableDropdown
                  list={users}
                  onChange={(e) =>
                    saveCommitments({ responsible: e }, rowData.id)
                  }
                  id={rowData.responsible}
                />
              )}
            />
            <Column
              field="deadline"
              header="fecha Límite"
              body={(rowData) => (
                <EditableCalendar
                  value={new Date(rowData.deadline)}
                  onChange={(e) =>
                    saveCommitments(
                      { deadline: formatDate(new Date(e)) },
                      rowData.id
                    )
                  }
                  label="Fecha Límite"
                />
              )}
            />
            <Column
              body={(rowData) => (
                <Button
                  style={{ top: -8 }}
                  icon="pi pi-trash"
                  className="p-button-danger"
                  onClick={() => deleteCommitment(rowData.id)}
                />
              )}
            />
          </DataTable>
          <div
            style={{
              marginLeft: 10,
              marginTop: 10,
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "10px",
            }}
          >
            <InputText
              placeholder="Tarea"
              value={newCommitmentActivity}
              style={{ width: 200 }}
              onChange={(e) => setNewCommitmentActivity(e.target.value)}
            />
            <Dropdown
              optionLabel="name"
              optionValue="id"
              value={newCommitmentResponsible}
              options={users}
              onChange={(e) => setNewCommitmentResponsible(e.value)}
              placeholder="Responsable"
              style={{ width: 200, height: 35, top: -5 }}
            />
            <Calendar
              value={newCommitmentDeadline}
              onChange={(e) => setNewCommitmentDeadline(e.value)}
              placeholder="Fecha Límite"
              style={{ width: 200, height: 43 }}
              dateFormat="dd/mm/yy"
            />
            <Button
              icon="pi pi-plus"
              style={{ top: -8 }}
              onClick={addCommitment}
            />
          </div>
        </AccordionTab>
      </Accordion>
      {isDownloading ? (
        <div style={{ padding: 10 }}>
          <Oval visible={true} height="40" width="40" color="#0078d4" />
        </div>
      ) : (
        <Button label="Descargar PDF" onClick={handleDownload} />
      )}
      <Toast ref={toast} />
    </Dialog>
  );
};

export default VisitDialog;

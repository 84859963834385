import React, { useState } from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { updateOtherMeasurements } from "../api/otherMeasurements";
import { getVisitImageUrl } from "../utils/getImageUrl";

const OtherMeasurementsPanel = ({ otherMeasurements, onChange, title }) => {
  const [selectedMeasurement, setSelectedMeasurement] = useState(null);
  const [originalMeasurement, setOriginalMeasurement] = useState(null);
  const [displayModal, setDisplayModal] = useState(false);
  const [sortOrder, setSortOrder] = useState(1);

  const handleInputChange = (field, value) => {
    setSelectedMeasurement((prevMeasurement) => ({
      ...prevMeasurement,
      [field]: value,
    }));
  };

  const showToast = (severity, summary, detail) => {
    toast.current.show({ severity, summary, detail });
  };

  const toast = React.useRef(null);

  const handleUpdateClick = () => {
    if (selectedMeasurement && selectedMeasurement.id) {
      updateOtherMeasurements(selectedMeasurement, selectedMeasurement.id)
        .then(() => {
          const updatedMeasurements = [
            ...otherMeasurements.filter((m) => m.id !== selectedMeasurement.id),
            selectedMeasurement,
          ];
          const sortedMeasurements = updatedMeasurements.sort(
            (a, b) => sortOrder * (a.id - b.id)
          );
          onChange(sortedMeasurements);
          setDisplayModal(false);
          showToast("success", "Éxito", "Medición Actualizada");
        })
        .catch((error) => {
          console.error("Error al actualizar Other Measurements:", error);
          showToast("error", "Error", "Error al actualizar la medición");
        });
    }
  };

  const openModal = (measurement) => {
    setSelectedMeasurement(measurement);
    setOriginalMeasurement({ ...measurement });
    setDisplayModal(true);
  };

  const closeModal = () => {
    setSelectedMeasurement(null);
    if (originalMeasurement) {
      setSelectedMeasurement({ ...originalMeasurement });
      setOriginalMeasurement(null);
    }
    setDisplayModal(false);
  };

  const handleDeleteImage = (index) => {
    const updatedMeasurement = { ...selectedMeasurement };
    const updatedDocuments = JSON.parse(updatedMeasurement.documents || "[]");
    updatedDocuments.splice(index, 1);
    updatedMeasurement.documents = JSON.stringify(updatedDocuments);
    setSelectedMeasurement(updatedMeasurement);
  };

  return (
    <div>
      <Accordion>
        <AccordionTab header={title}>
          <DataTable
            value={otherMeasurements}
            selectionMode="single"
            selection={selectedMeasurement}
            onSelectionChange={(e) => openModal(e.value)}
            onSort={(e) => setSortOrder(e.sortOrder)}
          >
            <Column field="room_code" header="Código de cámara"></Column>
            <Column
              field="min_room_temperature"
              header="Temperatura mínima de cámara"
            ></Column>
            <Column
              field="max_room_temperature"
              header="Temperatura máxima de cámara"
            ></Column>
            <Column
              field="min_pulp_temperature"
              header="Temperatura mínima de la pulpa"
            ></Column>
            <Column
              field="max_pulp_temperature"
              header="Temperatura máxima de la pulpa"
            ></Column>
            <Column field="comment" header="Comentario" />
          </DataTable>

          <Dialog
            visible={displayModal}
            header={"Editar Otras Mediciones"}
            style={{ width: "50vw" }}
            onHide={closeModal}
          >
            <div>
              <div className="field-title">Código de cámara</div>
              <InputText
                value={selectedMeasurement?.room_code || ""}
                onChange={(e) => handleInputChange("room_code", e.target.value)}
                placeholder="Código de cámara"
              />

              <div className="field-title">Temperatura mínima de cámara</div>
              <InputText
                value={selectedMeasurement?.min_room_temperature || ""}
                onChange={(e) =>
                  handleInputChange("min_room_temperature", e.target.value)
                }
                placeholder="Temperatura mínima de cámara"
              />

              <div className="field-title">Temperatura máxima de cámara</div>
              <InputText
                value={selectedMeasurement?.max_room_temperature || ""}
                onChange={(e) =>
                  handleInputChange("max_room_temperature", e.target.value)
                }
                placeholder="Temperatura máxima de cámara"
              />

              <div className="field-title">Temperatura mínima de la pulpa</div>
              <InputText
                value={selectedMeasurement?.min_pulp_temperature || ""}
                onChange={(e) =>
                  handleInputChange("min_pulp_temperature", e.target.value)
                }
                placeholder="Temperatura mínima de la pulpa"
              />

              <div className="field-title">Temperatura máxima de la pulpa</div>
              <InputText
                value={selectedMeasurement?.max_pulp_temperature || ""}
                onChange={(e) =>
                  handleInputChange("max_pulp_temperature", e.target.value)
                }
                placeholder="Temperatura máxima de la pulpa"
              />

              <div className="field-title">Velocidad mínima del viento</div>
              <InputText
                value={selectedMeasurement?.min_wind_speed || ""}
                onChange={(e) =>
                  handleInputChange("min_wind_speed", e.target.value)
                }
                placeholder="Velocidad mínima del viento"
              />

              <div className="field-title">Velocidad máxima del viento</div>
              <InputText
                value={selectedMeasurement?.max_wind_speed || ""}
                onChange={(e) =>
                  handleInputChange("max_wind_speed", e.target.value)
                }
                placeholder="Velocidad máxima del viento"
              />
              <div className="field-title">Comentario</div>
              <InputTextarea
                autoResize={true}
                value={selectedMeasurement?.comment || ""}
                onChange={(e) => handleInputChange("comment", e.target.value)}
                placeholder="Comentario"
              />
              {selectedMeasurement &&
              selectedMeasurement.documents &&
              JSON.parse(selectedMeasurement.documents).length > 0 ? (
                <div>
                  <div className="field-title">Imágenes</div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    {Array.isArray(
                      JSON.parse(selectedMeasurement.documents)
                    ) ? (
                      JSON.parse(selectedMeasurement.documents).map(
                        (document, index) => {
                          try {
                            const documentData = document;
                            const imageUrl = getVisitImageUrl(
                              documentData.name
                            );

                            return (
                              <div
                                key={index}
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  marginRight: "10px",
                                }}
                              >
                                <img
                                  src={imageUrl}
                                  alt={`Documento ${documentData.name}`}
                                  style={{ width: "100px", height: "100px" }}
                                />
                                <i
                                  className="pi pi-trash"
                                  style={{
                                    cursor: "pointer",
                                    color: "red",
                                    marginTop: "2px",
                                  }}
                                  onClick={() => handleDeleteImage(index)}
                                />
                              </div>
                            );
                          } catch (error) {
                            console.error(
                              "Error al parsear el documento:",
                              error
                            );
                            return (
                              <div key={index}>
                                <span style={{ color: "red" }}>
                                  Error al procesar la imagen.
                                </span>
                              </div>
                            );
                          }
                        }
                      )
                    ) : (
                      <div>No hay imágenes válidas.</div>
                    )}
                  </div>
                </div>
              ) : (
                <div>No hay imágenes asociadas.</div>
              )}
              <div className="p-mt-2">
                <Button
                  label="Guardar"
                  icon="pi pi-check"
                  onClick={handleUpdateClick}
                />
              </div>
            </div>
          </Dialog>
        </AccordionTab>
      </Accordion>
      <Toast ref={toast} />
    </div>
  );
};

export default OtherMeasurementsPanel;
